import { http } from "@/repo/http";
import { IRepoErrors } from "@/repo/errors";

export type IGetApplePayValidateSession = {
  epochTimestamp: number;
  expiresAt: number;
  merchantSessionIdentifier: string;
  nonce: string;
  merchantIdentifier: string;
  domainName: string;
  displayName: string;
  signature: string;
  operationalAnalyticsIdentifier: string;
  retries: number;
};

type IApiResponse = {
  data: {
    apple_pay_session: {
      epochTimestamp: number;
      expiresAt: number;
      merchantSessionIdentifier: string;
      nonce: string;
      merchantIdentifier: string;
      domainName: string;
      displayName: string;
      signature: string;
      operationalAnalyticsIdentifier: string;
      retries: number;
    };
  };
};

export type IGetApplePayValidateSessionBody = {
  applePaySessionUrl: string;
};

export class GetApplePayValidateSessionError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repository: payment.getApplePayValidateSession");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export const getApplePayValidateSession = async (
  request: IGetApplePayValidateSessionBody
): Promise<IGetApplePayValidateSession> => {
  let response;
  try {
    const { applePaySessionUrl } = request;
    const res: IApiResponse = await http.post(
      `/storefront/checkout-com/apple-pay-session?applePaySessionUrl=${applePaySessionUrl}`
    );

    const { apple_pay_session } = res.data || {};
    response = apple_pay_session;
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw new Error(e);
    }

    const errors: IRepoErrors = { unkownError: true };
    throw new GetApplePayValidateSessionError(errors);
  }

  return response;
};
