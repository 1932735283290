
import { t } from "@/i18n";
import { computed, defineComponent, PropType } from "vue";
import { MolLoyaltySwitch, MolLoyaltyBanner } from "@chatfood/design-system";
import { ColorSet, ILoyaltyProgram } from "@/repo/fetch-business-by-slug";
import { CurrencyCodeEnum } from "@/enum";
import { getRedeemableLoyaltyPoints } from "@/util/loyalty-points";
import { bemBuilder } from "@chatfood/core-utils";

const css = bemBuilder("bill-loyalty-program");

export default defineComponent({
  name: "BillLoyaltyProgram",
  components: {
    MolLoyaltySwitch,
    MolLoyaltyBanner,
  },
  props: {
    isLoggedIn: {
      type: Boolean,
      required: true,
    },
    totalOrder: {
      type: Number,
      required: true,
    },
    loyaltyProgram: {
      type: Object as PropType<ILoyaltyProgram>,
      required: true,
    },
    loyaltyPoints: {
      type: Number,
      required: true,
    },
    useLoyaltyProgram: {
      type: Boolean,
      required: true,
    },
    currency: {
      type: String as PropType<CurrencyCodeEnum>,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    colorSet: {
      type: Object as PropType<ColorSet>,
      required: true,
    },
  },
  emits: ["redirect", "set-state"],
  setup(props, { emit }) {
    const color = computed(() => {
      const { primary } = props.colorSet || {};
      return primary || "var(--color-primary)";
    });

    const orderAmountPercentageWorth = computed(
      () => props.loyaltyProgram?.orderAmountPercentageWorth ?? 0
    );

    const burnedPoints = computed(() => {
      if (!props?.loyaltyProgram || !props.loyaltyPoints) return 0;

      const { maximumRedeemedOrderAmount, maximumRedeemedOrderPercentage } =
        props.loyaltyProgram;

      return getRedeemableLoyaltyPoints(props.totalOrder, props.loyaltyPoints, {
        maximumRedeemedOrderAmount,
        maximumRedeemedOrderPercentage,
      });
    });

    const showLoyalty = computed(() =>
      props.isLoggedIn ? Boolean(burnedPoints.value) : true
    );

    const handleRedirect = (): void => {
      emit("redirect");
    };

    const setState = (): void => {
      emit("set-state", !props.useLoyaltyProgram);
    };

    return {
      t,
      css,
      color,
      orderAmountPercentageWorth,
      burnedPoints,
      showLoyalty,
      handleRedirect,
      setState,
    };
  },
});
