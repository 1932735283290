import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bc43f216"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomIcon = _resolveComponent("AtomIcon")!

  return (_openBlock(), _createElementBlock("button", {
    disabled: _ctx.isDisabled || _ctx.isLoading,
    class: _normalizeClass([
      _ctx.css(),
      _ctx.css('', _ctx.type),
      _ctx.css('', _ctx.size),
      {
        [_ctx.css('', _ctx.AtomButtonTypeEnum.DISABLED)]: _ctx.isDisabled,
        [_ctx.css('', 'loading')]: _ctx.isLoading,
        [_ctx.css('', 'icon')]: _ctx.icon,
      },
    ]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.css('circle'))
        }, null, 2))
      : _createCommentVNode("", true),
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_AtomIcon, {
          key: 1,
          class: _normalizeClass(_ctx.css('icon')),
          name: _ctx.icon
        }, null, 8, ["class", "name"]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 10, _hoisted_1))
}