
import { bemBuilder } from "@chatfood/core-utils";
import { defineComponent, computed } from "vue";
import { AtomIcon } from "@/design-system";

const css = bemBuilder("mol-collapse-indicator");

export default defineComponent({
  name: "MolCollapseIndicator",
  components: {
    AtomIcon,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const nameIcon = computed((): string =>
      props.open ? "arrow-down" : "arrow-right"
    );

    return {
      css,
      nameIcon,
    };
  },
});
