import { AxiosResponse } from "axios";
import { IRepoErrors } from "../errors";
import { http } from "../http";
import {
  PhoneVerificationErrorEnum,
  PhoneVerificationErrorsMap,
} from "./phone-verification-error";

export type IAuthLoginUsingOtpBody = {
  countryCode: string;
  phoneNumber: string;
  otp: string;
};

export class AuthLoginUsingOtpError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: AuthLoginUsingOtp");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function authLoginUsingOtp(
  request: IAuthLoginUsingOtpBody
): Promise<string> {
  let response: AxiosResponse;

  try {
    response = await http.post("/storefront/auth/customer", {
      country_code: request.countryCode.replace(/\D/g, ""),
      phone_number: request.phoneNumber.replace(/\D/g, ""),
      verification_code: request.otp.replace(/\D/g, ""),
    });
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    const errors: Record<any, any> = {};

    // eslint-disable-next-line no-magic-numbers
    if (e.response.status === 404) {
      errors.phoneVerificationError = {
        code: PhoneVerificationErrorEnum.NOT_FOUND,
        context: {},
      };
      throw new AuthLoginUsingOtpError(errors);
    }

    // eslint-disable-next-line no-magic-numbers
    if (e.response.status !== 422) {
      errors.networkError = true;
      throw new AuthLoginUsingOtpError(errors);
    }

    errors.validationError = {
      countryCode: e.response.data.errors?.country_code?.[0] ?? "",
      phoneNumber: e.response.data.errors?.phone_number?.[0] ?? "",
      otp: e.response.data.errors?.verification_code?.[0] ?? "",
    };

    const phoneVerificationErrorCode = e.response.data.error?.code;
    if (phoneVerificationErrorCode) {
      const errorFound = PhoneVerificationErrorsMap.find(
        (o: any) => o.apiError === phoneVerificationErrorCode
      );

      if (errorFound) {
        errors.phoneVerificationError = {
          code: errorFound.represents,
          context: e.response.data.error?.context ?? {},
        };
      }
    }

    throw new AuthLoginUsingOtpError(errors);
  }
  return response.data.access_token;
}
