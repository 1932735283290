import { http } from "@/repo/http";
import { IRepoErrors } from "@/repo/errors";
import { BusinessRuleErrorEnum, GatewayTypeEnum } from "@/repo/enums";
import { IAxiosErrorResponse } from "@/repo/types";

export type IGetPaymentGatewayFormattedResponse = {
  gatewayType: GatewayTypeEnum;
  publishableKey: string;
  isApplePayEnabled: boolean;
};

export type IGetPaymentGatewayResponse = {
  data: {
    gatewayType: number;
    publishableKey: string;
    isApplePayEnabled: boolean;
  };
};

export type IGetPaymentGatewayBody = {
  businessId: string;
};

export class GetPaymentGatewayError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repository: business.GetPaymentGateway");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export const getPaymentGateway = async (
  request: IGetPaymentGatewayBody
): Promise<IGetPaymentGatewayFormattedResponse> => {
  try {
    const body: IGetPaymentGatewayResponse = await http.get(
      `/storefront/businesses/${request.businessId}/gateway-account`
    );

    type tMapGatewayType = Record<number, GatewayTypeEnum>;

    const mapGatewayType: tMapGatewayType = {
      1: GatewayTypeEnum.CHECKOUT,
      2: GatewayTypeEnum.STRIPE,
      3: GatewayTypeEnum.SKIPCASH,
    };

    const { gatewayType, publishableKey, isApplePayEnabled } = body?.data || {};

    return {
      gatewayType: mapGatewayType[gatewayType],
      publishableKey,
      isApplePayEnabled,
    };
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw new Error(e);
    }

    const errors: IRepoErrors = {};
    const response: IAxiosErrorResponse = e.response;

    // eslint-disable-next-line no-magic-numbers
    if (response.status === 422) {
      errors.businessRuleError = {
        code: BusinessRuleErrorEnum.GATEWAY_ACCOUNT_CREDENTIALS_INVALID,
        context: {
          businessId: request.businessId,
        },
      };

      throw new GetPaymentGatewayError(errors);
    }

    errors.unkownError = true;
    throw new GetPaymentGatewayError(errors);
  }
};
