import { AxiosResponse } from "axios";
import { IRepoErrors } from "../errors";
import { http } from "../http";
import { CurrencyCodeEnum } from "@/enum";

type Currency = {
  currencyValue: number;
  currencyCode: CurrencyCodeEnum;
};

export type IFetchBillByTableIdResponse = {
  tableId: string;
  tableName: string;
  location: {
    id: string;
    name: string;
  };
  bill: {
    id: string;
    amountPaid: Currency;
    remainingBalance: Currency;
    tax: Currency;
    items: {
      id: string;
      name: string;
      unitPrice: Currency;
      quantity: number;
    };
  } | null;
};

export class FetchBillByTableIdError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchBillByTableId");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchBillByTableId(
  tableId: string
): Promise<IFetchBillByTableIdResponse> {
  let response: AxiosResponse;

  try {
    response = await http.get(`/pat/tables/${tableId}`);
  } catch (e: any) {
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchBillByTableIdError(e);
  }

  const data = response.data;
  const bill = response.data.bill;

  const mapCurrency = (data: any): Currency => {
    return {
      currencyValue: data.value,
      currencyCode: data.currency,
    };
  };

  return {
    tableId: data.table_id,
    tableName: data.table_name,
    location: data.location,
    bill: bill
      ? {
          id: bill.id,
          amountPaid: mapCurrency(bill.amount_paid),
          remainingBalance: mapCurrency(bill.remaining_balance),
          tax: mapCurrency(bill.tax),
          items: bill.items.map((item: any) => {
            return {
              ...item,
              unitPrice: mapCurrency(item.unit_price),
            };
          }),
        }
      : null,
  };
}
