import type { ICountry } from "./types";

export const countries: Array<ICountry> = [
  {
    name: "Afghanistan (‫افغانستان‬‎)",
    iso2: "AF",
    dialCode: "93",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇦🇫",
    flagEmojiUnicode: "U+1F1E6 U+1F1EB",
  },
  {
    name: "Albania (Shqipëri)",
    iso2: "AL",
    dialCode: "355",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇦🇱",
    flagEmojiUnicode: "U+1F1E6 U+1F1F1",
  },
  {
    name: "Algeria (‫الجزائر‬‎)",
    iso2: "DZ",
    dialCode: "213",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇩🇿",
    flagEmojiUnicode: "U+1F1E9 U+1F1FF",
  },
  {
    name: "American Samoa",
    iso2: "AS",
    dialCode: "1684",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇦🇸",
    flagEmojiUnicode: "U+1F1E6 U+1F1F8",
  },
  {
    name: "Andorra",
    iso2: "AD",
    dialCode: "376",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇦🇩",
    flagEmojiUnicode: "U+1F1E6 U+1F1E9",
  },
  {
    name: "Angola",
    iso2: "AO",
    dialCode: "244",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇦🇴",
    flagEmojiUnicode: "U+1F1E6 U+1F1F4",
  },
  {
    name: "Anguilla",
    iso2: "AI",
    dialCode: "1264",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇦🇮",
    flagEmojiUnicode: "U+1F1E6 U+1F1EE",
  },
  {
    name: "Antigua and Barbuda",
    iso2: "AG",
    dialCode: "1268",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇦🇬",
    flagEmojiUnicode: "U+1F1E6 U+1F1EC",
  },
  {
    name: "Argentina",
    iso2: "AR",
    dialCode: "54",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇦🇷",
    flagEmojiUnicode: "U+1F1E6 U+1F1F7",
  },
  {
    name: "Armenia (Հայաստան)",
    iso2: "AM",
    dialCode: "374",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇦🇲",
    flagEmojiUnicode: "U+1F1E6 U+1F1F2",
  },
  {
    name: "Aruba",
    iso2: "AW",
    dialCode: "297",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇦🇼",
    flagEmojiUnicode: "U+1F1E6 U+1F1FC",
  },
  {
    name: "Australia",
    iso2: "AU",
    dialCode: "61",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇦🇺",
    flagEmojiUnicode: "U+1F1E6 U+1F1FA",
  },
  {
    name: "Austria (Österreich)",
    iso2: "AT",
    dialCode: "43",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇦🇹",
    flagEmojiUnicode: "U+1F1E6 U+1F1F9",
  },
  {
    name: "Azerbaijan (Azərbaycan)",
    iso2: "AZ",
    dialCode: "994",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇦🇿",
    flagEmojiUnicode: "U+1F1E6 U+1F1FF",
  },
  {
    name: "Bahamas",
    iso2: "BS",
    dialCode: "1242",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇧🇸",
    flagEmojiUnicode: "U+1F1E7 U+1F1F8",
  },
  {
    name: "Bahrain (‫البحرين‬‎)",
    iso2: "BH",
    dialCode: "973",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇧🇭",
    flagEmojiUnicode: "U+1F1E7 U+1F1ED",
  },
  {
    name: "Bangladesh (বাংলাদেশ)",
    iso2: "BD",
    dialCode: "880",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇧🇩",
    flagEmojiUnicode: "U+1F1E7 U+1F1E9",
  },
  {
    name: "Barbados",
    iso2: "BB",
    dialCode: "1246",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇧🇧",
    flagEmojiUnicode: "U+1F1E7 U+1F1E7",
  },
  {
    name: "Belarus (Беларусь)",
    iso2: "BY",
    dialCode: "375",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇧🇾",
    flagEmojiUnicode: "U+1F1E7 U+1F1FE",
  },
  {
    name: "Belgium (België)",
    iso2: "BE",
    dialCode: "32",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇧🇪",
    flagEmojiUnicode: "U+1F1E7 U+1F1EA",
  },
  {
    name: "Belize",
    iso2: "BZ",
    dialCode: "501",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇧🇿",
    flagEmojiUnicode: "U+1F1E7 U+1F1FF",
  },
  {
    name: "Benin (Bénin)",
    iso2: "BJ",
    dialCode: "229",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇧🇯",
    flagEmojiUnicode: "U+1F1E7 U+1F1EF",
  },
  {
    name: "Bermuda",
    iso2: "BM",
    dialCode: "1441",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇧🇲",
    flagEmojiUnicode: "U+1F1E7 U+1F1F2",
  },
  {
    name: "Bhutan (འབྲུག)",
    iso2: "BT",
    dialCode: "975",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇧🇹",
    flagEmojiUnicode: "U+1F1E7 U+1F1F9",
  },
  {
    name: "Bolivia",
    iso2: "BO",
    dialCode: "591",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇧🇴",
    flagEmojiUnicode: "U+1F1E7 U+1F1F4",
  },
  {
    name: "Bosnia and Herzegovina (Босна и Херцеговина)",
    iso2: "BA",
    dialCode: "387",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇧🇦",
    flagEmojiUnicode: "U+1F1E7 U+1F1E6",
  },
  {
    name: "Botswana",
    iso2: "BW",
    dialCode: "267",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇧🇼",
    flagEmojiUnicode: "U+1F1E7 U+1F1FC",
  },
  {
    name: "Brazil (Brasil)",
    iso2: "BR",
    dialCode: "55",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇧🇷",
    flagEmojiUnicode: "U+1F1E7 U+1F1F7",
  },
  {
    name: "British Indian Ocean Territory",
    iso2: "IO",
    dialCode: "246",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇮🇴",
    flagEmojiUnicode: "U+1F1EE U+1F1F4",
  },
  {
    name: "British Virgin Islands",
    iso2: "VG",
    dialCode: "1284",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇻🇬",
    flagEmojiUnicode: "U+1F1FB U+1F1EC",
  },
  {
    name: "Brunei",
    iso2: "BN",
    dialCode: "673",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇧🇳",
    flagEmojiUnicode: "U+1F1E7 U+1F1F3",
  },
  {
    name: "Bulgaria (България)",
    iso2: "BG",
    dialCode: "359",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇧🇬",
    flagEmojiUnicode: "U+1F1E7 U+1F1EC",
  },
  {
    name: "Burkina Faso",
    iso2: "BF",
    dialCode: "226",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇧🇫",
    flagEmojiUnicode: "U+1F1E7 U+1F1EB",
  },
  {
    name: "Burundi (Uburundi)",
    iso2: "BI",
    dialCode: "257",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇧🇮",
    flagEmojiUnicode: "U+1F1E7 U+1F1EE",
  },
  {
    name: "Cambodia (កម្ពុជា)",
    iso2: "KH",
    dialCode: "855",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇰🇭",
    flagEmojiUnicode: "U+1F1F0 U+1F1ED",
  },
  {
    name: "Cameroon (Cameroun)",
    iso2: "CM",
    dialCode: "237",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇨🇲",
    flagEmojiUnicode: "U+1F1E8 U+1F1F2",
  },
  {
    name: "Canada",
    iso2: "CA",
    dialCode: "1",
    priority: 1,
    areaCodes: [
      "204",
      "226",
      "236",
      "249",
      "250",
      "289",
      "306",
      "343",
      "365",
      "387",
      "403",
      "416",
      "418",
      "431",
      "437",
      "438",
      "450",
      "506",
      "514",
      "519",
      "548",
      "579",
      "581",
      "587",
      "604",
      "613",
      "639",
      "647",
      "672",
      "705",
      "709",
      "742",
      "778",
      "780",
      "782",
      "807",
      "819",
      "825",
      "867",
      "873",
      "902",
      "905",
    ],
    flagEmoji: "🇨🇦",
    flagEmojiUnicode: "U+1F1E8 U+1F1E6",
  },
  {
    name: "Cape Verde (Kabu Verdi)",
    iso2: "CV",
    dialCode: "238",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇨🇻",
    flagEmojiUnicode: "U+1F1E8 U+1F1FB",
  },
  {
    name: "Caribbean Netherlands",
    iso2: "BQ",
    dialCode: "599",
    priority: 1,
    areaCodes: null,
    flagEmoji: "🇧🇶",
    flagEmojiUnicode: "U+1F1E7 U+1F1F6",
  },
  {
    name: "Cayman Islands",
    iso2: "KY",
    dialCode: "1",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇰🇾",
    flagEmojiUnicode: "U+1F1F0 U+1F1FE",
  },
  {
    name: "Central African Republic (République centrafricaine)",
    iso2: "CF",
    dialCode: "236",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇨🇫",
    flagEmojiUnicode: "U+1F1E8 U+1F1EB",
  },
  {
    name: "Chad (Tchad)",
    iso2: "TD",
    dialCode: "235",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇹🇩",
    flagEmojiUnicode: "U+1F1F9 U+1F1E9",
  },
  {
    name: "Chile",
    iso2: "CL",
    dialCode: "56",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇨🇱",
    flagEmojiUnicode: "U+1F1E8 U+1F1F1",
  },
  {
    name: "China (中国)",
    iso2: "CN",
    dialCode: "86",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇨🇳",
    flagEmojiUnicode: "U+1F1E8 U+1F1F3",
  },
  {
    name: "Christmas Island",
    iso2: "CX",
    dialCode: "61",
    priority: 2,
    areaCodes: null,
    flagEmoji: "🇨🇽",
    flagEmojiUnicode: "U+1F1E8 U+1F1FD",
  },
  {
    name: "Cocos (Keeling) Islands",
    iso2: "CC",
    dialCode: "61",
    priority: 1,
    areaCodes: null,
    flagEmoji: "🇨🇨",
    flagEmojiUnicode: "U+1F1E8 U+1F1E8",
  },
  {
    name: "Colombia",
    iso2: "CO",
    dialCode: "57",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇨🇴",
    flagEmojiUnicode: "U+1F1E8 U+1F1F4",
  },
  {
    name: "Comoros (‫جزر القمر‬‎)",
    iso2: "KM",
    dialCode: "269",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇰🇲",
    flagEmojiUnicode: "U+1F1F0 U+1F1F2",
  },
  {
    name: "Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)",
    iso2: "CD",
    dialCode: "243",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇨🇩",
    flagEmojiUnicode: "U+1F1E8 U+1F1E9",
  },
  {
    name: "Congo (Republic) (Congo-Brazzaville)",
    iso2: "CG",
    dialCode: "242",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇨🇬",
    flagEmojiUnicode: "U+1F1E8 U+1F1EC",
  },
  {
    name: "Cook Islands",
    iso2: "CK",
    dialCode: "682",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇨🇰",
    flagEmojiUnicode: "U+1F1E8 U+1F1F0",
  },
  {
    name: "Costa Rica",
    iso2: "CR",
    dialCode: "506",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇨🇷",
    flagEmojiUnicode: "U+1F1E8 U+1F1F7",
  },
  {
    name: "Croatia (Hrvatska)",
    iso2: "HR",
    dialCode: "385",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇭🇷",
    flagEmojiUnicode: "U+1F1ED U+1F1F7",
  },
  {
    name: "Cuba",
    iso2: "CU",
    dialCode: "53",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇨🇺",
    flagEmojiUnicode: "U+1F1E8 U+1F1FA",
  },
  {
    name: "Curaçao",
    iso2: "CW",
    dialCode: "599",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇨🇼",
    flagEmojiUnicode: "U+1F1E8 U+1F1FC",
  },
  {
    name: "Cyprus (Κύπρος)",
    iso2: "CY",
    dialCode: "357",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇨🇾",
    flagEmojiUnicode: "U+1F1E8 U+1F1FE",
  },
  {
    name: "Czech Republic (Česká republika)",
    iso2: "CZ",
    dialCode: "420",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇨🇿",
    flagEmojiUnicode: "U+1F1E8 U+1F1FF",
  },
  {
    name: "Côte d’Ivoire",
    iso2: "CI",
    dialCode: "225",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇨🇮",
    flagEmojiUnicode: "U+1F1E8 U+1F1EE",
  },
  {
    name: "Denmark (Danmark)",
    iso2: "DK",
    dialCode: "45",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇩🇰",
    flagEmojiUnicode: "U+1F1E9 U+1F1F0",
  },
  {
    name: "Djibouti",
    iso2: "DJ",
    dialCode: "253",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇩🇯",
    flagEmojiUnicode: "U+1F1E9 U+1F1EF",
  },
  {
    name: "Dominica",
    iso2: "DM",
    dialCode: "1767",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇩🇲",
    flagEmojiUnicode: "U+1F1E9 U+1F1F2",
  },
  {
    name: "Dominican Republic (República Dominicana)",
    iso2: "DO",
    dialCode: "1",
    priority: 2,
    areaCodes: ["809", "829", "849"],
    flagEmoji: "🇩🇴",
    flagEmojiUnicode: "U+1F1E9 U+1F1F4",
  },
  {
    name: "Ecuador",
    iso2: "EC",
    dialCode: "593",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇪🇨",
    flagEmojiUnicode: "U+1F1EA U+1F1E8",
  },
  {
    name: "Egypt (‫مصر‬‎)",
    iso2: "EG",
    dialCode: "20",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇪🇬",
    flagEmojiUnicode: "U+1F1EA U+1F1EC",
  },
  {
    name: "El Salvador",
    iso2: "SV",
    dialCode: "503",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇸🇻",
    flagEmojiUnicode: "U+1F1F8 U+1F1FB",
  },
  {
    name: "Equatorial Guinea (Guinea Ecuatorial)",
    iso2: "GQ",
    dialCode: "240",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇬🇶",
    flagEmojiUnicode: "U+1F1EC U+1F1F6",
  },
  {
    name: "Eritrea",
    iso2: "ER",
    dialCode: "291",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇪🇷",
    flagEmojiUnicode: "U+1F1EA U+1F1F7",
  },
  {
    name: "Estonia (Eesti)",
    iso2: "EE",
    dialCode: "372",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇪🇪",
    flagEmojiUnicode: "U+1F1EA U+1F1EA",
  },
  {
    name: "Ethiopia",
    iso2: "ET",
    dialCode: "251",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇪🇹",
    flagEmojiUnicode: "U+1F1EA U+1F1F9",
  },
  {
    name: "Falkland Islands (Islas Malvinas)",
    iso2: "FK",
    dialCode: "500",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇫🇰",
    flagEmojiUnicode: "U+1F1EB U+1F1F0",
  },
  {
    name: "Faroe Islands (Føroyar)",
    iso2: "FO",
    dialCode: "298",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇫🇴",
    flagEmojiUnicode: "U+1F1EB U+1F1F4",
  },
  {
    name: "Fiji",
    iso2: "FJ",
    dialCode: "679",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇫🇯",
    flagEmojiUnicode: "U+1F1EB U+1F1EF",
  },
  {
    name: "Finland (Suomi)",
    iso2: "FI",
    dialCode: "358",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇫🇮",
    flagEmojiUnicode: "U+1F1EB U+1F1EE",
  },
  {
    name: "France",
    iso2: "FR",
    dialCode: "33",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇫🇷",
    flagEmojiUnicode: "U+1F1EB U+1F1F7",
  },
  {
    name: "French Guiana (Guyane française)",
    iso2: "GF",
    dialCode: "594",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇬🇫",
    flagEmojiUnicode: "U+1F1EC U+1F1EB",
  },
  {
    name: "French Polynesia (Polynésie française)",
    iso2: "PF",
    dialCode: "689",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇵🇫",
    flagEmojiUnicode: "U+1F1F5 U+1F1EB",
  },
  {
    name: "Gabon",
    iso2: "GA",
    dialCode: "241",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇬🇦",
    flagEmojiUnicode: "U+1F1EC U+1F1E6",
  },
  {
    name: "Gambia",
    iso2: "GM",
    dialCode: "220",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇬🇲",
    flagEmojiUnicode: "U+1F1EC U+1F1F2",
  },
  {
    name: "Georgia (საქართველო)",
    iso2: "GE",
    dialCode: "995",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇬🇪",
    flagEmojiUnicode: "U+1F1EC U+1F1EA",
  },
  {
    name: "Germany (Deutschland)",
    iso2: "DE",
    dialCode: "49",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇩🇪",
    flagEmojiUnicode: "U+1F1E9 U+1F1EA",
  },
  {
    name: "Ghana (Gaana)",
    iso2: "GH",
    dialCode: "233",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇬🇭",
    flagEmojiUnicode: "U+1F1EC U+1F1ED",
  },
  {
    name: "Gibraltar",
    iso2: "GI",
    dialCode: "350",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇬🇮",
    flagEmojiUnicode: "U+1F1EC U+1F1EE",
  },
  {
    name: "Greece (Ελλάδα)",
    iso2: "GR",
    dialCode: "30",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇬🇷",
    flagEmojiUnicode: "U+1F1EC U+1F1F7",
  },
  {
    name: "Greenland (Kalaallit Nunaat)",
    iso2: "GL",
    dialCode: "299",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇬🇱",
    flagEmojiUnicode: "U+1F1EC U+1F1F1",
  },
  {
    name: "Grenada",
    iso2: "GD",
    dialCode: "1473",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇬🇩",
    flagEmojiUnicode: "U+1F1EC U+1F1E9",
  },
  {
    name: "Guadeloupe",
    iso2: "GP",
    dialCode: "590",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇬🇵",
    flagEmojiUnicode: "U+1F1EC U+1F1F5",
  },
  {
    name: "Guam",
    iso2: "GU",
    dialCode: "1671",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇬🇺",
    flagEmojiUnicode: "U+1F1EC U+1F1FA",
  },
  {
    name: "Guatemala",
    iso2: "GT",
    dialCode: "502",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇬🇹",
    flagEmojiUnicode: "U+1F1EC U+1F1F9",
  },
  {
    name: "Guernsey",
    iso2: "GG",
    dialCode: "44",
    priority: 1,
    areaCodes: null,
    flagEmoji: "🇬🇬",
    flagEmojiUnicode: "U+1F1EC U+1F1EC",
  },
  {
    name: "Guinea (Guinée)",
    iso2: "GN",
    dialCode: "224",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇬🇳",
    flagEmojiUnicode: "U+1F1EC U+1F1F3",
  },
  {
    name: "Guinea-Bissau (Guiné Bissau)",
    iso2: "GW",
    dialCode: "245",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇬🇼",
    flagEmojiUnicode: "U+1F1EC U+1F1FC",
  },
  {
    name: "Guyana",
    iso2: "GY",
    dialCode: "592",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇬🇾",
    flagEmojiUnicode: "U+1F1EC U+1F1FE",
  },
  {
    name: "Haiti",
    iso2: "HT",
    dialCode: "509",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇭🇹",
    flagEmojiUnicode: "U+1F1ED U+1F1F9",
  },
  {
    name: "Honduras",
    iso2: "HN",
    dialCode: "504",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇭🇳",
    flagEmojiUnicode: "U+1F1ED U+1F1F3",
  },
  {
    name: "Hong Kong (香港)",
    iso2: "HK",
    dialCode: "852",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇭🇰",
    flagEmojiUnicode: "U+1F1ED U+1F1F0",
  },
  {
    name: "Hungary (Magyarország)",
    iso2: "HU",
    dialCode: "36",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇭🇺",
    flagEmojiUnicode: "U+1F1ED U+1F1FA",
  },
  {
    name: "Iceland (Ísland)",
    iso2: "IS",
    dialCode: "354",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇮🇸",
    flagEmojiUnicode: "U+1F1EE U+1F1F8",
  },
  {
    name: "India (भारत)",
    iso2: "IN",
    dialCode: "91",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇮🇳",
    flagEmojiUnicode: "U+1F1EE U+1F1F3",
  },
  {
    name: "Indonesia",
    iso2: "ID",
    dialCode: "62",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇮🇩",
    flagEmojiUnicode: "U+1F1EE U+1F1E9",
  },
  {
    name: "Iran (‫ایران‬‎)",
    iso2: "IR",
    dialCode: "98",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇮🇷",
    flagEmojiUnicode: "U+1F1EE U+1F1F7",
  },
  {
    name: "Iraq (‫العراق‬‎)",
    iso2: "IQ",
    dialCode: "964",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇮🇶",
    flagEmojiUnicode: "U+1F1EE U+1F1F6",
  },
  {
    name: "Ireland",
    iso2: "IE",
    dialCode: "353",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇮🇪",
    flagEmojiUnicode: "U+1F1EE U+1F1EA",
  },
  {
    name: "Isle of Man",
    iso2: "IM",
    dialCode: "44",
    priority: 2,
    areaCodes: null,
    flagEmoji: "🇮🇲",
    flagEmojiUnicode: "U+1F1EE U+1F1F2",
  },
  {
    name: "Israel (‫ישראל‬‎)",
    iso2: "IL",
    dialCode: "972",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇮🇱",
    flagEmojiUnicode: "U+1F1EE U+1F1F1",
  },
  {
    name: "Italy (Italia)",
    iso2: "IT",
    dialCode: "39",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇮🇹",
    flagEmojiUnicode: "U+1F1EE U+1F1F9",
  },
  {
    name: "Jamaica",
    iso2: "JM",
    dialCode: "1876",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇯🇲",
    flagEmojiUnicode: "U+1F1EF U+1F1F2",
  },
  {
    name: "Japan (日本)",
    iso2: "JP",
    dialCode: "81",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇯🇵",
    flagEmojiUnicode: "U+1F1EF U+1F1F5",
  },
  {
    name: "Jersey",
    iso2: "JE",
    dialCode: "44",
    priority: 3,
    areaCodes: null,
    flagEmoji: "🇯🇪",
    flagEmojiUnicode: "U+1F1EF U+1F1EA",
  },
  {
    name: "Jordan (‫الأردن‬‎)",
    iso2: "JO",
    dialCode: "962",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇯🇴",
    flagEmojiUnicode: "U+1F1EF U+1F1F4",
  },
  {
    name: "Kazakhstan (Казахстан)",
    iso2: "KZ",
    dialCode: "7",
    priority: 1,
    areaCodes: null,
    flagEmoji: "🇰🇿",
    flagEmojiUnicode: "U+1F1F0 U+1F1FF",
  },
  {
    name: "Kenya",
    iso2: "KE",
    dialCode: "254",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇰🇪",
    flagEmojiUnicode: "U+1F1F0 U+1F1EA",
  },
  {
    name: "Kiribati",
    iso2: "KI",
    dialCode: "686",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇰🇮",
    flagEmojiUnicode: "U+1F1F0 U+1F1EE",
  },
  {
    name: "Kosovo",
    iso2: "XK",
    dialCode: "383",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇽🇰",
    flagEmojiUnicode: "U+1F1FD U+1F1F0",
  },
  {
    name: "Kuwait (‫الكويت‬‎)",
    iso2: "KW",
    dialCode: "965",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇰🇼",
    flagEmojiUnicode: "U+1F1F0 U+1F1FC",
  },
  {
    name: "Kyrgyzstan (Кыргызстан)",
    iso2: "KG",
    dialCode: "996",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇰🇬",
    flagEmojiUnicode: "U+1F1F0 U+1F1EC",
  },
  {
    name: "Laos (ລາວ)",
    iso2: "LA",
    dialCode: "856",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇱🇦",
    flagEmojiUnicode: "U+1F1F1 U+1F1E6",
  },
  {
    name: "Latvia (Latvija)",
    iso2: "LV",
    dialCode: "371",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇱🇻",
    flagEmojiUnicode: "U+1F1F1 U+1F1FB",
  },
  {
    name: "Lebanon (‫لبنان‬‎)",
    iso2: "LB",
    dialCode: "961",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇱🇧",
    flagEmojiUnicode: "U+1F1F1 U+1F1E7",
  },
  {
    name: "Lesotho",
    iso2: "LS",
    dialCode: "266",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇱🇸",
    flagEmojiUnicode: "U+1F1F1 U+1F1F8",
  },
  {
    name: "Liberia",
    iso2: "LR",
    dialCode: "231",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇱🇷",
    flagEmojiUnicode: "U+1F1F1 U+1F1F7",
  },
  {
    name: "Libya (‫ليبيا‬‎)",
    iso2: "LY",
    dialCode: "218",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇱🇾",
    flagEmojiUnicode: "U+1F1F1 U+1F1FE",
  },
  {
    name: "Liechtenstein",
    iso2: "LI",
    dialCode: "423",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇱🇮",
    flagEmojiUnicode: "U+1F1F1 U+1F1EE",
  },
  {
    name: "Lithuania (Lietuva)",
    iso2: "LT",
    dialCode: "370",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇱🇹",
    flagEmojiUnicode: "U+1F1F1 U+1F1F9",
  },
  {
    name: "Luxembourg",
    iso2: "LU",
    dialCode: "352",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇱🇺",
    flagEmojiUnicode: "U+1F1F1 U+1F1FA",
  },
  {
    name: "Macau (澳門)",
    iso2: "MO",
    dialCode: "853",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇲🇴",
    flagEmojiUnicode: "U+1F1F2 U+1F1F4",
  },
  {
    name: "Macedonia (FYROM) (Македонија)",
    iso2: "MK",
    dialCode: "389",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇲🇰",
    flagEmojiUnicode: "U+1F1F2 U+1F1F0",
  },
  {
    name: "Madagascar (Madagasikara)",
    iso2: "MG",
    dialCode: "261",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇲🇬",
    flagEmojiUnicode: "U+1F1F2 U+1F1EC",
  },
  {
    name: "Malawi",
    iso2: "MW",
    dialCode: "265",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇲🇼",
    flagEmojiUnicode: "U+1F1F2 U+1F1FC",
  },
  {
    name: "Malaysia",
    iso2: "MY",
    dialCode: "60",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇲🇾",
    flagEmojiUnicode: "U+1F1F2 U+1F1FE",
  },
  {
    name: "Maldives",
    iso2: "MV",
    dialCode: "960",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇲🇻",
    flagEmojiUnicode: "U+1F1F2 U+1F1FB",
  },
  {
    name: "Mali",
    iso2: "ML",
    dialCode: "223",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇲🇱",
    flagEmojiUnicode: "U+1F1F2 U+1F1F1",
  },
  {
    name: "Malta",
    iso2: "MT",
    dialCode: "356",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇲🇹",
    flagEmojiUnicode: "U+1F1F2 U+1F1F9",
  },
  {
    name: "Marshall Islands",
    iso2: "MH",
    dialCode: "692",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇲🇭",
    flagEmojiUnicode: "U+1F1F2 U+1F1ED",
  },
  {
    name: "Martinique",
    iso2: "MQ",
    dialCode: "596",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇲🇶",
    flagEmojiUnicode: "U+1F1F2 U+1F1F6",
  },
  {
    name: "Mauritania (‫موريتانيا‬‎)",
    iso2: "MR",
    dialCode: "222",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇲🇷",
    flagEmojiUnicode: "U+1F1F2 U+1F1F7",
  },
  {
    name: "Mauritius (Moris)",
    iso2: "MU",
    dialCode: "230",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇲🇺",
    flagEmojiUnicode: "U+1F1F2 U+1F1FA",
  },
  {
    name: "Mayotte",
    iso2: "YT",
    dialCode: "262",
    priority: 1,
    areaCodes: null,
    flagEmoji: "🇾🇹",
    flagEmojiUnicode: "U+1F1FE U+1F1F9",
  },
  {
    name: "Mexico (México)",
    iso2: "MX",
    dialCode: "52",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇲🇽",
    flagEmojiUnicode: "U+1F1F2 U+1F1FD",
  },
  {
    name: "Micronesia",
    iso2: "FM",
    dialCode: "691",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇫🇲",
    flagEmojiUnicode: "U+1F1EB U+1F1F2",
  },
  {
    name: "Moldova (Republica Moldova)",
    iso2: "MD",
    dialCode: "373",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇲🇩",
    flagEmojiUnicode: "U+1F1F2 U+1F1E9",
  },
  {
    name: "Monaco",
    iso2: "MC",
    dialCode: "377",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇲🇨",
    flagEmojiUnicode: "U+1F1F2 U+1F1E8",
  },
  {
    name: "Mongolia (Монгол)",
    iso2: "MN",
    dialCode: "976",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇲🇳",
    flagEmojiUnicode: "U+1F1F2 U+1F1F3",
  },
  {
    name: "Montenegro (Crna Gora)",
    iso2: "ME",
    dialCode: "382",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇲🇪",
    flagEmojiUnicode: "U+1F1F2 U+1F1EA",
  },
  {
    name: "Montserrat",
    iso2: "MS",
    dialCode: "1664",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇲🇸",
    flagEmojiUnicode: "U+1F1F2 U+1F1F8",
  },
  {
    name: "Morocco (‫المغرب‬‎)",
    iso2: "MA",
    dialCode: "212",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇲🇦",
    flagEmojiUnicode: "U+1F1F2 U+1F1E6",
  },
  {
    name: "Mozambique (Moçambique)",
    iso2: "MZ",
    dialCode: "258",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇲🇿",
    flagEmojiUnicode: "U+1F1F2 U+1F1FF",
  },
  {
    name: "Myanmar (Burma) (မြန်မာ)",
    iso2: "MM",
    dialCode: "95",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇲🇲",
    flagEmojiUnicode: "U+1F1F2 U+1F1F2",
  },
  {
    name: "Namibia (Namibië)",
    iso2: "NA",
    dialCode: "264",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇳🇦",
    flagEmojiUnicode: "U+1F1F3 U+1F1E6",
  },
  {
    name: "Nauru",
    iso2: "NR",
    dialCode: "674",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇳🇷",
    flagEmojiUnicode: "U+1F1F3 U+1F1F7",
  },
  {
    name: "Nepal (नेपाल)",
    iso2: "NP",
    dialCode: "977",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇳🇵",
    flagEmojiUnicode: "U+1F1F3 U+1F1F5",
  },
  {
    name: "Netherlands (Nederland)",
    iso2: "NL",
    dialCode: "31",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇳🇱",
    flagEmojiUnicode: "U+1F1F3 U+1F1F1",
  },
  {
    name: "New Caledonia (Nouvelle-Calédonie)",
    iso2: "NC",
    dialCode: "687",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇳🇨",
    flagEmojiUnicode: "U+1F1F3 U+1F1E8",
  },
  {
    name: "New Zealand",
    iso2: "NZ",
    dialCode: "64",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇳🇿",
    flagEmojiUnicode: "U+1F1F3 U+1F1FF",
  },
  {
    name: "Nicaragua",
    iso2: "NI",
    dialCode: "505",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇳🇮",
    flagEmojiUnicode: "U+1F1F3 U+1F1EE",
  },
  {
    name: "Niger (Nijar)",
    iso2: "NE",
    dialCode: "227",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇳🇪",
    flagEmojiUnicode: "U+1F1F3 U+1F1EA",
  },
  {
    name: "Nigeria",
    iso2: "NG",
    dialCode: "234",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇳🇬",
    flagEmojiUnicode: "U+1F1F3 U+1F1EC",
  },
  {
    name: "Niue",
    iso2: "NU",
    dialCode: "683",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇳🇺",
    flagEmojiUnicode: "U+1F1F3 U+1F1FA",
  },
  {
    name: "Norfolk Island",
    iso2: "NF",
    dialCode: "672",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇳🇫",
    flagEmojiUnicode: "U+1F1F3 U+1F1EB",
  },
  {
    name: "North Korea (조선 민주주의 인민 공화국)",
    iso2: "KP",
    dialCode: "850",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇰🇵",
    flagEmojiUnicode: "U+1F1F0 U+1F1F5",
  },
  {
    name: "Northern Mariana Islands",
    iso2: "MP",
    dialCode: "1670",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇲🇵",
    flagEmojiUnicode: "U+1F1F2 U+1F1F5",
  },
  {
    name: "Norway (Norge)",
    iso2: "NO",
    dialCode: "47",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇳🇴",
    flagEmojiUnicode: "U+1F1F3 U+1F1F4",
  },
  {
    name: "Oman (‫عُمان‬‎)",
    iso2: "OM",
    dialCode: "968",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇴🇲",
    flagEmojiUnicode: "U+1F1F4 U+1F1F2",
  },
  {
    name: "Pakistan (‫پاکستان‬‎)",
    iso2: "PK",
    dialCode: "92",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇵🇰",
    flagEmojiUnicode: "U+1F1F5 U+1F1F0",
  },
  {
    name: "Palau",
    iso2: "PW",
    dialCode: "680",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇵🇼",
    flagEmojiUnicode: "U+1F1F5 U+1F1FC",
  },
  {
    name: "Palestine (‫فلسطين‬‎)",
    iso2: "PS",
    dialCode: "970",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇵🇸",
    flagEmojiUnicode: "U+1F1F5 U+1F1F8",
  },
  {
    name: "Panama (Panamá)",
    iso2: "PA",
    dialCode: "507",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇵🇦",
    flagEmojiUnicode: "U+1F1F5 U+1F1E6",
  },
  {
    name: "Papua New Guinea",
    iso2: "PG",
    dialCode: "675",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇵🇬",
    flagEmojiUnicode: "U+1F1F5 U+1F1EC",
  },
  {
    name: "Paraguay",
    iso2: "PY",
    dialCode: "595",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇵🇾",
    flagEmojiUnicode: "U+1F1F5 U+1F1FE",
  },
  {
    name: "Peru (Perú)",
    iso2: "PE",
    dialCode: "51",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇵🇪",
    flagEmojiUnicode: "U+1F1F5 U+1F1EA",
  },
  {
    name: "Philippines",
    iso2: "PH",
    dialCode: "63",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇵🇭",
    flagEmojiUnicode: "U+1F1F5 U+1F1ED",
  },
  {
    name: "Poland (Polska)",
    iso2: "PL",
    dialCode: "48",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇵🇱",
    flagEmojiUnicode: "U+1F1F5 U+1F1F1",
  },
  {
    name: "Portugal",
    iso2: "PT",
    dialCode: "351",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇵🇹",
    flagEmojiUnicode: "U+1F1F5 U+1F1F9",
  },
  {
    name: "Puerto Rico",
    iso2: "PR",
    dialCode: "1",
    priority: 3,
    areaCodes: ["787", "939"],
    flagEmoji: "🇵🇷",
    flagEmojiUnicode: "U+1F1F5 U+1F1F7",
  },
  {
    name: "Qatar (‫قطر‬‎)",
    iso2: "QA",
    dialCode: "974",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇶🇦",
    flagEmojiUnicode: "U+1F1F6 U+1F1E6",
  },
  {
    name: "Romania (România)",
    iso2: "RO",
    dialCode: "40",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇷🇴",
    flagEmojiUnicode: "U+1F1F7 U+1F1F4",
  },
  {
    name: "Russia (Россия)",
    iso2: "RU",
    dialCode: "7",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇷🇺",
    flagEmojiUnicode: "U+1F1F7 U+1F1FA",
  },
  {
    name: "Rwanda",
    iso2: "RW",
    dialCode: "250",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇷🇼",
    flagEmojiUnicode: "U+1F1F7 U+1F1FC",
  },
  {
    name: "Réunion (La Réunion)",
    iso2: "RE",
    dialCode: "262",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇷🇪",
    flagEmojiUnicode: "U+1F1F7 U+1F1EA",
  },
  {
    name: "Saint Barthélemy",
    iso2: "BL",
    dialCode: "590",
    priority: 1,
    areaCodes: null,
    flagEmoji: "🇧🇱",
    flagEmojiUnicode: "U+1F1E7 U+1F1F1",
  },
  {
    name: "Saint Helena",
    iso2: "SH",
    dialCode: "290",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇸🇭",
    flagEmojiUnicode: "U+1F1F8 U+1F1ED",
  },
  {
    name: "Saint Kitts and Nevis",
    iso2: "KN",
    dialCode: "1869",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇰🇳",
    flagEmojiUnicode: "U+1F1F0 U+1F1F3",
  },
  {
    name: "Saint Lucia",
    iso2: "LC",
    dialCode: "1758",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇱🇨",
    flagEmojiUnicode: "U+1F1F1 U+1F1E8",
  },
  {
    name: "Saint Martin (Saint-Martin (partie française))",
    iso2: "MF",
    dialCode: "590",
    priority: 2,
    areaCodes: null,
    flagEmoji: "🇲🇫",
    flagEmojiUnicode: "U+1F1F2 U+1F1EB",
  },
  {
    name: "Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)",
    iso2: "PM",
    dialCode: "508",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇵🇲",
    flagEmojiUnicode: "U+1F1F5 U+1F1F2",
  },
  {
    name: "Saint Vincent and the Grenadines",
    iso2: "VC",
    dialCode: "1784",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇻🇨",
    flagEmojiUnicode: "U+1F1FB U+1F1E8",
  },
  {
    name: "Samoa",
    iso2: "WS",
    dialCode: "685",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇼🇸",
    flagEmojiUnicode: "U+1F1FC U+1F1F8",
  },
  {
    name: "San Marino",
    iso2: "SM",
    dialCode: "378",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇸🇲",
    flagEmojiUnicode: "U+1F1F8 U+1F1F2",
  },
  {
    name: "Saudi Arabia (‫المملكة العربية السعودية‬‎)",
    iso2: "SA",
    dialCode: "966",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇸🇦",
    flagEmojiUnicode: "U+1F1F8 U+1F1E6",
  },
  {
    name: "Senegal (Sénégal)",
    iso2: "SN",
    dialCode: "221",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇸🇳",
    flagEmojiUnicode: "U+1F1F8 U+1F1F3",
  },
  {
    name: "Serbia (Србија)",
    iso2: "RS",
    dialCode: "381",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇷🇸",
    flagEmojiUnicode: "U+1F1F7 U+1F1F8",
  },
  {
    name: "Seychelles",
    iso2: "SC",
    dialCode: "248",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇸🇨",
    flagEmojiUnicode: "U+1F1F8 U+1F1E8",
  },
  {
    name: "Sierra Leone",
    iso2: "SL",
    dialCode: "232",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇸🇱",
    flagEmojiUnicode: "U+1F1F8 U+1F1F1",
  },
  {
    name: "Singapore",
    iso2: "SG",
    dialCode: "65",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇸🇬",
    flagEmojiUnicode: "U+1F1F8 U+1F1EC",
  },
  {
    name: "Sint Maarten",
    iso2: "SX",
    dialCode: "1721",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇸🇽",
    flagEmojiUnicode: "U+1F1F8 U+1F1FD",
  },
  {
    name: "Slovakia (Slovensko)",
    iso2: "SK",
    dialCode: "421",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇸🇰",
    flagEmojiUnicode: "U+1F1F8 U+1F1F0",
  },
  {
    name: "Slovenia (Slovenija)",
    iso2: "SI",
    dialCode: "386",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇸🇮",
    flagEmojiUnicode: "U+1F1F8 U+1F1EE",
  },
  {
    name: "Solomon Islands",
    iso2: "SB",
    dialCode: "677",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇸🇧",
    flagEmojiUnicode: "U+1F1F8 U+1F1E7",
  },
  {
    name: "Somalia (Soomaaliya)",
    iso2: "SO",
    dialCode: "252",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇸🇴",
    flagEmojiUnicode: "U+1F1F8 U+1F1F4",
  },
  {
    name: "South Africa",
    iso2: "ZA",
    dialCode: "27",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇿🇦",
    flagEmojiUnicode: "U+1F1FF U+1F1E6",
  },
  {
    name: "South Korea (대한민국)",
    iso2: "KR",
    dialCode: "82",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇰🇷",
    flagEmojiUnicode: "U+1F1F0 U+1F1F7",
  },
  {
    name: "South Sudan (‫جنوب السودان‬‎)",
    iso2: "SS",
    dialCode: "211",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇸🇸",
    flagEmojiUnicode: "U+1F1F8 U+1F1F8",
  },
  {
    name: "Spain (España)",
    iso2: "ES",
    dialCode: "34",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇪🇸",
    flagEmojiUnicode: "U+1F1EA U+1F1F8",
  },
  {
    name: "Sri Lanka (ශ්‍රී ලංකාව)",
    iso2: "LK",
    dialCode: "94",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇱🇰",
    flagEmojiUnicode: "U+1F1F1 U+1F1F0",
  },
  {
    name: "Sudan (‫السودان‬‎)",
    iso2: "SD",
    dialCode: "249",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇸🇩",
    flagEmojiUnicode: "U+1F1F8 U+1F1E9",
  },
  {
    name: "Suriname",
    iso2: "SR",
    dialCode: "597",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇸🇷",
    flagEmojiUnicode: "U+1F1F8 U+1F1F7",
  },
  {
    name: "Svalbard and Jan Mayen",
    iso2: "SJ",
    dialCode: "47",
    priority: 1,
    areaCodes: null,
    flagEmoji: "🇸🇯",
    flagEmojiUnicode: "U+1F1F8 U+1F1EF",
  },
  {
    name: "Swaziland",
    iso2: "SZ",
    dialCode: "268",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇸🇿",
    flagEmojiUnicode: "U+1F1F8 U+1F1FF",
  },
  {
    name: "Sweden (Sverige)",
    iso2: "SE",
    dialCode: "46",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇸🇪",
    flagEmojiUnicode: "U+1F1F8 U+1F1EA",
  },
  {
    name: "Switzerland (Schweiz)",
    iso2: "CH",
    dialCode: "41",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇨🇭",
    flagEmojiUnicode: "U+1F1E8 U+1F1ED",
  },
  {
    name: "Syria (‫سوريا‬‎)",
    iso2: "SY",
    dialCode: "963",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇸🇾",
    flagEmojiUnicode: "U+1F1F8 U+1F1FE",
  },
  {
    name: "São Tomé and Príncipe (São Tomé e Príncipe)",
    iso2: "ST",
    dialCode: "239",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇸🇹",
    flagEmojiUnicode: "U+1F1F8 U+1F1F9",
  },
  {
    name: "Taiwan (台灣)",
    iso2: "TW",
    dialCode: "886",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇹🇼",
    flagEmojiUnicode: "U+1F1F9 U+1F1FC",
  },
  {
    name: "Tajikistan",
    iso2: "TJ",
    dialCode: "992",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇹🇯",
    flagEmojiUnicode: "U+1F1F9 U+1F1EF",
  },
  {
    name: "Tanzania",
    iso2: "TZ",
    dialCode: "255",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇹🇿",
    flagEmojiUnicode: "U+1F1F9 U+1F1FF",
  },
  {
    name: "Thailand (ไทย)",
    iso2: "TH",
    dialCode: "66",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇹🇭",
    flagEmojiUnicode: "U+1F1F9 U+1F1ED",
  },
  {
    name: "Timor-Leste",
    iso2: "TL",
    dialCode: "670",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇹🇱",
    flagEmojiUnicode: "U+1F1F9 U+1F1F1",
  },
  {
    name: "Togo",
    iso2: "TG",
    dialCode: "228",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇹🇬",
    flagEmojiUnicode: "U+1F1F9 U+1F1EC",
  },
  {
    name: "Tokelau",
    iso2: "TK",
    dialCode: "690",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇹🇰",
    flagEmojiUnicode: "U+1F1F9 U+1F1F0",
  },
  {
    name: "Tonga",
    iso2: "TO",
    dialCode: "676",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇹🇴",
    flagEmojiUnicode: "U+1F1F9 U+1F1F4",
  },
  {
    name: "Trinidad and Tobago",
    iso2: "TT",
    dialCode: "1868",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇹🇹",
    flagEmojiUnicode: "U+1F1F9 U+1F1F9",
  },
  {
    name: "Tunisia (‫تونس‬‎)",
    iso2: "TN",
    dialCode: "216",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇹🇳",
    flagEmojiUnicode: "U+1F1F9 U+1F1F3",
  },
  {
    name: "Turkey (Türkiye)",
    iso2: "TR",
    dialCode: "90",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇹🇷",
    flagEmojiUnicode: "U+1F1F9 U+1F1F7",
  },
  {
    name: "Turkmenistan",
    iso2: "TM",
    dialCode: "993",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇹🇲",
    flagEmojiUnicode: "U+1F1F9 U+1F1F2",
  },
  {
    name: "Turks and Caicos Islands",
    iso2: "TC",
    dialCode: "1649",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇹🇨",
    flagEmojiUnicode: "U+1F1F9 U+1F1E8",
  },
  {
    name: "Tuvalu",
    iso2: "TV",
    dialCode: "688",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇹🇻",
    flagEmojiUnicode: "U+1F1F9 U+1F1FB",
  },
  {
    name: "U.S. Virgin Islands",
    iso2: "VI",
    dialCode: "1340",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇻🇮",
    flagEmojiUnicode: "U+1F1FB U+1F1EE",
  },
  {
    name: "Uganda",
    iso2: "UG",
    dialCode: "256",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇺🇬",
    flagEmojiUnicode: "U+1F1FA U+1F1EC",
  },
  {
    name: "Ukraine (Україна)",
    iso2: "UA",
    dialCode: "380",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇺🇦",
    flagEmojiUnicode: "U+1F1FA U+1F1E6",
  },
  {
    name: "United Arab Emirates (‫الإمارات العربية المتحدة‬‎)",
    iso2: "AE",
    dialCode: "971",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇦🇪",
    flagEmojiUnicode: "U+1F1E6 U+1F1EA",
  },
  {
    name: "United Kingdom",
    iso2: "GB",
    dialCode: "44",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇬🇧",
    flagEmojiUnicode: "U+1F1EC U+1F1E7",
  },
  {
    name: "United States",
    iso2: "US",
    dialCode: "1",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇺🇸",
    flagEmojiUnicode: "U+1F1FA U+1F1F8",
  },
  {
    name: "Uruguay",
    iso2: "UY",
    dialCode: "598",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇺🇾",
    flagEmojiUnicode: "U+1F1FA U+1F1FE",
  },
  {
    name: "Uzbekistan (Oʻzbekiston)",
    iso2: "UZ",
    dialCode: "998",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇺🇿",
    flagEmojiUnicode: "U+1F1FA U+1F1FF",
  },
  {
    name: "Vanuatu",
    iso2: "VU",
    dialCode: "678",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇻🇺",
    flagEmojiUnicode: "U+1F1FB U+1F1FA",
  },
  {
    name: "Vatican City (Città del Vaticano)",
    iso2: "VA",
    dialCode: "39",
    priority: 1,
    areaCodes: null,
    flagEmoji: "🇻🇦",
    flagEmojiUnicode: "U+1F1FB U+1F1E6",
  },
  {
    name: "Venezuela",
    iso2: "VE",
    dialCode: "58",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇻🇪",
    flagEmojiUnicode: "U+1F1FB U+1F1EA",
  },
  {
    name: "Vietnam (Việt Nam)",
    iso2: "VN",
    dialCode: "84",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇻🇳",
    flagEmojiUnicode: "U+1F1FB U+1F1F3",
  },
  {
    name: "Wallis and Futuna (Wallis-et-Futuna)",
    iso2: "WF",
    dialCode: "681",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇼🇫",
    flagEmojiUnicode: "U+1F1FC U+1F1EB",
  },
  {
    name: "Western Sahara (‫الصحراء الغربية‬‎)",
    iso2: "EH",
    dialCode: "212",
    priority: 1,
    areaCodes: null,
    flagEmoji: "🇪🇭",
    flagEmojiUnicode: "U+1F1EA U+1F1ED",
  },
  {
    name: "Yemen (‫اليمن‬‎)",
    iso2: "YE",
    dialCode: "967",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇾🇪",
    flagEmojiUnicode: "U+1F1FE U+1F1EA",
  },
  {
    name: "Zambia",
    iso2: "ZM",
    dialCode: "260",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇿🇲",
    flagEmojiUnicode: "U+1F1FF U+1F1F2",
  },
  {
    name: "Zimbabwe",
    iso2: "ZW",
    dialCode: "263",
    priority: 0,
    areaCodes: null,
    flagEmoji: "🇿🇼",
    flagEmojiUnicode: "U+1F1FF U+1F1FC",
  },
  {
    name: "Åland Islands",
    iso2: "AX",
    dialCode: "358",
    priority: 1,
    areaCodes: null,
    flagEmoji: "🇦🇽",
    flagEmojiUnicode: "U+1F1E6 U+1F1FD",
  },
];
