import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MolLoadingWithLogo = _resolveComponent("MolLoadingWithLogo")!
  const _component_AtomText = _resolveComponent("AtomText")!
  const _component_AtomMoney = _resolveComponent("AtomMoney")!
  const _component_OrgFormMoneyInput = _resolveComponent("OrgFormMoneyInput")!
  const _component_AtomInput = _resolveComponent("AtomInput")!
  const _component_AtomFormControl = _resolveComponent("AtomFormControl")!
  const _component_AtomButton = _resolveComponent("AtomButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.css(), { [_ctx.css('loading')]: _ctx.isLoading }])
  }, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_MolLoadingWithLogo, {
          key: 0,
          title: "Refreshing your bill..."
        }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_AtomText, {
            type: _ctx.AtomTextTypeEnum.SUBTITLE,
            class: _normalizeClass(_ctx.css('title'))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Split the bill ")
            ]),
            _: 1
          }, 8, ["type", "class"]),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.css('total'))
          }, [
            _createElementVNode("span", null, [
              _createVNode(_component_AtomText, {
                color: _ctx.AtomTextColorEnum.BASE_50
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Order total: ")
                ]),
                _: 1
              }, 8, ["color"]),
              _createVNode(_component_AtomText, {
                color: _ctx.AtomTextColorEnum.BASE_50,
                type: _ctx.AtomTextTypeEnum.BODY_BOLD
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_AtomMoney, {
                    currency: _ctx.currency,
                    amount: _ctx.billTotal,
                    locale: _ctx.locale
                  }, null, 8, ["currency", "amount", "locale"])
                ]),
                _: 1
              }, 8, ["color", "type"])
            ]),
            (_ctx.showRemaining)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("span", {
                    class: _normalizeClass(_ctx.css('separator'))
                  }, "●", 2),
                  _createElementVNode("span", null, [
                    _createVNode(_component_AtomText, {
                      color: _ctx.AtomTextColorEnum.BASE_50
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Remaining: ")
                      ]),
                      _: 1
                    }, 8, ["color"]),
                    _createVNode(_component_AtomText, {
                      color: _ctx.AtomTextColorEnum.BASE_50,
                      type: _ctx.AtomTextTypeEnum.BODY_BOLD
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_AtomMoney, {
                          currency: _ctx.currency,
                          amount: _ctx.billRemaining,
                          locale: _ctx.locale
                        }, null, 8, ["currency", "amount", "locale"])
                      ]),
                      _: 1
                    }, 8, ["color", "type"])
                  ])
                ], 64))
              : _createCommentVNode("", true)
          ], 2),
          _createElementVNode("form", {
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.confirmShare && _ctx.confirmShare(...args)), ["prevent"]))
          }, [
            _createVNode(_component_OrgFormMoneyInput, {
              class: _normalizeClass(_ctx.css('input')),
              label: "Your share",
              value: _ctx.userShareAmount,
              "currency-code": _ctx.currency,
              "on-change": (value) => _ctx.validateAmount(value),
              errorMsg: _ctx.shareAmountError,
              locale: _ctx.locale,
              autofocus: ""
            }, null, 8, ["class", "value", "currency-code", "on-change", "errorMsg", "locale"]),
            _createVNode(_component_AtomFormControl, {
              label: "Your name",
              class: _normalizeClass(_ctx.css('input')),
              isOptional: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AtomInput, {
                  modelValue: _ctx.userName,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userName) = $event)),
                  "on-input": (value) => (_ctx.userName = value)
                }, null, 8, ["modelValue", "on-input"])
              ]),
              _: 1
            }, 8, ["class"]),
            _createVNode(_component_AtomButton, {
              class: _normalizeClass(_ctx.css('confirm')),
              onClick: () => _ctx.confirmShare(),
              size: _ctx.AtomButtonSizeEnum.LARGE,
              isDisabled: _ctx.isShareAmountInvalid || _ctx.userShareAmount === 0
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Confirm your share ")
              ]),
              _: 1
            }, 8, ["class", "onClick", "size", "isDisabled"])
          ], 32),
          _createVNode(_component_AtomButton, {
            class: _normalizeClass(_ctx.css('cancel')),
            size: _ctx.AtomButtonSizeEnum.LARGE,
            type: _ctx.AtomButtonTypeEnum.SECONDARY,
            onClick: _ctx.onCancel
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Cancel ")
            ]),
            _: 1
          }, 8, ["class", "size", "type", "onClick"])
        ], 64))
  ], 2))
}