
import { defineComponent, onMounted, ref } from "vue";
import { ViewBill } from "@/view/bill";
import { ViewPaymentSuccess } from "@/view/payment-success";
import { ViewPaymentFail } from "@/view/payment-fail";
import { ViewNoBill } from "@/view/no-bill";
import { ViewAuth } from "@/view/auth/main";
import { ViewsEnum } from "./view/views.enum";
import {
  fetchBusinessBySlug,
  IFetchBusinessBySlugResponse,
} from "./repo/fetch-business-by-slug";
import { useRoute } from "vue-router";
import {
  report,
  reportGlobalTags,
  reportIdentify,
} from "@chatfood/bug-reporter";
// import { fetchBusinessColorset } from "@/repo/business/fetch-colorset";
// import { defineColors } from "@/util/colorset";
import { setCurrentLanguage } from "@/i18n";
import { fetchUserInfo } from "@/repo/auth/fetch-user-info";

export default defineComponent({
  name: "ViewController",
  components: {
    ViewBill,
    ViewPaymentSuccess,
    ViewPaymentFail,
    ViewNoBill,
    ViewAuth,
  },
  props: {
    businessSlug: {
      type: String,
      required: true,
    },
    tableId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const currentView = ref<ViewsEnum>(ViewsEnum.BILL);
    const viewProps = ref<unknown>({});
    const business = ref<IFetchBusinessBySlugResponse>();
    const accessToken = window.localStorage.getItem("auth._token.local") || "";
    const isLoggedIn = ref(Boolean(accessToken));

    async function getBusiness(): Promise<void> {
      try {
        business.value = await fetchBusinessBySlug(props.businessSlug);
      } catch (e) {
        report(e);
      }
    }

    async function getUserData(): Promise<void> {
      try {
        const userDetails = await fetchUserInfo(accessToken);
        reportIdentify({ id: userDetails.id, email: userDetails.email });
      } catch (e) {
        report(e);
      }
    }

    onMounted(async () => {
      await Promise.all(
        isLoggedIn.value ? [getBusiness(), getUserData()] : [getBusiness()]
      );

      if (business.value) {
        // try {
        //   const colorset = await fetchBusinessColorset(business.value.id);
        //   colorset && defineColors(colorset);
        // } catch (e) {
        //   report(__filename, e);
        // }

        await setCurrentLanguage(business.value?.language);
      }

      try {
        reportGlobalTags({
          "business.id": business.value?.id,
          "business.slug": business.value?.url,
        });
      } catch (e) {
        report(e);
      }
    });

    if (currentView.value === ViewsEnum.BILL) {
      viewProps.value = {
        ...props,
        business,
        checkoutRedirect: Boolean(useRoute().query["cko-session-id"]),
      };
    }

    if (currentView.value === ViewsEnum.PAYMENT_SUCCESS) {
      viewProps.value = {
        ...props,
        business,
      };
    }

    function setCurrentView(view: ViewsEnum, isLoginFlow: boolean): void {
      if (isLoginFlow) {
        viewProps.value = {
          ...props,
          business,
          isLoginFlow,
          checkoutRedirect: false,
        };
      }
      currentView.value = view;
    }

    return {
      viewProps,
      currentView,
      setCurrentView,
      business,
    };
  },
});
