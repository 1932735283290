import { computed, ref, unref } from "vue";
import { i18n } from "@chatfood/core-utils";
import type { List, Object, String } from "ts-toolbelt";
import enLanguageSet from "./languages/en-US.json";

const i18nInstance = ref<i18n>(
  new i18n({
    currentLanguageSet: enLanguageSet,
    fallbackLanguageSet: enLanguageSet,
  })
);

export function t(
  key: String.Join<List.Flatten<Object.Paths<typeof enLanguageSet>>, ".">,
  params: Record<string, number | string> = {}
): string {
  return unref(computed(() => i18nInstance.value.translate(key, params)));
}

export async function setCurrentLanguage(language: string): Promise<void> {
  const languageSet = await import(`./languages/${language}.json`);
  i18nInstance.value = new i18n({
    currentLanguageSet: languageSet,
    fallbackLanguageSet: enLanguageSet,
  });
}
