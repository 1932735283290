import { IRepoErrors } from "../errors";
import { http } from "../http";

export type IFetchUserInfoResponse = {
  countryCode: string;
  email: string;
  id: string;
  name: string;
  phoneNumber: string;
};

export class FetchUserInfoError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchUserInfo");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchUserInfo(
  accessToken: string
): Promise<IFetchUserInfoResponse> {
  let response;

  try {
    const result = await http.get("/api/v1/customers/me", {
      headers: {
        Authorization: accessToken,
      },
    });
    response = {
      id: result.data?.id,
      name: result.data?.name,
      email: result.data?.email,
      countryCode: result.data?.country_code,
      phoneNumber: result.data?.phone_number,
    };
  } catch (e: any) {
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchUserInfoError(e);
  }

  return response;
}
