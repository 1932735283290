import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-48b2bdde"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomIcon = _resolveComponent("AtomIcon")!
  const _component_AtomInput = _resolveComponent("AtomInput")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.css())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.css('filter'))
    }, [
      _createVNode(_component_AtomInput, {
        modelValue: _ctx.filter,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter) = $event)),
        autofocus: "",
        placeholder: "Search country...",
        "on-input": (value) => _ctx.filter = value
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_AtomIcon, {
            name: _ctx.AtomIconNameEnum.SEARCH
          }, null, 8, ["name"])
        ]),
        _: 1
      }, 8, ["modelValue", "on-input"])
    ], 2),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupedCountries, (group, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass(_ctx.css('group'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.css('group-title'))
        }, _toDisplayString(group.name), 3),
        _createElementVNode("ul", {
          class: _normalizeClass(_ctx.css('list'))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.children, (country) => {
            return (_openBlock(), _createElementBlock("li", {
              key: country.iso2,
              class: _normalizeClass(_ctx.css('list-line'))
            }, [
              _createElementVNode("a", {
                href: "#",
                class: _normalizeClass(_ctx.css('link')),
                onClick: _withModifiers(($event: any) => (_ctx.selectCountry(country)), ["prevent"])
              }, [
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.css('flag-emoji'))
                }, _toDisplayString(country.flagEmoji), 3),
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.css('name'))
                }, _toDisplayString(country.name), 3),
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.css('dial-code'))
                }, _toDisplayString(_ctx.dialCode(country.dialCode)), 3)
              ], 10, _hoisted_1)
            ], 2))
          }), 128))
        ], 2)
      ], 2))
    }), 128))
  ], 2))
}