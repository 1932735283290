
import { bemBuilder, toMoney } from "@chatfood/core-utils";
import {
  computed,
  defineComponent,
  getCurrentInstance,
  PropType,
  ref,
  watch,
} from "vue";
import { AtomInput } from "../../atom/input";
import { AtomFormControl } from "../../atom/form-control";
import { AtomText, AtomTextColorEnum, AtomTextTypeEnum } from "../../atom/text";
import { CurrencyCodeEnum, InputTypesEnum } from "@/enum";

const css = bemBuilder("org-form-money-input");

export default defineComponent({
  name: "OrgFormMoneyInput",
  components: {
    AtomFormControl,
    AtomInput,
    AtomText,
  },
  props: {
    value: {
      type: Number,
      required: true,
    },
    currencyCode: {
      type: String as PropType<CurrencyCodeEnum>,
      required: true,
    },
    locale: {
      type: String,
      default: "en-US",
    },
    label: {
      type: String,
      default: "",
    },
    errorMsg: {
      type: String,
      default: "",
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    onChange: {
      type: Function as PropType<(cents: number) => void>,
      required: true,
    },
  },
  setup(props) {
    const displayAmount = ref("0");
    const rawAmount = ref(0);
    const symbol = computed(() => {
      try {
        const currency = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: props.currencyCode,
        })
          .formatToParts(1)
          .find((obj) => obj.type === "currency");

        return currency?.value ?? props.currencyCode;
      } catch (e) {
        return props.currencyCode;
      }
    });

    const toInteger = (value: string | number): number => {
      if ((typeof value === "number" && isNaN(value)) || value === "") {
        value = 0;
      }

      let clearedString = new String(value)
        .toString()
        .replaceAll(/[^0-9]/g, "")
        .replaceAll(new RegExp(/\.|,/g), "");

      if (clearedString === "") {
        clearedString = "0";
      }

      return parseInt(clearedString, 10);
    };

    const vm = getCurrentInstance();

    const previousDisplayLength = ref();
    const cursorPositionStorage = ref();

    watch(
      () => props.value,
      (newValue) => {
        if (newValue === rawAmount.value) return;
        displayAmount.value = toMoneyFormat(newValue);
      },
      {
        immediate: true,
      }
    );

    function toMoneyFormat(amount: number): string {
      const absAmount = Math.abs(amount) ?? 0;
      return toMoney(absAmount, props.currencyCode, {
        locale: props.locale,
        hideSymbol: true,
      });
    }

    function setValue(newInput: string, cursorPosition: number): void {
      cursorPositionStorage.value = cursorPosition;
      previousDisplayLength.value = displayAmount.value.length;
      rawAmount.value = toInteger(newInput);
      displayAmount.value = toMoneyFormat(rawAmount.value);
      vm?.proxy?.$forceUpdate();
      props.onChange(rawAmount.value);
    }

    return {
      css,
      symbol,
      setValue,
      displayAmount,
      AtomTextColorEnum,
      AtomTextTypeEnum,
      InputTypesEnum,
    };
  },
});
