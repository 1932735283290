import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3fd60da9"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isLoading)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        class: _normalizeClass(_ctx.css('loading')),
        "data-spec": "apple-pay-processing"
      }, " Processing... ", 2))
    : (_ctx.showButton)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          ref: "applePayButton",
          class: _normalizeClass(_ctx.css('button')),
          disabled: _ctx.disabled,
          style: {"-webkit-appearance":"-apple-pay-button"},
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.triggerApplePay && _ctx.triggerApplePay(...args)), ["prevent"]))
        }, null, 10, _hoisted_1))
      : _createCommentVNode("", true)
}