export enum AtomIconNameEnum {
  ARROW_DOWN = "arrow-down",
  ARROW_RIGHT = "arrow-right",
  CARET_DOWN = "caret-down",
  CARD = "card",
  BACK = "back",
  ARROW_LEFT = "arrow-left",
  LOADING = "loading",
  LOCK = "lock",
  MONEY = "money",
  SEARCH = "search",
  REFRESH = "refresh",
  TIMES = "times",
  HELP = "help",
  BILL = "bill",
  STAR = "star",
}
