export const checkoutStyles = {
  base: {
    color: "dimgray",
    fontSize: "12px",
    fontWeight: 400,
    letterSpacing: "0.5px",
    fontFamily: "Inter, sans-serif",
  },
  focus: {
    color: "black",
    borderColor: "black",
  },
  invalid: {
    color: "red",
    borderColor: "red",
  },
  placeholder: {
    base: {
      color: "gray",
      letterSpacing: "0.2px",
    },
  },
};
