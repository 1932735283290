import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomIcon = _resolveComponent("AtomIcon")!

  return (_openBlock(), _createElementBlock("a", {
    href: "#",
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)), ["prevent"])),
    class: _normalizeClass(_ctx.css())
  }, [
    _createVNode(_component_AtomIcon, {
      name: _ctx.iconName,
      size: 21,
      class: _normalizeClass(_ctx.css('icon'))
    }, null, 8, ["name", "class"])
  ], 2))
}