import { removeStorageItem, setStorageItem } from "@/util/local-storage";

export const getStorageLoyaltyKey = (id: string): string =>
  `bill-loyalty-${id}`;

export type LoyaltyProgramInfo = {
  amount: number;
  expirationDays: number;
  loyaltyPoints: number;
  maximumRedeemedOrderAmount: number;
  maximumRedeemedOrderPercentage: number;
  orderAmountPercentageWorth: number;
  signupPoints: number;
  isBurnedPoints: boolean;
};

export const saveLoyaltyPoints = (
  id: string,
  value: LoyaltyProgramInfo
): void => {
  const key = getStorageLoyaltyKey(id);
  if (!value.orderAmountPercentageWorth) {
    return removeStorageItem(key);
  }
  setStorageItem(key, value);
};

export const getRedeemableLoyaltyPoints = (
  total: number,
  loyaltyPoints: number,
  loyaltyProgram: {
    maximumRedeemedOrderAmount: number;
    maximumRedeemedOrderPercentage: number;
  }
): number => {
  if (!loyaltyPoints || !loyaltyProgram) return 0;

  try {
    let customerLoyaltyPoints = loyaltyPoints;
    const maxCapAmount = loyaltyProgram.maximumRedeemedOrderAmount;
    const maxCapPercentage = loyaltyProgram.maximumRedeemedOrderPercentage;

    if (customerLoyaltyPoints >= maxCapAmount) {
      customerLoyaltyPoints = maxCapAmount;
    }

    const maxRedeemByPercentage = (total * maxCapPercentage) / 100;

    if (customerLoyaltyPoints > maxRedeemByPercentage) {
      customerLoyaltyPoints = maxRedeemByPercentage;
    }

    return Math.floor(customerLoyaltyPoints);
  } catch (e) {
    console.error(
      "There was an error calculating redeemable loyalty points",
      e
    );

    return 0;
  }
};

export const getEarnedLoyaltyPoints = (
  orderTotal: number,
  cashbackPercentage: number
): number => {
  if (!orderTotal) return 0;

  try {
    const percentage = cashbackPercentage ?? 0;
    return orderTotal > 0 ? Math.floor((orderTotal / 100) * percentage) : 0;
  } catch (e) {
    console.error("There was an error calculating earned loyalty points", e);
    return 0;
  }
};
