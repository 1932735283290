import axios, { AxiosError, AxiosResponse } from "axios";
import { transactionIdGenerator } from "@/util/transaction-id-generator";

const client = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

client.interceptors.request.use(
  (config) => {
    if (config && config.headers) {
      config.headers["X-Transaction-Id"] = transactionIdGenerator();
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const http = client;

export type IHttpErrorResponse = Record<string, unknown> &
  AxiosResponse &
  AxiosError;
