import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MolIconButton = _resolveComponent("MolIconButton")!
  const _component_AuthRequestOtp = _resolveComponent("AuthRequestOtp")!
  const _component_AuthValidateOtp = _resolveComponent("AuthValidateOtp")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.css())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.css('content'))
    }, [
      _createVNode(_component_MolIconButton, {
        "icon-name": _ctx.AtomIconNameEnum.ARROW_LEFT,
        "on-click": _ctx.backPage
      }, null, 8, ["icon-name", "on-click"]),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.css('components'))
      }, [
        (_ctx.currentComponent === 'request')
          ? (_openBlock(), _createBlock(_component_AuthRequestOtp, {
              key: 0,
              "business-id": _ctx.business.id,
              "business-country": _ctx.business.country,
              "on-complete": _ctx.requestOtp
            }, null, 8, ["business-id", "business-country", "on-complete"]))
          : _createCommentVNode("", true),
        (_ctx.currentComponent === 'validate')
          ? (_openBlock(), _createBlock(_component_AuthValidateOtp, {
              key: 1,
              "business-id": _ctx.business.id,
              "phone-number": _ctx.phoneNumberObj.number.significant,
              "phone-number-international": _ctx.phoneNumberObj.number.international,
              "phone-countryCode": _ctx.phoneNumberObj.country.dialCode,
              "on-complete": _ctx.validateOtp
            }, null, 8, ["business-id", "phone-number", "phone-number-international", "phone-countryCode", "on-complete"]))
          : _createCommentVNode("", true)
      ], 2)
    ], 2)
  ], 2))
}