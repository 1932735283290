import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomIcon = _resolveComponent("AtomIcon")!
  const _component_AtomText = _resolveComponent("AtomText")!
  const _component_AtomMoney = _resolveComponent("AtomMoney")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.css())
  }, [
    (_ctx.showBackButton)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          href: "#",
          class: _normalizeClass(_ctx.css('back')),
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onBack && _ctx.onBack(...args)), ["prevent"]))
        }, [
          _createVNode(_component_AtomIcon, {
            name: _ctx.AtomIconNameEnum.BACK,
            color: _ctx.AtomTextColorEnum.BASE_30
          }, null, 8, ["name", "color"]),
          _createVNode(_component_AtomText, null, {
            default: _withCtx(() => [
              _createTextVNode("Go back")
            ]),
            _: 1
          })
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.showTitle)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.css('header'))
        }, [
          _createVNode(_component_AtomText, {
            type: _ctx.AtomTextTypeEnum.SUBTITLE
          }, {
            default: _withCtx(() => [
              _createTextVNode("Payment method")
            ]),
            _: 1
          }, 8, ["type"])
        ], 2))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "paymentMethod", {}, undefined, true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.css('description'))
    }, [
      _createElementVNode("div", null, [
        _createVNode(_component_AtomText, {
          type: _ctx.AtomTextTypeEnum.SUBTITLE,
          tag: _ctx.AtomTextTagEnum.SPAN
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.isSplitBill ? "Your total" : "Total"), 1)
          ]),
          _: 1
        }, 8, ["type", "tag"]),
        _renderSlot(_ctx.$slots, "paymentInfo", {}, undefined, true)
      ]),
      _createVNode(_component_AtomText, {
        type: _ctx.AtomTextTypeEnum.SUBTITLE
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AtomMoney, {
            currency: _ctx.billCurrency,
            amount: _ctx.billTotal,
            locale: _ctx.countryCode
          }, null, 8, ["currency", "amount", "locale"])
        ]),
        _: 1
      }, 8, ["type"])
    ], 2),
    _renderSlot(_ctx.$slots, "paymentButton", {}, undefined, true)
  ], 2))
}