
import { bemBuilder } from "@chatfood/core-utils";
import { defineComponent, PropType, ref, computed, onMounted } from "vue";
import { AtomInput } from "../../atom/input";
import { AtomIcon, AtomIconNameEnum } from "../../atom/icon";
import { ICountry, IGroupedCountries, ICountryNameIso2 } from "./types";
import { countries } from "./countries";

const css = bemBuilder("mol-select-country");

export default defineComponent({
  name: "MolSelectCountry",
  components: {
    AtomInput,
    AtomIcon,
  },
  props: {
    selectedCountry: {
      type: String as PropType<ICountryNameIso2>,
      default: "",
    },
    onSelectedCountry: {
      type: Function as PropType<(country: ICountry) => void>,
      required: true,
    },
  },
  setup(props) {
    const filter = ref("");

    const filteredCountries = computed(() => {
      if (filter.value === "") {
        return countries;
      }

      return countries.filter(
        (country) =>
          country.name.toLowerCase().includes(filter.value.toLowerCase()) ||
          country.iso2.toLowerCase().includes(filter.value.toLowerCase()) ||
          country.dialCode.includes(filter.value)
      );
    });

    const groupedCountries = computed(() =>
      filteredCountries.value.reduce(
        (acc: IGroupedCountries, country: ICountry) => {
          const group = country.name[0];

          if (acc[group] === undefined) {
            acc[group] = { name: group, children: [country] };
          } else {
            acc[group].children.push(country);
          }

          return acc;
        },
        {}
      )
    );

    function selectCountry(country: ICountry): void {
      props.onSelectedCountry(country);
    }

    function dialCode(code: string): string {
      return `+${code}`;
    }

    onMounted(() => {
      const hasSelectedCountry = Boolean(props.selectedCountry);
      if (!hasSelectedCountry) return;

      const country = countries.find(
        (country) => country.iso2 === props.selectedCountry
      );
      if (country) {
        selectCountry(country);
      }
    });

    return {
      css,
      filter,
      dialCode,
      selectCountry,
      groupedCountries,
      AtomIconNameEnum,
    };
  },
});
