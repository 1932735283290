import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b60b4fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled", "value", "placeholder", "type", "pattern", "autofocus"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass([
      _ctx.css(),
      {
        [_ctx.css('', 'invalid')]: _ctx.invalid,
        [_ctx.css('', 'disabled')]: _ctx.isDisabled,
        [_ctx.css('', 'focused')]: _ctx.focused,
      },
    ])
  }, [
    (_ctx.hasIcon)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.css('icon'))
        }, [
          _renderSlot(_ctx.$slots, "icon", {}, undefined, true)
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.hasPrefix)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.css('prefix'))
        }, [
          _renderSlot(_ctx.$slots, "prefix", {}, undefined, true)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      ref: "input",
      class: _normalizeClass(_ctx.css('field')),
      disabled: _ctx.isDisabled,
      value: _ctx.modelValue,
      placeholder: _ctx.placeholder,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setInput && _ctx.setInput(...args))),
      onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.setBlur && _ctx.setBlur(...args))),
      onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.setFocus && _ctx.setFocus(...args))),
      type: _ctx.type,
      pattern: _ctx.pattern,
      autofocus: _ctx.autofocus
    }, null, 42, _hoisted_1),
    (_ctx.hasSuffix)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(_ctx.css('suffix'))
        }, [
          _renderSlot(_ctx.$slots, "suffix", {}, undefined, true)
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}