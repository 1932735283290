
import { bemBuilder } from "@chatfood/core-utils";
import { defineComponent, computed } from "vue";
import { AtomGraphic, AtomText, AtomTextColorEnum } from "@/design-system";

const css = bemBuilder("mol-powered-by");

export default defineComponent({
  name: "MolPoweredByChatfood",
  components: {
    AtomGraphic,
    AtomText,
  },
  props: {
    business: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const linkUrl = computed(
      () =>
        `https://www.chatfood.io?utm_content=powered-by-chatfood&utm_source=business-storefront&utm_medium=menu-link&utm_campaign=${props.business}`
    );

    return {
      css,
      linkUrl,
      AtomTextColorEnum,
    };
  },
});
