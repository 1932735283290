import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9e4a7b8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MolIconButton = _resolveComponent("MolIconButton")!

  return (_openBlock(), _createBlock(_Transition, {
    name: "drawer-fade",
    onEnter: _cache[3] || (_cache[3] = ($event: any) => (_ctx.displayContent(true)))
  }, {
    default: _withCtx(() => [
      (_ctx.isDrawerVisible)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            role: "drawer",
            "aria-label": _ctx.label,
            "aria-modal": "true",
            class: _normalizeClass(_ctx.css())
          }, [
            (!_ctx.full)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(_ctx.css('header')),
                  onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.displayContent(false)), ["self"]))
                }, [
                  (_ctx.showCloseButton)
                    ? (_openBlock(), _createBlock(_component_MolIconButton, {
                        key: 0,
                        class: _normalizeClass(_ctx.css('close')),
                        "icon-name": _ctx.AtomIconNameEnum.TIMES,
                        "on-click": () => _ctx.displayContent(false)
                      }, null, 8, ["class", "icon-name", "on-click"]))
                    : _createCommentVNode("", true),
                  (_ctx.hasHeaderSlot)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _renderSlot(_ctx.$slots, "header", {}, undefined, true)
                      ]))
                    : _createCommentVNode("", true)
                ], 2))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.css('content'))
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.css('filler'))
              }, [
                _createVNode(_Transition, {
                  name: "drawer-slide-up",
                  onLeave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.close()))
                }, {
                  default: _withCtx(() => [
                    (_ctx.isContentVisible)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass(_ctx.css('aligner')),
                          style: _normalizeStyle(_ctx.containerStyles),
                          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.displayContent(false)), ["self"]))
                        }, [
                          _createElementVNode("div", {
                            class: _normalizeClass([
                  _ctx.css('shelve'),
                  {
                    [_ctx.css('shelve', 'full')]: _ctx.full,
                    [_ctx.css('shelve', 'no-scroll')]: _ctx.noScroll,
                    [_ctx.css('shelve', 'rounded')]: _ctx.shouldBeRounded,
                  },
                ])
                          }, [
                            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                          ], 2)
                        ], 6))
                      : _createCommentVNode("", true)
                  ]),
                  _: 3
                })
              ], 2)
            ], 2),
            (!_ctx.hideBackground)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  class: _normalizeClass(_ctx.css('bg-color'))
                }, null, 2))
              : _createCommentVNode("", true)
          ], 10, _hoisted_1))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}