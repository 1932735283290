import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomText = _resolveComponent("AtomText")!
  const _component_MolInputPhoneNumber = _resolveComponent("MolInputPhoneNumber")!
  const _component_AtomFormControl = _resolveComponent("AtomFormControl")!
  const _component_AtomButton = _resolveComponent("AtomButton")!

  return (_openBlock(), _createElementBlock("form", {
    class: _normalizeClass(_ctx.css()),
    onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.requestOtp && _ctx.requestOtp(...args)), ["prevent"]))
  }, [
    _createVNode(_component_AtomText, {
      type: _ctx.AtomTextTypeEnum.H2,
      color: _ctx.AtomTextColorEnum.CHARCOAL
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Enter your phone number ")
      ]),
      _: 1
    }, 8, ["type", "color"]),
    _createVNode(_component_AtomText, {
      class: _normalizeClass(_ctx.css('description'))
    }, {
      default: _withCtx(() => [
        _createTextVNode(" We will send you an SMS with a one-time verification code ")
      ]),
      _: 1
    }, 8, ["class"]),
    _createVNode(_component_AtomFormControl, {
      label: "Phone number",
      error: _ctx.error
    }, {
      default: _withCtx(() => [
        _createVNode(_component_MolInputPhoneNumber, {
          "default-country": _ctx.defaultCountry.iso2,
          "on-input": (value) => (_ctx.phoneNumberObject = value)
        }, null, 8, ["default-country", "on-input"])
      ]),
      _: 1
    }, 8, ["error"]),
    _createVNode(_component_AtomButton, {
      "is-loading": _ctx.isLoading,
      "is-disabled": _ctx.isDisabled,
      size: _ctx.AtomButtonSizeEnum.LARGE,
      "on-click": _ctx.requestOtp
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Send code ")
      ]),
      _: 1
    }, 8, ["is-loading", "is-disabled", "size", "on-click"])
  ], 34))
}