
import { bemBuilder } from "@chatfood/core-utils";
import { defineComponent, PropType, Ref, ref, computed, watch } from "vue";
import { AtomDrawer } from "../../atom/drawer";
import { AtomInput } from "../../atom/input";
import { AtomIcon, AtomIconNameEnum } from "../../atom/icon";
import { AtomText, AtomTextColorEnum, AtomTextTypeEnum } from "../../atom/text";
import { MolIconButton } from "../icon-button";
import { MolSelectCountry, findCountry, ICountry } from "../select-country";
import { InputStateEnum, IPhoneNumberObject } from "./types";
import PhoneNumber from "awesome-phonenumber";

const css = bemBuilder("mol-input-phone-number");

export default defineComponent({
  name: "MolInputPhoneNumber",
  components: {
    AtomDrawer,
    AtomText,
    AtomInput,
    AtomIcon,
    MolIconButton,
    MolSelectCountry,
  },
  props: {
    defaultCountry: {
      type: String,
      required: true,
    },
    onInput: {
      type: Function as PropType<(phoneNumber: IPhoneNumberObject) => void>,
      required: true,
    },
  },
  setup(props) {
    const inputState = ref(InputStateEnum.CLEAN);

    const selectedCountry = ref();

    watch(
      () => props.defaultCountry,
      (newCountry) => {
        selectedCountry.value = findCountry(newCountry);
      },
      {
        immediate: true,
      }
    );

    const phoneNumber = ref("");
    const phoneObject: Ref<IPhoneNumberObject> = computed(() => {
      const result = new PhoneNumber(
        phoneNumber.value,
        selectedCountry.value?.iso2 ?? props.defaultCountry
      ).toJSON();

      return {
        ...result,
        ...{
          country: selectedCountry.value,
        },
      };
    });

    const phoneText = computed(() => phoneObject.value.number.national || "");

    const acceptedPhoneType = "mobile";

    watch(
      () => phoneObject.value,
      (value) => {
        if (value.valid && value.type === acceptedPhoneType) {
          phoneNumber.value = phoneText.value;
          inputState.value = InputStateEnum.VALID;
        }

        if (value.valid && !value.type.includes(acceptedPhoneType)) {
          inputState.value = InputStateEnum.INVALID;
        }
      }
    );

    watch(
      () => phoneNumber.value,
      (_, oldValue) => {
        if (!testPhoneCharacteres()) {
          phoneNumber.value = oldValue;
        }
      }
    );

    const placeholder = computed(() => {
      const countryIso2 = selectedCountry.value?.iso2 ?? props.defaultCountry;
      return PhoneNumber.getExample(countryIso2, "mobile").getNumber(
        "national"
      );
    });

    function onSelectCountry(country: ICountry): void {
      selectedCountry.value = country;
      phoneNumber.value = "";
      displayCountriesList(false);
      props.onInput(phoneObject.value);
    }

    const showCountriesList = ref(false);

    function displayCountriesList(status: boolean): void {
      showCountriesList.value = status;
    }

    function testPhoneCharacteres(): boolean {
      const regex = /^[()\-+0-9\s]*$/;
      return regex.test(phoneNumber.value);
    }

    function testValue(): void {
      if (!testPhoneCharacteres()) {
        return;
      }

      props.onInput(phoneObject.value);
    }

    function onBlur(): void {
      if (phoneNumber.value === "") {
        inputState.value = InputStateEnum.CLEAN;
        return;
      }

      if (
        !phoneObject.value.valid ||
        !phoneObject.value.type.includes(acceptedPhoneType)
      ) {
        inputState.value = InputStateEnum.INVALID;
        return;
      }

      inputState.value = InputStateEnum.VALID;
    }

    const isInputInvalid = computed(
      () => inputState.value === InputStateEnum.INVALID
    );

    const countryCode = computed(() => `+${selectedCountry.value.dialCode}`);

    return {
      css,
      countryCode,
      phoneNumber,
      placeholder,
      selectedCountry,
      showCountriesList,
      phoneObject,
      isInputInvalid,
      testValue,
      onBlur,
      onSelectCountry,
      displayCountriesList,
      AtomIconNameEnum,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
