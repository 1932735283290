
import { bemBuilder } from "@chatfood/core-utils";
import { defineComponent, PropType, ref, Ref, watch } from "vue";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomButton,
  AtomButtonSizeEnum,
  AtomFormControl,
  MolInputPhoneNumber,
} from "@/design-system";
import { findCountry } from "@/design-system/mol/select-country";
import { IPhoneNumberObject } from "@/design-system/mol/input-phone-number/types";
import { authRequestOtp } from "@/repo/auth/request-otp";

const css = bemBuilder("auth-request-otp");

export default defineComponent({
  name: "AuthRequestOtp",
  components: {
    AtomText,
    AtomButton,
    AtomFormControl,
    MolInputPhoneNumber,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    businessCountry: {
      type: String,
      required: true,
    },
    onComplete: {
      type: Function as PropType<(phone: IPhoneNumberObject) => void>,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(false);
    const isDisabled = ref(false);
    const error = ref("");

    const phoneNumberObject: Ref<IPhoneNumberObject | undefined> = ref();

    watch(
      () => phoneNumberObject.value?.valid,
      (newValue) => (isDisabled.value = !newValue),
      { immediate: true }
    );

    const defaultCountry = ref(findCountry(props.businessCountry));

    async function requestOtp(): Promise<void> {
      const phoneNumber = phoneNumberObject.value;
      if (!phoneNumber || isDisabled.value) return;

      isLoading.value = true;
      isDisabled.value = true;

      try {
        await authRequestOtp({
          countryCode: phoneNumber.country.dialCode,
          phoneNumber: phoneNumber.number.significant,
          businessId: props.businessId,
        });

        props.onComplete(phoneNumber);
      } catch {
        error.value = "Oh no! We couldn't process your request.";
      } finally {
        isLoading.value = false;
        isDisabled.value = false;
      }
    }

    return {
      css,
      error,
      isLoading,
      isDisabled,
      requestOtp,
      defaultCountry,
      phoneNumberObject,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonSizeEnum,
    };
  },
});
