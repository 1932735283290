
import { bemBuilder } from "@chatfood/core-utils";
import { defineComponent, PropType } from "vue";
import { AtomText, AtomTextTypeEnum, AtomTextColorEnum } from "../../atom/text";
import { AtomGraphic } from "../../atom/graphic";
import { AtomButton } from "../../atom/button";

const css = bemBuilder("org-notification");

export default defineComponent({
  name: "OrgNotification",
  components: {
    AtomText,
    AtomGraphic,
    AtomButton,
  },
  props: {
    iconName: {
      type: String,
      required: true,
    },
    iconSize: {
      type: Number,
      default: 50,
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    buttonLabel: {
      type: String,
      default: "",
    },
    onClick: {
      type: Function as PropType<() => void>,
      default: () => null,
    },
  },
  setup() {
    return {
      css,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
