import { http } from "@/repo/http";
import { IRepoErrors } from "@/repo/errors";

export class PosRefreshBillError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: posBillRefresh");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function posRefreshBill(
  locationId: string,
  tableId: string
): Promise<boolean> {
  let response = false;
  try {
    await http.post(`pat/locations/${locationId}/refresh-bill`, {
      table_id: tableId,
    });
    response = true;
  } catch (e: any) {
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new PosRefreshBillError(e);
  }

  return response;
}
