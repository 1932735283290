import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-096a9bd6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomText = _resolveComponent("AtomText")!
  const _component_AtomMoney = _resolveComponent("AtomMoney")!
  const _component_AtomIcon = _resolveComponent("AtomIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.css()),
    onClick: () => _ctx.onClick()
  }, [
    (_ctx.amount > 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.css('active'))
        }, [
          _createElementVNode("div", null, [
            _createVNode(_component_AtomText, null, {
              default: _withCtx(() => [
                _createTextVNode(" Your share: ")
              ]),
              _: 1
            }),
            _createVNode(_component_AtomText, {
              type: _ctx.AtomTextTypeEnum.BODY_BOLD
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AtomMoney, {
                  amount: _ctx.amount,
                  currency: _ctx.currency,
                  locale: _ctx.locale
                }, null, 8, ["amount", "currency", "locale"])
              ]),
              _: 1
            }, 8, ["type"])
          ]),
          _createVNode(_component_AtomText, {
            type: _ctx.AtomTextTypeEnum.SMALL_BOLD,
            color: _ctx.AtomTextColorEnum.PRIMARY
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Change ")
            ]),
            _: 1
          }, 8, ["type", "color"])
        ], 2))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.css('empty'))
        }, [
          _createVNode(_component_AtomIcon, {
            name: _ctx.AtomIconNameEnum.MONEY,
            size: 21
          }, null, 8, ["name"]),
          _createVNode(_component_AtomText, {
            type: _ctx.AtomTextTypeEnum.BODY_BOLD,
            color: _ctx.AtomTextColorEnum.PRIMARY
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Split the bill ")
            ]),
            _: 1
          }, 8, ["type", "color"])
        ], 2))
  ], 10, _hoisted_1))
}