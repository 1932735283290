let scrollPosition = 0;
let previousActiveElement: any = null;

const bodyWrapper = document.querySelector("body") as HTMLBodyElement;

function enableScrolling(): void {
  bodyWrapper.style.removeProperty("overflow");
  bodyWrapper.style.removeProperty("position");
  bodyWrapper.style.removeProperty("top");
  bodyWrapper.style.removeProperty("width");

  window.scrollTo(0, scrollPosition);
}

function disableScrolling(): void {
  scrollPosition = window.pageYOffset;

  bodyWrapper.style.overflow = "hidden";
  bodyWrapper.style.position = "fixed";
  bodyWrapper.style.top = `-${scrollPosition}px`;
  bodyWrapper.style.width = "100%";
}

function restoreFocus(): void {
  previousActiveElement?.focus();
}

export function lockBody(): void {
  previousActiveElement = document.activeElement;
  disableScrolling();
}

export function unlockBody(): void {
  enableScrolling();
  restoreFocus();
}
