
import { bemBuilder } from "@chatfood/core-utils";
import { defineComponent, PropType, ref } from "vue";
import { AtomIconNameEnum, MolIconButton } from "@/design-system";
import { AuthRequestOtp } from "../request-otp";
import { AuthValidateOtp } from "../validate-otp";
import { IPhoneNumberObject } from "@/design-system/mol/input-phone-number/types";
import { ViewsEnum } from "@/view/views.enum";
import { IFetchBusinessBySlugResponse } from "@/repo/fetch-business-by-slug";
import mixpanel from "mixpanel-browser";
import { redeemSignupPoints } from "@/repo/redeem-signup-points";
import { fetchUserInfo } from "@/repo/auth/fetch-user-info";
import { report, reportIdentify } from "@chatfood/bug-reporter";

const css = bemBuilder("view-auth");

export default defineComponent({
  name: "ViewAuth",
  components: {
    MolIconButton,
    AuthRequestOtp,
    AuthValidateOtp,
  },
  props: {
    business: {
      type: Object as PropType<IFetchBusinessBySlugResponse>,
      required: true,
    },
    changeViewTo: {
      type: Function as PropType<
        (view: ViewsEnum, isLoginFlow: boolean) => void
      >,
      required: true,
    },
  },
  setup(props) {
    const currentComponent = ref<"request" | "validate">("request");

    const phoneNumberObj = ref<IPhoneNumberObject>();

    function requestOtp(phone: IPhoneNumberObject): void {
      phoneNumberObj.value = phone;
      currentComponent.value = "validate";
      tracking("Request OTP");
    }

    async function checkRedeemSignupPoints(
      id: string,
      token: string
    ): Promise<void> {
      try {
        await redeemSignupPoints(props.business.id, token);
      } catch (e) {
        console.warn(e, "Error occurred while receiving signup points");
      }
    }

    async function getUserData(accessToken: string): Promise<void> {
      try {
        const userDetails = await fetchUserInfo(accessToken);
        reportIdentify({ id: userDetails.id, email: userDetails.email });
      } catch (e) {
        report(e);
      }
    }

    async function validateOtp(accessToken: string): Promise<void> {
      const token = `Bearer ${accessToken}`;
      window.localStorage.setItem("auth._token.local", token);

      if (props.business) {
        await Promise.all([
          checkRedeemSignupPoints(props.business.id, token),
          getUserData(token),
        ]);
      }

      props.changeViewTo(ViewsEnum.BILL, true);
      tracking("Validate OTP");
    }

    function tracking(title: string): void {
      mixpanel.track(title, {
        business_id: props.business.id,
        business_name: props.business.name,
        event_category: "pay_at_table",
      });
    }

    function backPage(): void {
      if (currentComponent.value === "validate") {
        currentComponent.value = "request";
        return;
      }

      props.changeViewTo(ViewsEnum.BILL, false);
    }

    return {
      css,
      backPage,
      requestOtp,
      validateOtp,
      phoneNumberObj,
      currentComponent,
      AtomIconNameEnum,
    };
  },
});
