import { IRepoErrors } from "../errors";
import { http } from "../http";

type ILoyaltyProgramResponse = {
  expiration_days: number;
  maximum_redeemed_order_amount: number;
  maximum_redeemed_order_percentage: number;
  order_amount_percentage_worth: number;
  signup_points: number;
};

export type ILoyaltyProgram = {
  expirationDays: number;
  maximumRedeemedOrderAmount: number;
  maximumRedeemedOrderPercentage: number;
  orderAmountPercentageWorth: number;
  signupPoints: number;
};

const loyaltyProgramTransformer = (
  loyaltyProgram: ILoyaltyProgramResponse
): ILoyaltyProgram | null => {
  if (!loyaltyProgram?.expiration_days) return null;

  return {
    expirationDays: loyaltyProgram.expiration_days ?? null,
    maximumRedeemedOrderAmount:
      loyaltyProgram.maximum_redeemed_order_amount ?? null,
    maximumRedeemedOrderPercentage:
      loyaltyProgram.maximum_redeemed_order_percentage ?? null,
    orderAmountPercentageWorth:
      loyaltyProgram.order_amount_percentage_worth ?? null,
    signupPoints: loyaltyProgram.signup_points ?? null,
  };
};

export type IFetchBusinessBySlugResponse = {
  id: string;
  name: string;
  url: string;
  country: string;
  language: string;
  logo: string;
  cover: string;
  has_live_tracking: boolean;
  messaging_channel: string;
  category: string;
  colorset: ColorSet;
  loyaltyProgram: ILoyaltyProgram;
};

export type ColorSet = {
  primary: string;
  "on-primary": string;
  "primary-hover": string;
  "on-primary-hover": string;
  "primary-light": string;
  "primary-dark": string;
};

export class FetchBusinessBySlugError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchBusinessBySlug");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchBusinessBySlug(
  businessSlug: string
): Promise<IFetchBusinessBySlugResponse> {
  let response: IFetchBusinessBySlugResponse;

  try {
    const result = await http.get(
      `/api/v1/businesses/${businessSlug}?include=loyalty_program`
    );

    const { loyalty_program, ...rest } = result.data || {};
    response = {
      ...rest,
      loyaltyProgram: loyaltyProgramTransformer(loyalty_program),
    };
  } catch (e: any) {
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchBusinessBySlugError(e);
  }

  return response;
}
