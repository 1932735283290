import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "modal-fade" }, {
    default: _withCtx(() => [
      (_ctx.isVisible)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.css())
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.css('content'))
            }, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ], 2),
            _createElementVNode("span", {
              class: _normalizeClass([
          _ctx.css('backdrop'),
          {
            [_ctx.css('backdrop', 'transparent')]: _ctx.hideBackdrop,
          },
        ])
            }, null, 2)
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}