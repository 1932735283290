
import { defineComponent, defineAsyncComponent } from "vue";

export default defineComponent({
  name: "AtomGraphic",
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 32,
    },
  },
  computed: {
    graphicSize(): { width: string; height: string } {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
      };
    },
    graphic() {
      return defineAsyncComponent(() => import(`./assets/${this.name}.svg`));
    },
  },
});
