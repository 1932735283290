
import { bemBuilder } from "@chatfood/core-utils";
import { defineComponent } from "vue";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomTextTagEnum,
  MolLoading,
} from "@/design-system";

const css = bemBuilder("bill-waiting");

export default defineComponent({
  name: "BillWaiting",
  components: {
    AtomText,
    MolLoading,
  },
  setup() {
    return {
      css,
      AtomTextTypeEnum,
      AtomTextTagEnum,
      AtomTextColorEnum,
    };
  },
});
