
import { bemBuilder } from "@chatfood/core-utils";
import { defineComponent, PropType, ref, computed, onMounted } from "vue";
import { InputTypesEnum } from "@/enum";

const css = bemBuilder("atom-input");

export default defineComponent({
  name: "AtomInput",
  model: {
    prop: "modelValue",
    event: "input",
  },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    onInput: {
      type: Function as PropType<(value: string) => void>,
      required: true,
    },
    onBlur: {
      type: Function as PropType<() => void>,
      default: () => null,
    },
    onFocus: {
      type: Function as PropType<() => void>,
      default: () => null,
    },
    type: {
      type: String as PropType<InputTypesEnum>,
      default: "",
    },
    pattern: {
      type: String,
      default: "",
    },
  },
  setup(props, { slots }) {
    const focused = ref(false);

    const input = ref<HTMLInputElement>();

    onMounted(() => {
      if (props.autofocus && input.value) {
        input.value.focus();
      }
    });

    function setInput(event: InputEvent): void {
      props.onInput((event.target as HTMLInputElement).value);
    }

    function setBlur(): void {
      focused.value = false;
      props.onBlur();
    }

    function setFocus(): void {
      focused.value = true;
      props.onFocus();
    }

    const hasIcon = computed(() => slots.icon && Boolean(slots.icon()));

    const hasPrefix = computed(() => slots.prefix && Boolean(slots.prefix()));

    const hasSuffix = computed(() => slots.suffix && Boolean(slots.suffix()));

    return {
      css,
      setInput,
      setBlur,
      setFocus,
      focused,
      input,
      hasIcon,
      hasPrefix,
      hasSuffix,
    };
  },
});
