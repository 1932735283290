
import { bemBuilder } from "@chatfood/core-utils";
import { defineComponent, PropType, ref, computed, watch } from "vue";
import { AtomIconNameEnum } from "../icon";
import { MolIconButton } from "../../mol/icon-button";
import { lockBody, unlockBody } from "@/design-system/composable/body-locker";

const css = bemBuilder("atom-drawer");

export default defineComponent({
  name: "AtomDrawer",
  components: {
    MolIconButton,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    hideBackground: {
      type: Boolean,
      default: false,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    maxHeight: {
      type: Number,
      default: 0,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    lockScroll: {
      type: Boolean,
      default: true,
    },
    noScroll: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function as PropType<() => void>,
      default: () => null,
    },
  },
  setup(props, { slots }) {
    const isDrawerVisible = ref(false);
    const isContentVisible = ref(false);

    watch(
      () => props.modelValue,
      (modelValue) => {
        modelValue ? displayDrawer(true) : displayContent(false);

        if (props.lockScroll) {
          modelValue ? lockBody() : unlockBody();
        }
      }
    );

    function close(): void {
      displayDrawer(false);
      props.onClose();
    }

    function displayContent(status: boolean): void {
      isContentVisible.value = status;
    }

    function displayDrawer(status: boolean): void {
      isDrawerVisible.value = status;
    }

    const shouldBeRounded = computed(() => !props.full && props.rounded);

    const containerStyles = computed(() =>
      props.maxHeight === 0 ? {} : { "max-height": `${props.maxHeight}%` }
    );

    const hasHeaderSlot = computed(() => Boolean(slots?.header));

    return {
      css,
      close,
      hasHeaderSlot,
      containerStyles,
      displayContent,
      isDrawerVisible,
      isContentVisible,
      shouldBeRounded,
      AtomIconNameEnum,
    };
  },
});
