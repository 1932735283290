import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomText = _resolveComponent("AtomText")!
  const _component_AtomIcon = _resolveComponent("AtomIcon")!
  const _component_AtomPinCode = _resolveComponent("AtomPinCode")!
  const _component_AtomFormControl = _resolveComponent("AtomFormControl")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.css())
  }, [
    _createVNode(_component_AtomText, {
      type: _ctx.AtomTextTypeEnum.H2,
      color: _ctx.AtomTextColorEnum.CHARCOAL
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Verify your phone ")
      ]),
      _: 1
    }, 8, ["type", "color"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.css('description'))
    }, [
      _createVNode(_component_AtomIcon, {
        name: "sms",
        size: 28,
        class: _normalizeClass(_ctx.css('description-icon'))
      }, null, 8, ["class"]),
      _createVNode(_component_AtomText, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.description), 1)
        ]),
        _: 1
      })
    ], 2),
    _createVNode(_component_AtomFormControl, { error: _ctx.error }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.css('otp'))
        }, [
          (_ctx.displayOtp)
            ? (_openBlock(), _createBlock(_component_AtomPinCode, {
                key: 0,
                fields: _ctx.otpMaxLength,
                invalid: Boolean(_ctx.error),
                disabled: _ctx.isDisabled,
                "on-complete": _ctx.validateOtp
              }, null, 8, ["fields", "invalid", "disabled", "on-complete"]))
            : _createCommentVNode("", true)
        ], 2)
      ]),
      _: 1
    }, 8, ["error"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.css('resend'))
    }, [
      (_ctx.displayResendCode)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            href: "#",
            class: _normalizeClass(_ctx.css('resend-link')),
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.requestOtp && _ctx.requestOtp(...args)), ["prevent"]))
          }, " I haven't received the code ", 2))
        : (_openBlock(), _createBlock(_component_AtomText, { key: 1 }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.counterMessage), 1)
            ]),
            _: 1
          }))
    ], 2)
  ], 2))
}