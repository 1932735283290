
import { AtomMoney } from "@chatfood/design-system";
import { bemBuilder } from "@chatfood/core-utils";
import { defineComponent, ref, PropType, computed } from "vue";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextTagEnum,
  AtomTextColorEnum,
  AtomInput,
  AtomFormControl,
  AtomButton,
  AtomButtonSizeEnum,
  AtomButtonTypeEnum,
  MolLoadingWithLogo,
} from "@/design-system";
import { OrgFormMoneyInput } from "../form-money-input";
import { CurrencyCodeEnum } from "@/enum";

const css = bemBuilder("split-bill-form");

export default defineComponent({
  name: "OrgSplitBillForm",
  components: {
    AtomText,
    AtomMoney,
    AtomInput,
    AtomButton,
    AtomFormControl,
    MolLoadingWithLogo,
    OrgFormMoneyInput,
  },
  props: {
    name: {
      type: String,
      default: "",
    },
    shareAmount: {
      type: Number,
      default: null,
    },
    billTotal: {
      type: Number,
      required: true,
    },
    billRemaining: {
      type: Number,
      required: true,
    },
    currency: {
      type: String as PropType<CurrencyCodeEnum>,
      required: true,
    },
    locale: {
      type: String,
      default: "",
    },
    onCancel: {
      type: Function as PropType<() => void>,
      default: () => null,
    },
    onSave: {
      type: Function as PropType<(amount: number, name: string) => void>,
      default: () => null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const userName = ref(props.name);
    const userShareAmount = ref(props.shareAmount);
    const isShareAmountInvalid = ref(false);
    const shareAmountError = ref("");

    const showRemaining = computed(
      () => props.billRemaining > 0 && props.billTotal > props.billRemaining
    );

    function createDebounce(): any {
      let timeout: any = null;
      const defaultDelay = 500;
      return function (fnc: any, delayMs: number) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fnc();
        }, delayMs || defaultDelay);
      };
    }

    function showError(message: string): void {
      isShareAmountInvalid.value = true;
      shareAmountError.value = message;
    }

    function validateAmount(value: number): void {
      userShareAmount.value = value;
      const valueToBePaid = userShareAmount.value;

      isShareAmountInvalid.value = false;
      shareAmountError.value = "";

      if (valueToBePaid === 0) {
        showError("Your share should not be 0");
      }

      if (
        (props.billRemaining > 0 && valueToBePaid > props.billRemaining) ||
        (props.billRemaining === 0 && valueToBePaid > props.billTotal)
      ) {
        showError("Your share should not exceed the remaining total");
      }
    }

    function confirmShare(): void {
      props.onSave(userShareAmount.value, userName.value);
    }

    return {
      css,
      userName,
      userShareAmount,
      isShareAmountInvalid,
      shareAmountError,
      showRemaining,
      validateAmount,
      confirmShare,
      debounce: createDebounce(),
      AtomTextTagEnum,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonSizeEnum,
      AtomButtonTypeEnum,
    };
  },
});
