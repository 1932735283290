import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomText = _resolveComponent("AtomText")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      _ctx.css(),
      {
        [_ctx.css('', 'error')]: _ctx.error,
      },
    ])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(_ctx.css('label'))
        }, [
          _createVNode(_component_AtomText, {
            type: _ctx.AtomTextTypeEnum.BODY_BOLD,
            color: _ctx.AtomTextColorEnum.BASE_20
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.label), 1)
            ]),
            _: 1
          }, 8, ["type", "color"]),
          (_ctx.isOptional)
            ? (_openBlock(), _createBlock(_component_AtomText, {
                key: 0,
                type: _ctx.AtomTextTypeEnum.SMALL,
                color: _ctx.AtomTextColorEnum.BASE_50
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" (optional) ")
                ]),
                _: 1
              }, 8, ["type", "color"]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    (_ctx.error)
      ? (_openBlock(), _createBlock(_component_AtomText, {
          key: 1,
          class: _normalizeClass(_ctx.css('error-message')),
          color: _ctx.AtomTextColorEnum.CORAL
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.error), 1)
          ]),
          _: 1
        }, 8, ["class", "color"]))
      : _createCommentVNode("", true)
  ], 2))
}