
import { AtomMoney } from "@chatfood/design-system";
import { bemBuilder } from "@chatfood/core-utils";
import { defineComponent, PropType } from "vue";
import { AtomText, AtomTextTypeEnum, AtomTextColorEnum } from "../../atom/text";
import { AtomIcon, AtomIconNameEnum } from "../../atom/icon";
import { CurrencyCodeEnum } from "@/enum/currency-code.enum";

const css = bemBuilder("split-button");

export default defineComponent({
  name: "MolSplitButton",
  components: {
    AtomText,
    AtomIcon,
    AtomMoney,
  },
  props: {
    amount: {
      type: Number,
      default: 0,
    },
    currency: {
      type: String as PropType<CurrencyCodeEnum>,
      required: true,
    },
    locale: {
      type: String,
      default: "en-US",
    },
    onClick: {
      type: Function as PropType<() => void>,
      default: () => null,
    },
  },
  setup() {
    return {
      css,
      AtomTextColorEnum,
      AtomTextTypeEnum,
      AtomIconNameEnum,
    };
  },
});
