import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b7c4fc26"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MolLoadingWithLogo = _resolveComponent("MolLoadingWithLogo")!
  const _component_AtomLogo = _resolveComponent("AtomLogo")!
  const _component_MolSplitButton = _resolveComponent("MolSplitButton")!
  const _component_OrgBillSummary = _resolveComponent("OrgBillSummary")!
  const _component_BillLoyaltyProgram = _resolveComponent("BillLoyaltyProgram")!
  const _component_OrgTipPicker = _resolveComponent("OrgTipPicker")!
  const _component_BillWaiting = _resolveComponent("BillWaiting")!
  const _component_AtomText = _resolveComponent("AtomText")!
  const _component_BillCheckoutForm = _resolveComponent("BillCheckoutForm")!
  const _component_MolPaymentBox = _resolveComponent("MolPaymentBox")!
  const _component_ApplePayButton = _resolveComponent("ApplePayButton")!
  const _component_AtomButton = _resolveComponent("AtomButton")!
  const _component_OrgPaymentTab = _resolveComponent("OrgPaymentTab")!
  const _component_OrgPaymentMethod = _resolveComponent("OrgPaymentMethod")!
  const _component_AtomDrawer = _resolveComponent("AtomDrawer")!
  const _component_OrgSplitBillForm = _resolveComponent("OrgSplitBillForm")!
  const _component_OrgNotification = _resolveComponent("OrgNotification")!
  const _component_AtomModal = _resolveComponent("AtomModal")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.css(), { [_ctx.css('', 'blink')]: _ctx.billBlink }])
  }, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_MolLoadingWithLogo, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.css('inner'))
    }, [
      (!_ctx.isLoading && _ctx.table.bill)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.business)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(_ctx.css('logo'))
                }, [
                  _createVNode(_component_AtomLogo, {
                    imageLink: _ctx.business.logo
                  }, null, 8, ["imageLink"])
                ], 2))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.css('summary'))
            }, [
              _createVNode(_component_OrgBillSummary, {
                tableName: _ctx.table.tableName,
                countryCode: _ctx.business.country,
                billItems: _ctx.table.bill.items,
                billTotal: _ctx.billTotal,
                billCurrency: _ctx.currencyCode,
                billRemaining: _ctx.billRemaining,
                billPaymentsList: _ctx.billPaymentsList,
                isOrderSettled: _ctx.isOrderSettled,
                lastUpdated: _ctx.lastUpdated,
                showReloadBillAction: _ctx.isPosEnabled,
                onReloadBill: _ctx.onReloadBill
              }, {
                default: _withCtx(() => [
                  (!_ctx.isOrderSettled)
                    ? (_openBlock(), _createBlock(_component_MolSplitButton, {
                        key: 0,
                        class: _normalizeClass(_ctx.css('split-button')),
                        amount: _ctx.shareAmount,
                        currency: _ctx.currencyCode,
                        locale: _ctx.business.country,
                        onClick: _ctx.setSplitBill
                      }, null, 8, ["class", "amount", "currency", "locale", "onClick"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["tableName", "countryCode", "billItems", "billTotal", "billCurrency", "billRemaining", "billPaymentsList", "isOrderSettled", "lastUpdated", "showReloadBillAction", "onReloadBill"])
            ], 2),
            (_ctx.showLoyaltyProgramBlock)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_BillLoyaltyProgram, {
                    "total-order": _ctx.totalOrderAmount,
                    "is-logged-in": _ctx.isLoggedIn,
                    "color-set": _ctx.business.colorset,
                    currency: _ctx.currencyCode,
                    locale: _ctx.business.country,
                    "loyalty-points": _ctx.loyaltyPoints,
                    "loyalty-program": _ctx.business.loyaltyProgram,
                    "use-loyalty-program": _ctx.useLoyaltyProgram,
                    onRedirect: _ctx.redirectToAuth,
                    onSetState: _ctx.setLoyaltyProgramState
                  }, null, 8, ["total-order", "is-logged-in", "color-set", "currency", "locale", "loyalty-points", "loyalty-program", "use-loyalty-program", "onRedirect", "onSetState"])
                ]))
              : _createCommentVNode("", true),
            (!_ctx.isOrderSettled)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: _normalizeClass(_ctx.css('summary')),
                  ref: "tipsRef"
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.css('tips'))
                  }, [
                    _createVNode(_component_OrgTipPicker, {
                      title: _ctx.t('view.bill.tips.title'),
                      subtitle: _ctx.t('view.bill.tips.subtitle'),
                      class: _normalizeClass({ [_ctx.css('', 'shake')]: _ctx.tipShaker }),
                      noTipButtonLabel: _ctx.t('view.bill.tips.no_tip'),
                      selectedTipPercentage: _ctx.tipsPercentage,
                      tipPercentages: [0, 5, 10, 15],
                      billAmount: {
                value: _ctx.shareAmount > 0 ? _ctx.shareAmount : _ctx.billRemaining,
                currency: _ctx.currencyCode,
              },
                      hasError: _ctx.hasError,
                      onChange: _ctx.selectTip
                    }, null, 8, ["title", "subtitle", "class", "noTipButtonLabel", "selectedTipPercentage", "billAmount", "hasError", "onChange"])
                  ], 2)
                ], 2))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true)
    ], 2),
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.css('payment'))
        }, [
          (_ctx.contactingBank)
            ? (_openBlock(), _createBlock(_component_BillWaiting, { key: 0 }))
            : (_ctx.table.bill && !_ctx.isOrderSettled)
              ? (_openBlock(), _createBlock(_component_OrgPaymentTab, {
                  key: 1,
                  countryCode: _ctx.business.country,
                  billCurrency: _ctx.currencyCode,
                  isSplitBill: _ctx.isSplitBill,
                  billTotal: _ctx.totalPayableWithTips,
                  showTitle: Boolean(_ctx.selectedCard || _ctx.showCheckoutForm),
                  showBackButton: _ctx.displayBackButtonPayment,
                  onBack: () => _ctx.handleCheckoutForm(false)
                }, _createSlots({
                  paymentButton: _withCtx(() => [
                    (_ctx.isLoggedIn)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          (
                _ctx.selectedCard &&
                _ctx.selectedCard.method === _ctx.PaymentMethodsEnum.APPLE_PAY
              )
                            ? (_openBlock(), _createBlock(_component_ApplePayButton, {
                                key: 0,
                                "business-id": _ctx.business.id,
                                "business-name": _ctx.business.name,
                                "on-validation": _ctx.checkIsTipPicked,
                                "country-code": _ctx.business.country,
                                "currency-code": _ctx.currencyCode,
                                total: _ctx.totalPayableWithTips,
                                "capture-phone-number": false,
                                class: _normalizeClass(_ctx.css('payment-button')),
                                onOnSuccess: _ctx.payWithApplePay
                              }, null, 8, ["business-id", "business-name", "on-validation", "country-code", "currency-code", "total", "class", "onOnSuccess"]))
                            : (_openBlock(), _createBlock(_component_AtomButton, {
                                key: 1,
                                onClick: _ctx.payWithCard,
                                class: _normalizeClass(_ctx.css('payment-button')),
                                size: _ctx.AtomButtonSizeEnum.LARGE,
                                isDisabled: _ctx.disablePaymentButton
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.buttonLabel), 1)
                                ]),
                                _: 1
                              }, 8, ["onClick", "class", "size", "isDisabled"]))
                        ], 64))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createVNode(_component_AtomButton, {
                            class: _normalizeClass(_ctx.css('payment-button')),
                            type: 
                _ctx.hasApplePay
                  ? _ctx.AtomButtonTypeEnum.SECONDARY
                  : _ctx.AtomButtonTypeEnum.PRIMARY
              ,
                            size: _ctx.AtomButtonSizeEnum.LARGE,
                            onClick: _ctx.payWithCard,
                            isDisabled: _ctx.disablePaymentButton
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.buttonLabelApplePay), 1)
                            ]),
                            _: 1
                          }, 8, ["class", "type", "size", "onClick", "isDisabled"]),
                          (_ctx.hasApplePay)
                            ? (_openBlock(), _createBlock(_component_ApplePayButton, {
                                key: 0,
                                class: _normalizeClass(_ctx.css('payment-button')),
                                "business-id": _ctx.business.id,
                                "business-name": _ctx.business.name,
                                "on-validation": _ctx.checkIsTipPicked,
                                "country-code": _ctx.business.country,
                                "currency-code": _ctx.currencyCode,
                                total: _ctx.totalPayableWithTips,
                                "capture-phone-number": true,
                                onOnSuccess: _ctx.payWithApplePay
                              }, null, 8, ["class", "business-id", "business-name", "on-validation", "country-code", "currency-code", "total", "onOnSuccess"]))
                            : _createCommentVNode("", true)
                        ], 64))
                  ]),
                  _: 2
                }, [
                  (_ctx.tipChosen)
                    ? {
                        name: "paymentInfo",
                        fn: _withCtx(() => [
                          _createVNode(_component_AtomText, {
                            color: _ctx.AtomTextColorEnum.BASE_50,
                            tag: _ctx.AtomTextTagEnum.P
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.paymentInfoSubtitle), 1)
                            ]),
                            _: 1
                          }, 8, ["color", "tag"])
                        ]),
                        key: "0"
                      }
                    : undefined,
                  (_ctx.selectedCard || _ctx.showCheckoutForm)
                    ? {
                        name: "paymentMethod",
                        fn: _withCtx(() => [
                          _createElementVNode("div", {
                            class: _normalizeClass([
              _ctx.css('payment-box'),
              _ctx.css(
                'payment-box',
                _ctx.showCheckoutForm ? 'checkout' : 'selected-card'
              ),
            ])
                          }, [
                            (_ctx.showCheckoutForm)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                  _createVNode(_component_BillCheckoutForm, {
                                    checkoutToken: _ctx.checkoutToken,
                                    selectedCard: _ctx.selectNewCard
                                  }, null, 8, ["checkoutToken", "selectedCard"])
                                ]))
                              : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                  (_ctx.selectedCard)
                                    ? (_openBlock(), _createBlock(_component_MolPaymentBox, {
                                        key: _ctx.selectedCard.id,
                                        paymentMethod: _ctx.selectedCard.method,
                                        paymentImageSize: 27,
                                        paymentLabel: _ctx.selectedCard.label,
                                        last4: _ctx.selectedCard.last4,
                                        onClick: 
                  () => (!_ctx.hasSkipcash ? (_ctx.showPaymentMethod = true) : {})
                ,
                                        showChange: !_ctx.hasSkipcash,
                                        showCheckbox: _ctx.hasSkipcash
                                      }, null, 8, ["paymentMethod", "paymentLabel", "last4", "onClick", "showChange", "showCheckbox"]))
                                    : _createCommentVNode("", true)
                                ]))
                          ], 2)
                        ]),
                        key: "1"
                      }
                    : undefined
                ]), 1032, ["countryCode", "billCurrency", "isSplitBill", "billTotal", "showTitle", "showBackButton", "onBack"]))
              : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    _createVNode(_component_AtomDrawer, {
      hideBackground: "",
      lockScroll: false,
      showCloseButton: false,
      label: "payment-method",
      modelValue: _ctx.showPaymentMethod,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showPaymentMethod) = $event)),
      "on-close": () => (_ctx.showPaymentMethod = false)
    }, {
      default: _withCtx(() => [
        _createVNode(_component_OrgPaymentMethod, {
          paymentMethodList: _ctx.paymentMethods,
          selectedPaymentId: _ctx.selectedCard.id,
          onChangeMethod: _ctx.onSelectedCard,
          onBack: () => (_ctx.showPaymentMethod = false),
          addCard: _ctx.openCheckoutForm
        }, null, 8, ["paymentMethodList", "selectedPaymentId", "onChangeMethod", "onBack", "addCard"])
      ]),
      _: 1
    }, 8, ["modelValue", "on-close"]),
    _createVNode(_component_AtomDrawer, {
      hideBackground: "",
      lockScroll: false,
      showCloseButton: false,
      label: "split-bill-form",
      modelValue: _ctx.showSplitBillForm,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showSplitBillForm) = $event)),
      "on-close": () => (_ctx.showSplitBillForm = false)
    }, {
      default: _withCtx(() => [
        _createVNode(_component_OrgSplitBillForm, {
          name: _ctx.shareName,
          shareAmount: _ctx.shareAmount,
          billTotal: _ctx.billTotal,
          billRemaining: _ctx.billRemaining,
          currency: _ctx.currencyCode,
          locale: _ctx.business.country,
          onCancel: () => (_ctx.showSplitBillForm = false),
          "is-loading": _ctx.hasStartedSync,
          onSave: (amount, name) => _ctx.selectedSplitBill(amount, name, true)
        }, null, 8, ["name", "shareAmount", "billTotal", "billRemaining", "currency", "locale", "onCancel", "is-loading", "onSave"])
      ]),
      _: 1
    }, 8, ["modelValue", "on-close"]),
    _createVNode(_component_AtomModal, { isVisible: _ctx.isNotificationVisible }, {
      default: _withCtx(() => [
        _createVNode(_component_OrgNotification, {
          iconName: _ctx.notificationIcon,
          title: _ctx.notificationTitle,
          description: _ctx.notificationDescription,
          buttonLabel: "Okay",
          onClick: _ctx.closeNotification
        }, null, 8, ["iconName", "title", "description", "onClick"])
      ]),
      _: 1
    }, 8, ["isVisible"])
  ], 2))
}