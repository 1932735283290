import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MolLoading = _resolveComponent("MolLoading")!
  const _component_AtomText = _resolveComponent("AtomText")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.css())
  }, [
    _createVNode(_component_MolLoading, {
      class: _normalizeClass(_ctx.css('loading')),
      size: 50,
      inline: ""
    }, null, 8, ["class"]),
    _createVNode(_component_AtomText, {
      class: _normalizeClass(_ctx.css('heading')),
      type: _ctx.AtomTextTypeEnum.H3
    }, {
      default: _withCtx(() => [
        _createTextVNode(" We’re contacting the bank ")
      ]),
      _: 1
    }, 8, ["class", "type"]),
    _createVNode(_component_AtomText, {
      class: _normalizeClass(_ctx.css('desc')),
      type: _ctx.AtomTextTypeEnum.BODY
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Please hold for a moment ")
      ]),
      _: 1
    }, 8, ["class", "type"])
  ], 2))
}