import { http } from "@/repo/http";
import { IRepoErrors } from "@/repo/errors";

export class FetchLoyaltyPointsError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchLoyaltyPoints");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

type IFetchLoyaltyPointsRequest = {
  businessId: string;
  accessToken: string;
};

export async function fetchLoyaltyPoints({
  businessId,
  accessToken,
}: IFetchLoyaltyPointsRequest): Promise<number> {
  let response: number;
  try {
    const result = await http.get(`/api/v1/customers/rewardPoints`, {
      headers: {
        Authorization: accessToken,
      },
      params: {
        business: businessId,
      },
    });

    response = result.data?.balance ?? 0;
  } catch (e: any) {
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchLoyaltyPointsError(e);
  }

  return response;
}
