
import { bemBuilder } from "@chatfood/core-utils";
import {
  defineComponent,
  ref,
  watch,
  computed,
  PropType,
  onUnmounted,
  onMounted,
} from "vue";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  MolPoweredByChatfood,
  AtomGraphic,
  OrgPaymentDetails,
} from "@/design-system";
import {
  fetchBillByTableId,
  IFetchBillByTableIdResponse,
} from "@/repo/fetch-bill-by-table-id";
import {
  fetchPaymentsList,
  IFetchPaymentsListResponse,
} from "@/repo/fetch-payments-list";
import { IFetchBusinessBySlugResponse } from "@/repo/fetch-business-by-slug";
import { useRouter } from "vue-router";
import { ViewsEnum } from "@/view/views.enum";
import { getStorageItem, removeStorageItem } from "@/util/local-storage";
import {
  getStorageLoyaltyKey,
  LoyaltyProgramInfo,
} from "@/util/loyalty-points";
import { PaymentSuccessLoyaltyProgram } from "./loyalty-program";
import { PaymentSuccessRatingFeedback } from "./feedback";
import { report } from "@chatfood/bug-reporter";
import { TipsEnum } from "@/enum";
import { fetchUserInfo } from "@/repo/auth/fetch-user-info";

const css = bemBuilder("view-payment-success");

export default defineComponent({
  name: "ViewPaymentSuccess",
  components: {
    OrgPaymentDetails,
    AtomText,
    AtomGraphic,
    MolPoweredByChatfood,
    PaymentSuccessLoyaltyProgram,
    PaymentSuccessRatingFeedback,
  },
  props: {
    tableId: {
      type: String,
      required: true,
    },
    businessName: {
      type: String,
      required: false,
    },
    business: {
      type: Object as PropType<IFetchBusinessBySlugResponse>,
      required: true,
    },
    changeViewTo: {
      type: Function as PropType<(view: ViewsEnum) => void>,
      required: true,
    },
  },
  setup(props) {
    const accessToken = window.localStorage.getItem("auth._token.local") || "";
    const table = ref<IFetchBillByTableIdResponse | Record<string, never>>({});
    const billPaymentsList = ref<IFetchPaymentsListResponse>([]);
    const router = useRouter();
    const billBlink = ref(false);
    const amountPaidValue = ref(0);
    const loyaltyProgram = ref<LoyaltyProgramInfo>();
    const userInfo = ref();

    const savedPaymentSuccess = computed(() =>
      getStorageItem(`payment-success-${table.value.bill?.id}`)
    );

    const currencyCode = computed(
      () => table.value.bill?.remainingBalance?.currencyCode
    );

    const tipsPercentage = computed(
      () => savedPaymentSuccess.value?.tipPercentage || TipsEnum.NONE
    );

    const tipsAmount = computed(
      () => savedPaymentSuccess.value?.tipAmount || TipsEnum.NONE
    );

    const isSplitBill = computed(
      () => savedPaymentSuccess.value?.isSplitBill || false
    );

    const customerName = computed(
      () => userInfo.value?.name || savedPaymentSuccess.value?.customerName
    );

    const customerPhoneNumber = computed(() => {
      if (savedPaymentSuccess.value?.phoneNumber)
        return savedPaymentSuccess.value.phoneNumber;

      return userInfo.value
        ? `+${userInfo.value.countryCode}${userInfo.value.phoneNumber}`
        : "";
    });

    const emojiName = computed(() =>
      isOrderSettled.value ? "thumbs-up" : "love-face"
    );

    const title = computed(() =>
      isOrderSettled.value ? "The bill is settled" : "You paid your part"
    );

    const subtitle = computed(() =>
      isOrderSettled.value
        ? "Your payment was completed. You're good to go. Hope to see you soon."
        : "Please before you go make sure that the bill was settled. Hope to see you soon."
    );

    const amountPaid = computed(
      () => savedPaymentSuccess.value?.amountPaid || 0
    );

    const billTotal = computed(() => {
      if (
        !table.value.bill?.remainingBalance?.currencyValue &&
        !table.value.bill?.amountPaid?.currencyValue
      ) {
        return 0;
      }

      return (
        table.value.bill?.remainingBalance?.currencyValue +
        table.value.bill?.amountPaid?.currencyValue
      );
    });

    const billRemaining = computed(() => {
      if (!table.value.bill?.remainingBalance?.currencyValue) {
        return 0;
      }
      return table.value.bill.remainingBalance.currencyValue;
    });

    const isOrderSettled = computed(() => billRemaining.value === 0);
    const showLoyaltyProgram = computed(() => loyaltyProgram.value);
    const businessColor = computed(() => props.business?.colorset?.primary);

    const locationId = computed(() => table.value.location.id);
    const billId = computed(() => table.value.bill?.id);

    watch(
      () => props.business,
      async () => {
        if (props.business?.id) {
          await getBillForTable(true);
        }
      },
      {
        immediate: true,
      }
    );

    const getUserInfo = async (): Promise<void> => {
      if (!accessToken) return;

      try {
        userInfo.value = await fetchUserInfo(accessToken);
      } catch (e) {
        report(e);
      }
    };

    onMounted(() => {
      getUserInfo();
    });

    onUnmounted(() => {
      removeStorageItem(`payment-success-${table.value.bill?.id}`);
    });

    const getLoyaltyProgramInfo = (id: string): void => {
      const key = getStorageLoyaltyKey(id);
      loyaltyProgram.value = getStorageItem(key) as LoyaltyProgramInfo;
    };

    async function getBillForTable(firstLoad = false): Promise<void> {
      const oldAmountPaidValue = JSON.parse(
        JSON.stringify(amountPaidValue.value)
      );

      try {
        table.value = await fetchBillByTableId(props.tableId);
      } catch (e: any) {
        report(e);
      } finally {
        if (table.value?.bill) {
          if (table.value?.bill?.amountPaid?.currencyValue) {
            amountPaidValue.value = table.value.bill.amountPaid.currencyValue;
          }

          if (firstLoad) {
            await getPaymentList(table.value.bill.id);
          }

          if (!firstLoad && oldAmountPaidValue !== amountPaidValue.value) {
            await getPaymentList(table.value.bill.id);
            blinkBill();
          }

          if (table.value.bill.id) {
            getLoyaltyProgramInfo(table.value.bill.id);
          }
        }

        if (!isOrderSettled.value) {
          // eslint-disable-next-line no-magic-numbers
          setTimeout(() => getBillForTable(), 2000);
        }
      }
    }

    function blinkBill(): void {
      billBlink.value = false;
      setTimeout(() => (billBlink.value = true), 100);
    }

    async function getPaymentList(billId: string): Promise<void> {
      try {
        const res = await fetchPaymentsList(billId, props.business?.id);

        if (res.length) {
          billPaymentsList.value = res;
        }
      } catch (e) {
        report(e);
      }
    }

    function goBackToBill(): void {
      router.replace({
        name: "PAT",
        params: {
          businessId: props.business.id,
          tableId: props.tableId,
        },
      });

      props.changeViewTo(ViewsEnum.BILL);
    }

    return {
      css,
      table,
      emojiName,
      title,
      subtitle,
      billPaymentsList,
      currencyCode,
      billTotal,
      billRemaining,
      amountPaid,
      billBlink,
      showLoyaltyProgram,
      loyaltyProgram,
      tipsPercentage,
      tipsAmount,
      isSplitBill,
      businessColor,
      locationId,
      billId,
      customerName,
      customerPhoneNumber,
      goBackToBill,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
