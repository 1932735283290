
import { bemBuilder } from "@chatfood/core-utils";
import {
  defineComponent,
  PropType,
  Ref,
  ref,
  watch,
  onMounted,
  onBeforeUpdate,
} from "vue";

const css = bemBuilder("atom-pin-code");

export default defineComponent({
  name: "AtomPinCode",
  props: {
    fields: {
      type: Number,
      default: 5,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    onChange: {
      type: Function as PropType<(value: string) => void>,
      default: () => null,
    },
    onComplete: {
      type: Function as PropType<(value: string) => void>,
      required: true,
    },
  },
  setup(props) {
    const pinCode = ref([""]);

    function refreshInputs(value: string): void {
      if (value.length === 0) {
        pinCode.value = Array(props.fields).fill("");
        return;
      }

      const pin = [];
      for (let i = 0; i < props.fields; i++) {
        pin.push(value[i] || "");
      }

      pinCode.value = pin;
    }

    refreshInputs("");

    const pinCodeFields = ref([]) as Ref<Array<HTMLInputElement>>;

    onMounted(() => {
      pinCodeFields.value[0]?.focus();
    });

    function emitUpdates(): void {
      const newPinCode = pinCode.value.join("");
      props.onChange(newPinCode);

      if (newPinCode.length >= props.fields) {
        removeFocus();
        props.onComplete(newPinCode);
      }
    }

    function removeFocus(): void {
      const activeElement = document.activeElement;
      pinCodeFields.value.forEach((field) => {
        if (field === activeElement) {
          field.blur();
        }
      });
    }

    watch(
      () => props.fields,
      () => refreshInputs("")
    );

    onBeforeUpdate(() => {
      pinCodeFields.value = [];
    });

    function onFocus(index: number): void {
      pinCodeFields.value[index].select();
    }

    function useElements(index: number): any {
      const el = pinCodeFields.value[index];
      const prevEl = pinCodeFields.value?.[
        index - 1
      ] as HTMLInputElement | null;
      const nextEl = pinCodeFields.value?.[
        index + 1
      ] as HTMLInputElement | null;

      return {
        el,
        prevEl,
        nextEl,
      };
    }

    function onBackspace(index: number): void {
      const { prevEl } = useElements(index);

      const isInputEmpty = !pinCode.value[index];
      pinCode.value[index] = "";

      if (isInputEmpty) {
        prevEl?.focus();
      }

      pinCode.value = [...pinCode.value];
      emitUpdates();
    }

    function onArrowRight(index: number): void {
      const { nextEl } = useElements(index);
      nextEl?.focus();
    }

    function onArrowLeft(index: number): void {
      const { prevEl } = useElements(index);
      prevEl?.focus();
    }

    function onInput(index: number): void {
      const { el } = useElements(index);

      el.value = el.value.replace(/[^\d+]/gi, "");

      if (el.value === "" || !el.validity.valid) {
        return;
      }

      const inputValue = el.value;
      pinCode.value[index] = inputValue.charAt(0);

      const rest = inputValue.substring(1);
      let nextIndex = index + 1;

      const maxIndex = props.fields - 1;

      if (rest.length) {
        rest.split("").forEach((input: string) => {
          if (nextIndex <= props.fields && nextIndex <= maxIndex) {
            pinCode.value[nextIndex] = input;
            nextIndex++;
          }
        });
      }

      pinCode.value = [...pinCode.value];

      const isLastCode = nextIndex >= maxIndex;
      nextIndex = isLastCode ? maxIndex : nextIndex;
      const nextEl = pinCodeFields.value[nextIndex];

      nextEl?.focus();
      if (isLastCode) {
        nextEl?.setSelectionRange(0, 0, "backward");
      }

      emitUpdates();
    }

    // to avoid any browser reaction
    const killEvent = (e: Event): void => {
      e.preventDefault();
    };

    return {
      css,
      pinCodeFields,
      pinCode,
      onFocus,
      onInput,
      onBackspace,
      onArrowRight,
      onArrowLeft,
      killEvent,
    };
  },
});
