
import { bemBuilder } from "@chatfood/core-utils";
import { computed, defineComponent, PropType, ref, watch } from "vue";
import { AtomTextColorEnum } from "./color.enum";
import { AtomTextTypeEnum } from "./type.enum";
import { AtomTextTagEnum } from "./tag.enum";

const css = bemBuilder("atom-text");

export default defineComponent({
  name: "AtomText",
  props: {
    tag: {
      type: String as PropType<AtomTextTagEnum>,
      default: AtomTextTagEnum.AUTO,
    },
    type: {
      type: String as PropType<AtomTextTypeEnum>,
      default: AtomTextTypeEnum.BODY,
    },
    color: {
      type: String as PropType<AtomTextColorEnum | undefined>,
      default: undefined,
    },
  },
  setup(props) {
    const htmlTag = ref<string>("");

    const styles = computed(() => {
      if (props.color) {
        return {
          color: `var(--color-${props.color})`,
        };
      }

      return {};
    });

    watch(
      [() => props.type, () => props.tag],
      () => {
        if (props.tag !== AtomTextTagEnum.AUTO) {
          htmlTag.value = props.tag;
          return;
        }

        const typeTagMap = {
          [AtomTextTypeEnum.H1]: AtomTextTagEnum.H1,
          [AtomTextTypeEnum.H2]: AtomTextTagEnum.H2,
          [AtomTextTypeEnum.H3]: AtomTextTagEnum.H3,
          [AtomTextTypeEnum.SUBTITLE]: AtomTextTagEnum.H4,
          [AtomTextTypeEnum.BODY]: AtomTextTagEnum.SPAN,
          [AtomTextTypeEnum.BODY_BOLD]: AtomTextTagEnum.SPAN,
          [AtomTextTypeEnum.P]: AtomTextTagEnum.P,
          [AtomTextTypeEnum.SMALL]: AtomTextTagEnum.SPAN,
          [AtomTextTypeEnum.SMALL_BOLD]: AtomTextTagEnum.SPAN,
          [AtomTextTypeEnum.LINK]: AtomTextTagEnum.SPAN,
          [AtomTextTypeEnum.LINK_BOLD]: AtomTextTagEnum.SPAN,
          [AtomTextTypeEnum.LINK_SMALL]: AtomTextTagEnum.SPAN,
        };

        htmlTag.value = typeTagMap[props.type];
      },
      { immediate: true }
    );

    return {
      styles,
      htmlTag,
      css,
    };
  },
});
