import { AxiosResponse } from "axios";
import { IRepoErrors } from "../errors";
import { http } from "../http";

type IFetchUserCreditCardsBody = {
  businessId: string;
  accessToken: string;
};

export type IFetchUserCreditCardsResponse = Array<{
  id: string;
  last4: string;
  cardBrand: string;
}>;

export class FetchUserCreditCardsError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchUserCreditCards");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchUserCreditCards(
  request: IFetchUserCreditCardsBody
): Promise<IFetchUserCreditCardsResponse> {
  let response: AxiosResponse;

  try {
    response = await http.get(
      `/api/v1/customers/cards?business_id=${request.businessId}`,
      {
        headers: {
          Authorization: request.accessToken,
        },
      }
    );
  } catch (e: any) {
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchUserCreditCardsError(e);
  }

  return response.data.data.map((card: any) => {
    return {
      id: card.id,
      last4: card.last4,
      cardBrand: card.paymentMethod,
    };
  });
}
