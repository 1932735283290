
import { bemBuilder } from "@chatfood/core-utils";
import { defineComponent, PropType, computed } from "vue";
import { AtomText, AtomTextTypeEnum, AtomTextColorEnum } from "../../atom/text";
import { AtomIcon, AtomIconNameEnum } from "../../atom/icon";
import { AtomGraphic } from "../../atom/graphic";
import { PaymentMethodsEnum } from "@/enum";

const css = bemBuilder("mol-payment-box");

export default defineComponent({
  name: "MolPaymentBox",
  components: {
    AtomText,
    AtomIcon,
    AtomGraphic,
  },
  props: {
    paymentImageSize: {
      type: Number,
      required: true,
    },
    last4: {
      type: String,
      default: "",
    },
    paymentMethod: {
      type: String as PropType<PaymentMethodsEnum>,
      required: true,
    },
    onClick: {
      type: Function,
      required: true,
    },
    paymentLabel: {
      type: String,
      required: true,
    },
    showChange: {
      type: Boolean,
      default: false,
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const brandIcon = computed(() =>
      Object.values(PaymentMethodsEnum).includes(props.paymentMethod)
        ? props.paymentMethod
        : "card"
    );

    return {
      css,
      brandIcon,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomIconNameEnum,
    };
  },
});
