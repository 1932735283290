import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MolLoyaltySwitch = _resolveComponent("MolLoyaltySwitch")!
  const _component_MolLoyaltyBanner = _resolveComponent("MolLoyaltyBanner")!

  return (_ctx.showLoyalty)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.css())
      }, [
        (_ctx.isLoggedIn)
          ? (_openBlock(), _createBlock(_component_MolLoyaltySwitch, {
              key: 0,
              loyaltyValue: { value: _ctx.burnedPoints, currency: _ctx.currency, locale: _ctx.locale },
              checked: _ctx.useLoyaltyProgram,
              label: _ctx.t('view.bill.loyalty_program.use_loyalty'),
              onChange: _ctx.setState
            }, null, 8, ["loyaltyValue", "checked", "label", "onChange"]))
          : (_openBlock(), _createBlock(_component_MolLoyaltyBanner, {
              key: 1,
              onCtaClick: _ctx.handleRedirect,
              subtitle: _ctx.t('view.bill.loyalty_program.subtitle'),
              "cta-label": _ctx.t('view.bill.loyalty_program.cta_login'),
              title: 
        _ctx.t('view.bill.loyalty_program.title', {
          percentage: _ctx.orderAmountPercentageWorth,
        })
      
            }, null, 8, ["onCtaClick", "subtitle", "cta-label", "title"]))
      ], 2))
    : _createCommentVNode("", true)
}