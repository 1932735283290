import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomGraphic = _resolveComponent("AtomGraphic")!
  const _component_AtomText = _resolveComponent("AtomText")!

  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(_ctx.css())
  }, [
    _createElementVNode("span", null, [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.css('location_indicator'))
      }, [
        _createVNode(_component_AtomGraphic, {
          name: "chatfood-icon",
          size: 45,
          class: _normalizeClass(_ctx.css('icon'))
        }, null, 8, ["class"])
      ], 2),
      _createVNode(_component_AtomText, {
        color: _ctx.AtomTextColorEnum.BASE_50,
        class: _normalizeClass(_ctx.css('title'))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.title), 1)
        ]),
        _: 1
      }, 8, ["color", "class"])
    ])
  ], 2))
}