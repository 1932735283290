
import dayjs from "dayjs";
import { bemBuilder } from "@chatfood/core-utils";
import { t } from "@/i18n";
import { defineComponent, ref, onMounted, onUnmounted, watch } from "vue";
import relativeTime from "dayjs/plugin/relativeTime";
import { AtomText, AtomTextTypeEnum, AtomTextColorEnum } from "@/design-system";
dayjs.extend(relativeTime);
const css = bemBuilder("reload-bill");

export default defineComponent({
  name: "ReloadBill",
  components: {
    AtomText,
  },
  props: {
    lastUpdated: {
      type: Date,
      default: undefined,
    },
  },
  emits: ["reloadBill"],
  setup(props) {
    const lastSyncFromNow = ref();
    const intervalIdSyncRefresher = ref();

    const refreshLastSyncFrequency = 60000;

    watch(
      () => props.lastUpdated,
      () => refreshLastSyncFromNow(),
      { immediate: true }
    );

    function refreshLastSyncFromNow(): void {
      lastSyncFromNow.value = dayjs(props.lastUpdated).fromNow();
    }

    onMounted(() => {
      intervalIdSyncRefresher.value = setInterval(
        refreshLastSyncFromNow,
        refreshLastSyncFrequency
      );
    });

    onUnmounted(() => {
      clearInterval(intervalIdSyncRefresher.value);
    });
    return {
      t,
      css,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      lastSyncFromNow,
    };
  },
});
