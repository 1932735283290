import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4af899a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-position", "value", "disabled", "required", "onFocus", "onInput", "onKeydown"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      _ctx.css(),
      {
        [_ctx.css('', 'invalid')]: _ctx.invalid,
      },
    ])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pinCode, (value, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: i,
        class: _normalizeClass(_ctx.css('field'))
      }, [
        _createElementVNode("input", {
          ref_for: true,
          ref: 
          (el) => {
            if (el) _ctx.pinCodeFields[i] = el;
          }
        ,
          class: _normalizeClass(_ctx.css('input')),
          autocomplete: "one-time-code",
          "data-position": i,
          type: "tel",
          pattern: "[0-9]+",
          value: value,
          disabled: _ctx.disabled,
          required: _ctx.required,
          onFocus: ($event: any) => (_ctx.onFocus(i)),
          onInput: ($event: any) => (_ctx.onInput(i)),
          onKeydown: [
            _withKeys(_withModifiers(($event: any) => (_ctx.onBackspace(i)), ["prevent"]), ["backspace"]),
            _withKeys(_withModifiers(($event: any) => (_ctx.onArrowRight(i)), ["prevent"]), ["right"]),
            _withKeys(_withModifiers(($event: any) => (_ctx.onArrowLeft(i)), ["prevent"]), ["left"]),
            _cache[0] || (_cache[0] = _withKeys(
//@ts-ignore
(...args) => (_ctx.killEvent && _ctx.killEvent(...args)), ["up"])),
            _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.killEvent && _ctx.killEvent(...args)), ["down"]))
          ]
        }, null, 42, _hoisted_1)
      ], 2))
    }), 128))
  ], 2))
}