/* eslint-disable no-magic-numbers */
export const transactionIdGenerator = (): string => {
  try {
    const typedArray = new Uint32Array(8);
    window.crypto.getRandomValues(typedArray);

    let id = "";
    for (let i = 0; i < typedArray.length; i++) {
      id += typedArray[i].toString(16).slice(-4);
    }

    return id;
  } catch (e) {
    return Date.now().toString(36) + Math.random().toString(36).substr(2, 9);
  }
};
