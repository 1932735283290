import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomIcon = _resolveComponent("AtomIcon")!
  const _component_AtomText = _resolveComponent("AtomText")!
  const _component_MolPaymentBox = _resolveComponent("MolPaymentBox")!
  const _component_AtomButton = _resolveComponent("AtomButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.css())
  }, [
    _createElementVNode("a", {
      href: "#",
      class: _normalizeClass(_ctx.css('back')),
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onBack && _ctx.onBack(...args)), ["prevent"]))
    }, [
      _createVNode(_component_AtomIcon, {
        name: _ctx.AtomIconNameEnum.BACK,
        color: _ctx.AtomTextColorEnum.BASE_30
      }, null, 8, ["name", "color"]),
      _createVNode(_component_AtomText, {
        class: _normalizeClass(_ctx.css('back-text'))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Go back")
        ]),
        _: 1
      }, 8, ["class"])
    ], 2),
    _createVNode(_component_AtomText, {
      type: _ctx.AtomTextTypeEnum.H3
    }, {
      default: _withCtx(() => [
        _createTextVNode("Payment method")
      ]),
      _: 1
    }, 8, ["type"]),
    _createVNode(_component_AtomText, {
      class: _normalizeClass(_ctx.css('description'))
    }, {
      default: _withCtx(() => [
        _createTextVNode("Choose a payment method")
      ]),
      _: 1
    }, 8, ["class"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentMethodList, (item) => {
      return (_openBlock(), _createBlock(_component_MolPaymentBox, {
        class: _normalizeClass(_ctx.css('item')),
        key: item.method,
        paymentMethod: item.method,
        paymentImageSize: 27,
        paymentLabel: item.label,
        last4: item.last4,
        onClick: () => (_ctx.selectedItemId = item.id),
        showCheckbox: _ctx.selectedItemId === item.id
      }, null, 8, ["class", "paymentMethod", "paymentLabel", "last4", "onClick", "showCheckbox"]))
    }), 128)),
    _createElementVNode("div", null, [
      _createVNode(_component_AtomButton, {
        class: _normalizeClass(_ctx.css('add-card')),
        size: _ctx.AtomButtonSizeEnum.SMALL,
        type: _ctx.AtomButtonTypeEnum.SECONDARY,
        onClick: () => _ctx.addCard()
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Add card ")
        ]),
        _: 1
      }, 8, ["class", "size", "type", "onClick"])
    ]),
    _createVNode(_component_AtomButton, {
      class: _normalizeClass(_ctx.css('button')),
      onClick: () => _ctx.onChangeMethod(_ctx.selectedItem)
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Done ")
      ]),
      _: 1
    }, 8, ["class", "onClick"])
  ], 2))
}