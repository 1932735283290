import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55be3496"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomGraphic = _resolveComponent("AtomGraphic")!
  const _component_AtomText = _resolveComponent("AtomText")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.css(''))
  }, [
    _createElementVNode("a", {
      target: "_blank",
      href: _ctx.linkUrl,
      class: _normalizeClass(_ctx.css('link'))
    }, [
      _createVNode(_component_AtomGraphic, {
        class: _normalizeClass(_ctx.css('icon')),
        name: "chatfood-icon",
        size: 21
      }, null, 8, ["class"]),
      _createVNode(_component_AtomText, {
        color: _ctx.AtomTextColorEnum.BASE_50,
        class: _normalizeClass(_ctx.css('text'))
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Powered by ChatFood ")
        ]),
        _: 1
      }, 8, ["color", "class"])
    ], 10, _hoisted_1)
  ], 2))
}