import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import mixpanel from "mixpanel-browser";
import { reportInit } from "@chatfood/bug-reporter";
import "@chatfood/design-system/dist/style.css";

const app = createApp(App);

mixpanel.init(process.env.VUE_APP_MIXPANEL_KEY);

reportInit({
  app,
  environment: process.env.VUE_APP_ENV,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  enabled: process.env.VUE_APP_SENTRY_ENABLED === "true",
  debug: process.env.VUE_APP_SENTRY_DEBUG === "true",
  release: process.env.VUE_APP_VERSION,
  jsFramework: "Vue 3",
  browserTracing: {
    router,
    origins: [/^\//],
  },
});

app.use(router);
app.mount("#app");

// @ts-expect-error: not aware of window here
window.version = process.env.VUE_APP_VERSION;
