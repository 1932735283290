
import { t } from "@/i18n";
import { bemBuilder } from "@chatfood/core-utils";
import { computed, defineComponent, onMounted, PropType } from "vue";
import {
  AtomButton,
  AtomButtonSizeEnum,
  AtomButtonTypeEnum,
  MolPoweredByChatfood,
  AtomIconNameEnum,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomGraphic,
} from "@/design-system";
import { IFetchBusinessBySlugResponse } from "@/repo/fetch-business-by-slug";
import { ViewsEnum } from "@/view/views.enum";
import { useRouter } from "vue-router";
import mixpanel from "mixpanel-browser";

const css = bemBuilder("view-no-bill");

export default defineComponent({
  name: "ViewNoBill",
  components: {
    AtomButton,
    AtomText,
    MolPoweredByChatfood,
    AtomGraphic,
  },
  props: {
    business: {
      type: Object as PropType<IFetchBusinessBySlugResponse>,
      required: true,
    },
    tableId: {
      type: String,
      required: true,
    },
    changeViewTo: {
      type: Function as PropType<(view: ViewsEnum) => void>,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const businessName = computed(() => props.business?.name);

    function reloadPage(): void {
      router.replace({
        name: "PAT",
        params: {
          businessId: props.business.id,
          tableId: props.tableId,
        },
      });

      props.changeViewTo(ViewsEnum.BILL);
    }

    const tracking = (event: string): void => {
      mixpanel.track(event, {
        business_id: props.business.id,
        business_name: props.business.name,
        table_id: props.tableId,
        event_category: "pay_at_table",
      });
    };

    onMounted(() => {
      tracking("No bills present");
    });

    return {
      t,
      css,
      businessName,
      reloadPage,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonSizeEnum,
      AtomButtonTypeEnum,
      AtomIconNameEnum,
    };
  },
});
