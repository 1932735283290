import { http } from "@/repo/http";
import { IRepoErrors } from "@/repo/errors";

type IApiResponse = {
  data: {
    checkout_token: {
      gateway_account_id: string;
      type: "apple_pay" | "google_pay";
      token: string;
      expires_on: string;
      expiry_month: number;
      expiry_year: number;
      brand: string;
      last4: number;
    };
  };
};

export type IExchangeTokenBody = {
  businessId: string;
  tokenData: {
    type: string;
    paymentData: {
      version: string;
      data: string;
      signature: string;
      header: {
        ephemeralPublicKey: string;
        publicKeyHash: string;
        transactionId: string;
      };
    };
  };
};

export type IExchangeTokenResponse = {
  checkoutToken: {
    gatewayAccountIid: string;
    type: string;
    token: string;
    expiresOn: string;
    expiryMonth: number;
    expiryYear: number;
    brand: string;
    last4: number;
  };
};

export class ExchangeTokenError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repository: payment.exchangeToken");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export const exchangeToken = async (
  request: IExchangeTokenBody
): Promise<IExchangeTokenResponse> => {
  try {
    const { businessId, tokenData } = request;

    const response: IApiResponse = await http.post(
      `/storefront/businesses/${businessId}/checkout-com/exchange-token`,
      {
        token_data: {
          type: tokenData.type,
          payment_data: tokenData.paymentData,
        },
      }
    );

    const { checkout_token } = response.data || {};
    const token = checkout_token;

    return {
      checkoutToken: {
        gatewayAccountIid: token.gateway_account_id,
        type: token.type,
        token: token.token,
        expiresOn: token.expires_on,
        expiryMonth: token.expiry_month,
        expiryYear: token.expiry_year,
        brand: token.brand,
        last4: token.last4,
      },
    };
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw new Error(e);
    }

    const { status, error } = e.response || {};

    // eslint-disable-next-line no-magic-numbers
    if (status === 422) {
      throw new ExchangeTokenError(error);
    }

    const errors: IRepoErrors = { unkownError: true };
    throw new ExchangeTokenError(errors);
  }
};
