import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomText = _resolveComponent("AtomText")!
  const _component_AtomInput = _resolveComponent("AtomInput")!
  const _component_AtomFormControl = _resolveComponent("AtomFormControl")!

  return (_openBlock(), _createBlock(_component_AtomFormControl, {
    class: _normalizeClass(_ctx.css()),
    label: _ctx.label,
    error: _ctx.errorMsg
  }, {
    default: _withCtx(() => [
      _createVNode(_component_AtomText, {
        class: _normalizeClass(_ctx.css('symbol')),
        color: _ctx.AtomTextColorEnum.BASE_20,
        type: _ctx.AtomTextTypeEnum.SUBTITLE
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.symbol), 1)
        ]),
        _: 1
      }, 8, ["class", "color", "type"]),
      _createVNode(_component_AtomInput, _mergeProps({
        class: [_ctx.css('input'), { [_ctx.css('short-symbol')]: _ctx.symbol.length === 1 }]
      }, _ctx.$attrs, {
        value: _ctx.displayAmount,
        modelValue: _ctx.displayAmount,
        type: _ctx.InputTypesEnum.NUMBER,
        pattern: "[0-9]*",
        invalid: Boolean(_ctx.errorMsg),
        "on-input": _ctx.setValue,
        autofocus: _ctx.autofocus
      }), null, 16, ["class", "value", "modelValue", "type", "invalid", "on-input", "autofocus"])
    ]),
    _: 1
  }, 8, ["class", "label", "error"]))
}