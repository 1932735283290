
import { bemBuilder } from "@chatfood/core-utils";
import { defineComponent, PropType, ref, onMounted } from "vue";
import {
  AtomIcon,
  AtomButton,
  AtomButtonSizeEnum,
  MolLoading,
} from "@/design-system";
import { checkoutStyles } from "./assets/checkout-styles";
import { ICardDetails } from ".";

const css = bemBuilder("bill-checkout-form");

type ICardCheckout = {
  expiry_month: number;
  expiry_year: number;
  last4: string;
  scheme: string | undefined;
  token: string;
};

export default defineComponent({
  name: "BillCheckoutForm",
  components: {
    AtomIcon,
    AtomButton,
    MolLoading,
  },
  props: {
    checkoutToken: {
      type: String,
      required: true,
    },
    selectedCard: {
      type: Function as PropType<(card: ICardDetails) => void>,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(true);
    const isProcessing = ref(false);
    const frameActivated = ref(false);
    const erroMessage = ref("");
    const unexpectedError = "An unexpected error occurred. Please try again.";
    const unavailableFrame =
      "Sorry, but our online payment method is unavailable right now. Try again in a minute or chose another payment method.";

    onMounted(() => {
      setupFrames();
    });

    async function setupFrames(): Promise<void> {
      isLoading.value = true;
      frameActivated.value = false;

      await loadFramesLibrary();

      if ((window as any).Frames) {
        try {
          framesInit();
        } catch {
          framesUnavailable(unavailableFrame, false);
        }
        return;
      }

      framesUnavailable(unavailableFrame, false);
    }

    function loadFramesLibrary(): any {
      if ((window as any).Frames) return Promise.resolve();
      return new Promise((resolve, reject) => {
        const scriptTag = document.createElement("script");
        scriptTag.async = true;
        scriptTag.src = "https://cdn.checkout.com/js/framesv2.min.js";
        document.head.appendChild(scriptTag);
        scriptTag.onload = resolve;
        scriptTag.onerror = reject;
      });
    }

    function framesUnavailable(message: string, frameActive = true): void {
      erroMessage.value = message;
      isLoading.value = false;
      frameActivated.value = frameActive;
    }

    function framesInit(): void {
      (window as any).Frames.init({
        debugMode: false,
        publicKey: props.checkoutToken,
        style: checkoutStyles,

        cardSubmitted: () => {
          erroMessage.value = "";
          isProcessing.value = true;
        },

        frameActivated: () => {
          frameActivated.value = true;
          isLoading.value = false;
        },

        cardTokenized: (e: ICardCheckout) => {
          let expiryMonth = String(e.expiry_month);

          if (expiryMonth.length === 1) {
            expiryMonth = `0${expiryMonth}`;
          }

          const cardInfo = {
            card: {
              expiryMonth,
              expiryYear: String(e.expiry_year),
              last4: String(e.last4),
              paymentMethod: e.scheme || "",
            },
            cardToken: e.token,
          };

          props.selectedCard(cardInfo);
        },

        cardTokenizationFailed: () => {
          framesUnavailable(unexpectedError);
        },
      });
    }

    async function submitCard(): Promise<void> {
      try {
        isProcessing.value = true;
        await (window as any).Frames.submitCard();
      } catch {
        framesUnavailable(unexpectedError);
      } finally {
        isProcessing.value = false;
      }
    }

    return {
      css,
      isLoading,
      frameActivated,
      submitCard,
      erroMessage,
      isProcessing,
      AtomButtonSizeEnum,
    };
  },
});
