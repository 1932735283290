
import { bemBuilder } from "@chatfood/core-utils";
import { defineComponent, PropType, ref, computed, onMounted } from "vue";
import {
  AtomIcon,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomFormControl,
  AtomPinCode,
} from "@/design-system";
import { authRequestOtp } from "@/repo/auth/request-otp";
import { authLoginUsingOtp } from "@/repo/auth/login-using-otp";
import { PhoneVerificationErrorEnum } from "@/repo/auth/phone-verification-error";

const css = bemBuilder("auth-validate-otp");

export default defineComponent({
  name: "AuthRequestOtp",
  components: {
    AtomIcon,
    AtomText,
    AtomFormControl,
    AtomPinCode,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    phoneNumber: {
      type: String,
      required: true,
    },
    phoneNumberInternational: {
      type: String,
      required: true,
    },
    phoneCountryCode: {
      type: String,
      required: true,
    },
    onComplete: {
      type: Function as PropType<(accessToken: string) => void>,
      required: true,
    },
  },
  setup(props) {
    const error = ref("");
    const otpMaxLength = 5;
    const isDisabled = ref(false);
    const defaultCounterSeconds = 30;
    const displayOtp = ref(true);

    const description = computed(
      () =>
        `We’ve sent you an SMS with the verification code to ${props.phoneNumberInternational}`
    );

    const resendCounter = ref(defaultCounterSeconds);

    const counterMessage = computed(
      () => `I haven't received the code • ${resendCounter.value} sec`
    );

    onMounted(() => {
      resendTimeOut();
    });

    function resendTimeOut(): void {
      const counter = setInterval(() => {
        if (resendCounter.value > 0) resendCounter.value--;
        if (resendCounter.value <= 0) clearInterval(counter);
      }, 1000);
    }

    const displayResendCode = computed(() => resendCounter.value === 0);

    async function requestOtp(): Promise<void> {
      displayOtp.value = false;

      try {
        await authRequestOtp({
          countryCode: props.phoneCountryCode,
          phoneNumber: props.phoneNumber,
          businessId: props.businessId,
        });

        isDisabled.value = false;
        error.value = "";
      } catch {
        error.value =
          "We couldn't process your request. Please, try again later.";
      } finally {
        displayOtp.value = true;
        resendCounter.value = defaultCounterSeconds;
        resendTimeOut();
      }
    }

    async function validateOtp(otp: string): Promise<void> {
      if (otp.length !== otpMaxLength || isDisabled.value) return;

      isDisabled.value = true;
      error.value = "";

      try {
        const accessToken = await authLoginUsingOtp({
          countryCode: props.phoneCountryCode,
          phoneNumber: props.phoneNumber,
          otp,
        });

        props.onComplete(accessToken);
      } catch (e: any) {
        let errorMessage =
          "We couldn't process your request. Please, try again later.";

        if (e.errors.phoneVerificationError) {
          const mapError: Record<string, string> = {
            [PhoneVerificationErrorEnum.OTP_NOT_MATCH]: "The code is invalid.",
            [PhoneVerificationErrorEnum.NOT_STARTED]:
              "The verification code has expired. Please try again.",
          };

          errorMessage = mapError[e.errors.phoneVerificationError.code];
        }

        error.value = errorMessage;
      } finally {
        isDisabled.value = false;
      }
    }

    return {
      css,
      error,
      displayOtp,
      otpMaxLength,
      isDisabled,
      requestOtp,
      validateOtp,
      description,
      counterMessage,
      displayResendCode,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
