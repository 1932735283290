
import { bemBuilder } from "@chatfood/core-utils";
import { defineComponent } from "vue";
import { AtomIcon } from "../../atom/icon";

const css = bemBuilder("mol-loading");

export default defineComponent({
  name: "MolLoading",
  components: {
    AtomIcon,
  },
  props: {
    size: {
      type: Number,
      default: 38,
    },
    floating: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      css,
    };
  },
});
