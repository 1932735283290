import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomIcon = _resolveComponent("AtomIcon")!
  const _component_AtomInput = _resolveComponent("AtomInput")!
  const _component_MolIconButton = _resolveComponent("MolIconButton")!
  const _component_AtomText = _resolveComponent("AtomText")!
  const _component_MolSelectCountry = _resolveComponent("MolSelectCountry")!
  const _component_AtomDrawer = _resolveComponent("AtomDrawer")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.css())
  }, [
    _createVNode(_component_AtomInput, {
      modelValue: _ctx.phoneNumber,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.phoneNumber) = $event)),
      onKeyup: _ctx.testValue,
      "on-blur": _ctx.onBlur,
      autofocus: "",
      invalid: _ctx.isInputInvalid,
      placeholder: _ctx.placeholder,
      "on-input": (value) => (_ctx.phoneNumber = value)
    }, {
      prefix: _withCtx(() => [
        (_ctx.selectedCountry)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: "#",
              class: _normalizeClass(_ctx.css('select-country')),
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.displayCountriesList(true)), ["prevent"]))
            }, [
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.css('select-country-flag'))
              }, _toDisplayString(_ctx.selectedCountry.flagEmoji), 3),
              _createVNode(_component_AtomIcon, {
                size: 10,
                class: _normalizeClass(_ctx.css('select-country-icon')),
                name: _ctx.AtomIconNameEnum.CARET_DOWN
              }, null, 8, ["class", "name"]),
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.css('select-country-code'))
              }, _toDisplayString(_ctx.countryCode), 3)
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue", "onKeyup", "on-blur", "invalid", "placeholder", "on-input"]),
    _createVNode(_component_AtomDrawer, {
      full: "",
      label: "list-of-countries",
      modelValue: _ctx.showCountriesList,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showCountriesList) = $event)),
      "on-close": () => (_ctx.showCountriesList = false)
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.css('drawer-header'))
        }, [
          _createVNode(_component_MolIconButton, {
            "icon-name": _ctx.AtomIconNameEnum.TIMES,
            "on-click": () => _ctx.displayCountriesList(false)
          }, null, 8, ["icon-name", "on-click"]),
          _createVNode(_component_AtomText, {
            class: _normalizeClass(_ctx.css('drawer-title')),
            type: _ctx.AtomTextTypeEnum.H1,
            color: _ctx.AtomTextColorEnum.CHARCOAL
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Choose your country ")
            ]),
            _: 1
          }, 8, ["class", "type", "color"])
        ], 2),
        _createVNode(_component_MolSelectCountry, { "on-selected-country": _ctx.onSelectCountry }, null, 8, ["on-selected-country"])
      ]),
      _: 1
    }, 8, ["modelValue", "on-close"])
  ], 2))
}