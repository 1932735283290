import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import ViewController from "../ViewController.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:businessSlug/:tableId",
    name: "PAT",
    props: true,
    component: ViewController,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
