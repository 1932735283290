import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomText = _resolveComponent("AtomText")!
  const _component_AtomMoney = _resolveComponent("AtomMoney")!
  const _component_AtomIcon = _resolveComponent("AtomIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.css())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.css('amount'))
    }, [
      _createVNode(_component_AtomText, {
        class: _normalizeClass(_ctx.css('label')),
        type: _ctx.AtomTextTypeEnum.SMALL,
        color: _ctx.AtomTextColorEnum.BASE_50
      }, {
        default: _withCtx(() => [
          _createTextVNode(" You’ve earned ")
        ]),
        _: 1
      }, 8, ["class", "type", "color"]),
      _createVNode(_component_AtomText, {
        type: _ctx.AtomTextTypeEnum.SUBTITLE
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AtomMoney, {
            amount: _ctx.earnedPoints,
            currency: _ctx.currency,
            locale: _ctx.locale
          }, null, 8, ["amount", "currency", "locale"])
        ]),
        _: 1
      }, 8, ["type"])
    ], 2),
    _createVNode(_component_AtomIcon, {
      name: "loyalty",
      size: 35,
      color: _ctx.businessColor
    }, null, 8, ["color"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.css('amount'))
    }, [
      _createVNode(_component_AtomText, {
        class: _normalizeClass(_ctx.css('label')),
        type: _ctx.AtomTextTypeEnum.SMALL,
        color: _ctx.AtomTextColorEnum.BASE_50
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Total credit balance ")
        ]),
        _: 1
      }, 8, ["class", "type", "color"]),
      _createVNode(_component_AtomText, {
        type: _ctx.AtomTextTypeEnum.SUBTITLE
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AtomMoney, {
            amount: _ctx.totalCreditBalance,
            currency: _ctx.currency,
            locale: _ctx.locale
          }, null, 8, ["amount", "currency", "locale"])
        ]),
        _: 1
      }, 8, ["type"])
    ], 2)
  ], 2))
}