import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomGraphic = _resolveComponent("AtomGraphic")!
  const _component_AtomText = _resolveComponent("AtomText")!
  const _component_AtomButton = _resolveComponent("AtomButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.css())
  }, [
    (_ctx.iconName)
      ? (_openBlock(), _createBlock(_component_AtomGraphic, {
          key: 0,
          name: _ctx.iconName,
          size: _ctx.iconSize,
          class: _normalizeClass(_ctx.css('icon'))
        }, null, 8, ["name", "size", "class"]))
      : _createCommentVNode("", true),
    _createVNode(_component_AtomText, {
      type: _ctx.AtomTextTypeEnum.H3,
      class: _normalizeClass(_ctx.css('title'))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.title), 1)
      ]),
      _: 1
    }, 8, ["type", "class"]),
    _createVNode(_component_AtomText, {
      class: _normalizeClass(_ctx.css('description')),
      color: _ctx.AtomTextColorEnum.BASE_50
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.description), 1)
      ]),
      _: 1
    }, 8, ["class", "color"]),
    (_ctx.buttonLabel)
      ? (_openBlock(), _createBlock(_component_AtomButton, {
          key: 1,
          onClick: () => _ctx.onClick(),
          class: _normalizeClass(_ctx.css('button'))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.buttonLabel), 1)
          ]),
          _: 1
        }, 8, ["onClick", "class"]))
      : _createCommentVNode("", true)
  ], 2))
}