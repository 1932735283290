export enum PhoneVerificationErrorEnum {
  OTP_NOT_MATCH = "otp_doesnt_match",
  NOT_FOUND = "not_found",
  NOT_STARTED = "phone_verification_not_started",
}

export const PhoneVerificationErrorsMap = [
  {
    apiError: "phone_verification_not_started",
    represents: PhoneVerificationErrorEnum.NOT_STARTED,
  },
  {
    apiError: "phone_verification_code_doesnt_match",
    represents: PhoneVerificationErrorEnum.OTP_NOT_MATCH,
  },
];
