import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, Transition as _Transition, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5fdde568"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomText = _resolveComponent("AtomText")!
  const _component_MolCollapseIndicator = _resolveComponent("MolCollapseIndicator")!
  const _component_ReloadAction = _resolveComponent("ReloadAction")!
  const _component_AtomMoney = _resolveComponent("AtomMoney")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.css())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.css('top'))
    }, [
      _createElementVNode("a", {
        href: "#",
        class: _normalizeClass(_ctx.css('header')),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isCollapsed = !_ctx.isCollapsed))
      }, [
        _createVNode(_component_AtomText, {
          type: _ctx.AtomTextTypeEnum.H3
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.tableName), 1)
          ]),
          _: 1
        }, 8, ["type"]),
        _createVNode(_component_MolCollapseIndicator, {
          open: !_ctx.isCollapsed
        }, null, 8, ["open"])
      ], 2),
      (_ctx.showReloadBillAction)
        ? (_openBlock(), _createBlock(_component_ReloadAction, {
            key: 0,
            lastUpdated: _ctx.lastUpdated,
            onReloadBill: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('reloadBill')))
          }, null, 8, ["lastUpdated"]))
        : _createCommentVNode("", true),
      _createVNode(_Transition, { name: "slide" }, {
        default: _withCtx(() => [
          (!_ctx.isCollapsed)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("ul", {
                  class: _normalizeClass([
              _ctx.css('list'),
              { [_ctx.css('list', 'top-border')]: _ctx.showReloadBillAction },
            ])
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.billItems, (item) => {
                    return (_openBlock(), _createElementBlock("li", {
                      class: _normalizeClass(_ctx.css('list-item')),
                      key: item.id
                    }, [
                      _createVNode(_component_AtomText, {
                        tag: _ctx.AtomTextTagEnum.P
                      }, {
                        default: _withCtx(() => [
                          (item.quantity > 1)
                            ? (_openBlock(), _createBlock(_component_AtomText, {
                                key: 0,
                                class: _normalizeClass(_ctx.css('list-qtt')),
                                type: _ctx.AtomTextTypeEnum.SMALL_BOLD
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.quantity), 1)
                                ]),
                                _: 2
                              }, 1032, ["class", "type"]))
                            : _createCommentVNode("", true),
                          _createTextVNode(" " + _toDisplayString(item.name), 1)
                        ]),
                        _: 2
                      }, 1032, ["tag"]),
                      _createVNode(_component_AtomText, {
                        class: _normalizeClass(_ctx.css('list-price'))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_AtomMoney, {
                            currency: item.unitPrice.currencyCode,
                            amount: item.unitPrice.currencyValue * item.quantity,
                            locale: _ctx.countryCode
                          }, null, 8, ["currency", "amount", "locale"])
                        ]),
                        _: 2
                      }, 1032, ["class"])
                    ], 2))
                  }), 128))
                ], 2)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      (_ctx.isPayeeAvailable)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass([
          _ctx.css('payments'),
          {
            [_ctx.css('payments', 'top-border')]:
              _ctx.isCollapsed && _ctx.showReloadBillAction,
          },
        ])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.css('payments', 'total'))
            }, [
              _createElementVNode("div", null, [
                _createVNode(_component_AtomText, {
                  type: _ctx.AtomTextTypeEnum.BODY_BOLD
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Order total")
                  ]),
                  _: 1
                }, 8, ["type"]),
                _createVNode(_component_AtomText, {
                  class: _normalizeClass(_ctx.css('total-vat')),
                  type: _ctx.AtomTextTypeEnum.SMALL,
                  color: _ctx.AtomTextColorEnum.BASE_50
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" (VAT Included) ")
                  ]),
                  _: 1
                }, 8, ["class", "type", "color"])
              ]),
              _createVNode(_component_AtomText, {
                type: _ctx.AtomTextTypeEnum.BODY_BOLD
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_AtomMoney, {
                    currency: _ctx.billCurrency,
                    amount: _ctx.billTotal,
                    locale: _ctx.countryCode
                  }, null, 8, ["currency", "amount", "locale"])
                ]),
                _: 1
              }, 8, ["type"])
            ], 2),
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.billPaymentsList, (payment, i) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: i,
                  class: _normalizeClass(_ctx.css('payments', 'line'))
                }, [
                  _createVNode(_component_AtomText, {
                    color: _ctx.AtomTextColorEnum.MINT
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(payment.customerName ? payment.customerName : `Guest #${i + 1}`), 1)
                    ]),
                    _: 2
                  }, 1032, ["color"]),
                  _createVNode(_component_AtomText, {
                    color: _ctx.AtomTextColorEnum.MINT
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AtomMoney, {
                        currency: payment.amountPaid.currencyCode,
                        amount: payment.amountPaid.currencyValue,
                        locale: _ctx.countryCode
                      }, null, 8, ["currency", "amount", "locale"])
                    ]),
                    _: 2
                  }, 1032, ["color"])
                ], 2))
              }), 128))
            ])
          ], 2))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.css('bottom'))
    }, [
      (_ctx.isOrderSettled)
        ? (_openBlock(), _createBlock(_component_AtomText, {
            key: 0,
            color: _ctx.AtomTextColorEnum.MINT,
            class: _normalizeClass(_ctx.css('bottom', 'settled'))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" The bill was settled ")
            ]),
            _: 1
          }, 8, ["color", "class"]))
        : (_openBlock(), _createBlock(_component_AtomText, {
            key: 1,
            type: _ctx.AtomTextTypeEnum.SUBTITLE,
            class: _normalizeClass(_ctx.css('total'))
          }, {
            default: _withCtx(() => [
              (_ctx.isPayeeAvailable)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, "Remaining total"))
                : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createTextVNode(" Order total "),
                    _createElementVNode("small", {
                      class: _normalizeClass(_ctx.css('total-vat'))
                    }, "(VAT Included)", 2)
                  ])),
              _createVNode(_component_AtomMoney, {
                amount: _ctx.billRemaining,
                locale: _ctx.countryCode,
                currency: _ctx.billCurrency
              }, null, 8, ["amount", "locale", "currency"])
            ]),
            _: 1
          }, 8, ["type", "class"])),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2)
  ], 2))
}