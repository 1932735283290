
import { bemBuilder } from "@chatfood/core-utils";
import { computed, defineComponent } from "vue";

const css = bemBuilder("atom-logo");

export default defineComponent({
  name: "AtomLogo",
  props: {
    imageLink: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const placeholder =
      "https://assets.website-files.com/61125ee08a13c0883c488423/61125ee08a13c06f0b4884ab_pricing1.svg";

    const imageUrl = computed(() =>
      props.imageLink
        ? encodeURI(
            `${props.imageLink}?fm=jpeg&q=90&w=200&h=200&fit=crop&crop=center`
          )
        : placeholder
    );

    return {
      css,
      imageUrl,
    };
  },
});
