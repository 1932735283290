import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomIcon = _resolveComponent("AtomIcon")!

  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass([
      _ctx.css(),
      {
        [_ctx.css('', 'floating')]: _ctx.floating,
        [_ctx.css('', 'inline')]: _ctx.inline,
      },
    ])
  }, [
    _createVNode(_component_AtomIcon, {
      name: "loading",
      size: _ctx.size,
      class: _normalizeClass(_ctx.css('icon'))
    }, null, 8, ["size", "class"])
  ], 2))
}