
import { AtomMoney } from "@chatfood/design-system";
import { computed, defineComponent, PropType } from "vue";
import { bemBuilder } from "@chatfood/core-utils";
import {
  getEarnedLoyaltyPoints,
  getRedeemableLoyaltyPoints,
} from "@/util/loyalty-points";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomIcon,
} from "@/design-system";
import { CurrencyCodeEnum } from "@/enum";

const css = bemBuilder("payment-success-loyalty-program");

export default defineComponent({
  name: "PaymentSuccessLoyaltyProgram",
  components: {
    AtomText,
    AtomMoney,
    AtomIcon,
  },
  props: {
    totalOrder: {
      type: Number,
      required: true,
    },
    loyaltyPoints: {
      type: Number,
      required: true,
    },
    maximumRedeemedOrderAmount: {
      type: Number,
      required: true,
    },
    maximumRedeemedOrderPercentage: {
      type: Number,
      required: true,
    },
    orderAmountPercentageWorth: {
      type: Number,
      required: true,
    },
    isBurnedPoints: {
      type: Boolean,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    currency: {
      type: String as PropType<CurrencyCodeEnum>,
      required: true,
    },
    businessColor: {
      type: String,
      default: "var(--color-primary)",
    },
  },
  setup(props) {
    const burnedPoints = computed(() => {
      if (!props.isBurnedPoints) return 0;

      return getRedeemableLoyaltyPoints(props.totalOrder, props.loyaltyPoints, {
        maximumRedeemedOrderAmount: props.maximumRedeemedOrderAmount,
        maximumRedeemedOrderPercentage: props.maximumRedeemedOrderPercentage,
      });
    });

    const earnedPoints = computed(() => {
      const finalTotal = props.totalOrder - burnedPoints.value;
      return getEarnedLoyaltyPoints(
        finalTotal,
        props.orderAmountPercentageWorth
      );
    });

    const totalCreditBalance = computed(
      () => props.loyaltyPoints - burnedPoints.value + earnedPoints.value
    );

    return {
      css,
      burnedPoints,
      totalCreditBalance,
      earnedPoints,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
