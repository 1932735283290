export const removeStorageItem = (key: string): void => {
  if (!window?.localStorage) return;

  window.localStorage.removeItem(key);
};

export const setStorageItem = (
  key: string,
  value: Record<string, any>
): void => {
  if (!window?.localStorage) return;

  window.localStorage.setItem(key, JSON.stringify(value));
};

export const getStorageItem = (key: string): null | Record<string, any> => {
  if (!window?.localStorage) return null;

  const value = window.localStorage.getItem(key);

  if (!value) return null;

  return JSON.parse(value);
};
