
import { bemBuilder } from "@chatfood/core-utils";
import { defineComponent, PropType } from "vue";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomTextTagEnum,
} from "../../atom/text";
import { AtomIcon, AtomIconNameEnum } from "../../atom/icon";
import { AtomMoney } from "@chatfood/design-system";
import { CurrencyCodeEnum } from "@/enum";

const css = bemBuilder("payment-tab");

export default defineComponent({
  name: "OrgPaymentTab",
  components: {
    AtomText,
    AtomIcon,
    AtomMoney,
  },
  props: {
    billTotal: {
      type: Number,
      required: true,
    },
    billCurrency: {
      type: String as PropType<CurrencyCodeEnum>,
      required: true,
    },
    countryCode: {
      type: String,
      required: true,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
    isSplitBill: {
      type: Boolean,
      default: false,
    },
    onBack: {
      type: Function as PropType<() => void>,
      default: () => null,
    },
  },
  setup() {
    return {
      css,
      AtomTextTypeEnum,
      AtomTextTagEnum,
      AtomTextColorEnum,
      AtomIconNameEnum,
    };
  },
});
