import { http } from "../http";
import { IRepoErrors } from "@/repo/errors";

export class RedeemSignupPointsError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: RedeemSignupPoints");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export const redeemSignupPoints = async (
  businessId: string,
  accessToken: string
): Promise<void> => {
  try {
    await http.post(
      "/storefront/reward/join",
      {
        business_id: businessId,
      },
      {
        headers: {
          Authorization: accessToken,
        },
      }
    );
  } catch (e: any) {
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new RedeemSignupPointsError(e);
  }
};
