
import { defineComponent, onMounted, ref } from "vue";
import { OrgFeedbackPopup } from "@chatfood/design-system";
import { bemBuilder } from "@chatfood/core-utils";
import { report } from "@chatfood/bug-reporter";
import { sendFeedbackRating } from "@/repo/feedback";
import mixpanel from "mixpanel-browser";
import { AtomText, AtomTextColorEnum, AtomTextTypeEnum } from "@/design-system";
import { SuccessToast, ErrorToast } from "@/design-system/atom/toast";

const css = bemBuilder("payment-success-rating-feedback");

const TOAST_DELAY = 200;
export default defineComponent({
  name: "PaymentSuccessRatingFeedback",
  components: {
    AtomText,
    OrgFeedbackPopup,
  },
  props: {
    locationId: {
      type: String,
      required: true,
    },
    paymentId: {
      type: String,
      required: true,
    },
    customerName: {
      type: String,
      default: null,
    },
    customerNumber: {
      type: String,
      default: null,
    },
    businessId: {
      type: String,
      required: true,
    },
    businessName: {
      type: String,
      required: true,
    },
    businessLogo: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const headers = [
      "How was your experience today?",
      "Tell us more about your experience",
    ];
    const messages = ["Really bad", "Bad", "Not good", "Good", "Amazing!"];

    const showLabel = ref(true);
    const showPopup = ref(true);
    const isLoading = ref(false);

    onMounted(() => {
      tracking("Feedback popup showed");
    });

    const onSave = async ({
      rating,
      comment,
    }: {
      rating: number;
      comment: string;
    }): Promise<void> => {
      isLoading.value = true;
      try {
        await sendFeedbackRating({
          rating,
          comment,
          businessId: props.businessId,
          locationId: props.locationId,
          customerName: props.customerName,
          customerNumber: props.customerNumber,
          paymentId: props.paymentId,
        });
        showLabel.value = false;
        tracking("Feedback saved", { rating, comment });
      } catch (e) {
        new ErrorToast().create({ text: "We were unable to save your review" });

        report(e);
      } finally {
        isLoading.value = false;
        showPopup.value = false;
      }

      setTimeout(() => {
        new SuccessToast().create({
          title: "Feedback sent",
          text: "Thank you for sharing your thoughts!",
        });
      }, TOAST_DELAY);
    };

    const onSelectedRating = (rating: number): void => {
      tracking("Rating selected", { rating });
    };

    const onClose = (): void => {
      showPopup.value = false;
      tracking("Feedback popup closed");
    };

    function tracking(
      title: string,
      additionalData: Record<string, any> = {}
    ): void {
      mixpanel.track(title, {
        location_id: props.locationId,
        business_id: props.businessId,
        business_name: props.businessName,
        event_category: "pay_at_table",
        ...additionalData,
      });
    }

    return {
      css,
      headers,
      messages,
      isLoading,
      showPopup,
      showLabel,
      onSave,
      onClose,
      onSelectedRating,
      AtomTextColorEnum,
      AtomTextTypeEnum,
    };
  },
});
