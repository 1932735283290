
import { bemBuilder } from "@chatfood/core-utils";
import { defineComponent } from "vue";
import { AtomText, AtomTextColorEnum, AtomTextTypeEnum } from "../text";

const css = bemBuilder("atom-form-control");

export default defineComponent({
  name: "AtomFormControl",
  components: {
    AtomText,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
    isOptional: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      css,
      AtomTextColorEnum,
      AtomTextTypeEnum,
    };
  },
});
