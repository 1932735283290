export enum InputStateEnum {
  CLEAN = "clean",
  INVALID = "invalid",
  VALID = "valid",
}

export type Country = {
  name: string;
  iso2: string;
  dialCode: string;
  priority: number;
  areaCodes: null;
  flagEmoji: string;
  flagEmojiUnicode: string;
};

export type PhoneNumber = {
  input: string;
  international: string;
  national: string;
  e164: string;
  rfc3966: string;
  significant: string;
};

export type IPhoneNumberObject = {
  number: PhoneNumber;
  regionCode: string;
  valid: boolean;
  possible: boolean;
  canBeInternationallyDialled: boolean;
  type: string;
  possibility: string;
  country: Country;
};
