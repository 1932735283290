import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomText = _resolveComponent("AtomText")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.css())
  }, [
    _createElementVNode("a", {
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('reloadBill')), ["prevent"]))
    }, [
      _createVNode(_component_AtomText, {
        type: _ctx.AtomTextTypeEnum.SMALL,
        color: _ctx.AtomTextColorEnum.BASE_40
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("view.bill.reload_action")), 1)
        ]),
        _: 1
      }, 8, ["type", "color"])
    ]),
    _createVNode(_component_AtomText, {
      type: _ctx.AtomTextTypeEnum.SMALL,
      color: _ctx.AtomTextColorEnum.BASE_40
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("view.bill.last_synced", [_ctx.lastSyncFromNow])), 1)
      ]),
      _: 1
    }, 8, ["type", "color"])
  ], 2))
}