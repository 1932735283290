
import { AtomMoney } from "@chatfood/design-system";
import { bemBuilder } from "@chatfood/core-utils";
import { defineComponent, PropType, computed, ref } from "vue";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextTagEnum,
  AtomTextColorEnum,
  MolCollapseIndicator,
} from "@/design-system";
import { IFetchPaymentsListResponse } from "@/repo/fetch-payments-list";
import { ReloadAction } from "@/view/bill/reload-action";
import { CurrencyCodeEnum } from "@/enum";

const css = bemBuilder("bill-summary");

type Currency = {
  currencyValue: number;
  currencyCode: CurrencyCodeEnum;
};

type IItems = {
  id: string;
  name: string;
  quantity: number;
  total: Currency;
};

export default defineComponent({
  name: "OrgBillSummary",
  components: {
    AtomText,
    AtomMoney,
    MolCollapseIndicator,
    ReloadAction,
  },
  props: {
    showReloadBillAction: {
      type: Boolean,
      required: true,
    },
    lastUpdated: {
      type: Date,
      default: undefined,
    },
    tableName: {
      type: String,
      required: true,
    },
    billItems: {
      type: Array as PropType<Array<IItems>>,
      required: true,
    },
    billTotal: {
      type: Number,
      required: true,
    },
    billCurrency: {
      type: String as PropType<CurrencyCodeEnum>,
      required: true,
    },
    countryCode: {
      type: String,
      required: true,
    },
    billRemaining: {
      type: Number,
      default: 0,
    },
    billPaymentsList: {
      type: Array as PropType<Array<IFetchPaymentsListResponse>>,
      default: () => [],
    },
    isOrderSettled: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const isCollapsed = ref(true);
    const isPayeeAvailable = computed(() => props.billPaymentsList.length > 0);
    return {
      css,
      isCollapsed,
      isPayeeAvailable,
      AtomTextTagEnum,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
