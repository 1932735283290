import { AtomToast } from "@chatfood/design-system";

export class SuccessToast extends AtomToast {
  constructor() {
    super({
      type: "success",
      title: "Success",
    });
  }
}

export class ErrorToast extends AtomToast {
  constructor() {
    super({
      type: "error",
      title: "Oops! Something went wrong!",
      text: "Please report the error to our support",
    });
  }
}
