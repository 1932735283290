
import { bemBuilder } from "@chatfood/core-utils";
import { defineComponent, PropType } from "vue";
import { AtomButtonSizeEnum } from "./size.enum";
import { AtomButtonTypeEnum } from "./type.enum";
import { AtomIcon, AtomIconNameEnum } from "../icon";

const css = bemBuilder("atom-button");

export default defineComponent({
  name: "AtomButton",
  components: {
    AtomIcon,
  },
  props: {
    type: {
      type: String as PropType<AtomButtonTypeEnum>,
      default: AtomButtonTypeEnum.PRIMARY,
    },
    size: {
      type: String as PropType<AtomButtonSizeEnum>,
      default: AtomButtonSizeEnum.MEDIUM,
    },
    icon: {
      type: String as PropType<AtomIconNameEnum>,
      default: null,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      required: true,
    },
  },
  setup() {
    return {
      css,
      AtomButtonSizeEnum,
      AtomButtonTypeEnum,
    };
  },
});
