import { http } from "@/repo/http";
import { AxiosResponse } from "axios";
import { IRepoErrors } from "@/repo/errors";
import { CurrencyCodeEnum } from "@/enum/currency-code.enum";

type ICheckoutFetchBillPaymentBody = {
  businessId: string;
  billId: string;
  paymentId: string;
};

export type ICheckoutFetchBillPaymentResponse = {
  id: string;
  url: string;
  amount: number;
  currency: CurrencyCodeEnum;
  status:
    | "created"
    | "pending_authentication"
    | "successful"
    | "failed"
    | "voided";
  reason: "bill_already_paid" | "unexpected_error" | null;
  amountCaptured: number;
  tipAmount: number;
};

export class CheckoutFetchBillPaymentError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repository: CheckoutFetchBillPayment");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function checkoutFetchBillPayment(
  request: ICheckoutFetchBillPaymentBody
): Promise<ICheckoutFetchBillPaymentResponse> {
  let response: AxiosResponse;

  try {
    response = await http.get(
      `/pat/businesses/${request.businessId}/bills/${request.billId}/payments/${request.paymentId}`
    );

    return {
      id: response.data.data.id,
      url: response.data.data.url,
      amount: response.data.data.amount,
      currency: response.data.data.currency,
      status: response.data.data.status,
      reason: response.data.data.reason,
      amountCaptured: response.data.data.amount_captured,
      tipAmount: response.data.data.tip_amount,
    };
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new CheckoutFetchBillPaymentError(error);
  }
}
