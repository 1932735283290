import { countries } from "./countries";

export type ICountry = {
  name: string;
  iso2: string;
  dialCode: string;
  priority: number;
  areaCodes: Array<string> | null;
  flagEmoji: string;
  flagEmojiUnicode: string;
};

export const countriesIso2 = countries.map((country) => country.iso2);

export type ICountryNameIso2 = typeof countriesIso2[number];

export type IGroupedCountries = Record<
  string,
  {
    name: string;
    children: Array<ICountry>;
  }
>;

export function findCountry(countryIso2: string): ICountry | undefined {
  return countries.find((country) => country.iso2 === countryIso2);
}
