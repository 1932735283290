import { AxiosResponse } from "axios";
import { IRepoErrors } from "../errors";
import { http } from "../http";
import { CurrencyCodeEnum } from "@/enum";

type Currency = {
  currencyValue: number;
  currencyCode: CurrencyCodeEnum;
};

export type IFetchPaymentsListResponse = Array<{
  customerName: string | null;
  amountPaid: Currency;
}>;

export class FetchPaymentsListError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchPaymentsList");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchPaymentsList(
  billId: string,
  businessId: string
): Promise<IFetchPaymentsListResponse> {
  let response: AxiosResponse;

  try {
    response = await http.get(
      `/pat/businesses/${businessId}/bills/${billId}/payments`
    );
  } catch (e: any) {
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchPaymentsListError(e);
  }

  return response.data.map((payment: any) => {
    return {
      customerName: payment.customer_name ?? "",
      amountPaid: {
        currencyValue: payment.amount_paid.value,
        currencyCode: payment.amount_paid.currency,
      },
    };
  });
}
