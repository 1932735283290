import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomIcon = _resolveComponent("AtomIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.css(''))
  }, [
    (_openBlock(), _createBlock(_component_AtomIcon, {
      class: _normalizeClass(_ctx.css('svg')),
      key: _ctx.nameIcon,
      size: 11,
      name: _ctx.nameIcon
    }, null, 8, ["class", "name"]))
  ], 2))
}