
import { bemBuilder } from "@chatfood/core-utils";
import { defineComponent, PropType } from "vue";
import { AtomIcon, AtomIconNameEnum } from "../../atom/icon";

const css = bemBuilder("mol-icon-button");

export default defineComponent({
  name: "MolIconButton",
  components: {
    AtomIcon,
  },
  props: {
    iconName: {
      type: String as PropType<AtomIconNameEnum>,
      required: true,
    },
    onClick: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup() {
    return {
      css,
    };
  },
});
