import { http } from "@/repo/http";
import { AxiosResponse } from "axios";
import { IRepoErrors } from "@/repo/errors";
import { TipsEnum } from "@/enum";

type ICheckoutPayBillBody = {
  businessId: string;
  billId: string;
  paymentDetails: IBillPaymentDetails;
  amount: number;
  customerName?: string;
  phoneNumber?: string;
  tipPercentage?: TipsEnum;
  useRewardPoints?: boolean;
};

export type IBillPaymentDetails = {
  type: IBillPaymentType;
  cardId?: string;
  cardToken?: string;
  brand?: string;
  last4?: string;
  expiryYear?: string;
  expiryMonth?: string;
};

export enum IBillPaymentType {
  CARD_ONLINE = "card_online",
  APPLE_PAY = "apple_pay",
  SKIPCASH = "skipcash",
}

export class CheckoutPayBillError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repository: CheckoutPayBill");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function checkoutPayBill(
  request: ICheckoutPayBillBody
): Promise<string> {
  let response: AxiosResponse;

  try {
    response = await http.post(
      `/pat/v2/businesses/${request.businessId}/bills/${request.billId}/checkout`,
      {
        amount: request.amount,
        customer_name: request.customerName || "",
        tip_percentage: request.tipPercentage || TipsEnum.NONE,
        use_reward_points: request.useRewardPoints || false,
        payment_method: {
          type: request.paymentDetails.type,
          ...(request.paymentDetails.type === "skipcash"
            ? {}
            : {
                credit_card: {
                  card_id: request.paymentDetails.cardId,
                  card_token: request.paymentDetails.cardToken,
                  brand: request.paymentDetails.brand,
                  last_4: request.paymentDetails.last4,
                  expiry_year: request.paymentDetails.expiryYear,
                  expiry_month: request.paymentDetails.expiryMonth,
                },
              }),
        },
        ...(request.phoneNumber ? { phone_number: request.phoneNumber } : {}),
      },
      {
        headers: {
          Authorization: window.localStorage.getItem("auth._token.local") || "",
        },
      }
    );

    return response.data.bill_payment_id;
  } catch (e: any) {
    let error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    const response = e.response || {};
    const errorCode = response.data?.error?.code;
    const validationError = 422;

    if (response.status === validationError) {
      error = { errorCode };
    }

    throw new CheckoutPayBillError(error);
  }
}
