import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomText = _resolveComponent("AtomText")!
  const _component_OrgFeedbackPopup = _resolveComponent("OrgFeedbackPopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showLabel && !_ctx.showPopup)
      ? (_openBlock(), _createBlock(_component_AtomText, {
          key: 0,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showPopup = true)),
          type: _ctx.AtomTextTypeEnum.BODY_BOLD,
          color: _ctx.AtomTextColorEnum.PRIMARY,
          class: _normalizeClass(_ctx.css('cta'))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Leave Feedback ")
          ]),
          _: 1
        }, 8, ["type", "color", "class"]))
      : _createCommentVNode("", true),
    _createVNode(_component_OrgFeedbackPopup, {
      "show-popup": _ctx.showPopup,
      "business-name": _ctx.businessName,
      "business-logo": _ctx.businessLogo,
      headers: _ctx.headers,
      subheader: "Please share your thoughts",
      hintMessage: "characters left",
      ctaLabel: "Send feedback",
      ratingMessages: _ctx.messages,
      isLoading: _ctx.isLoading,
      onSave: _ctx.onSave,
      onClose: _ctx.onClose,
      onSelectedRating: _ctx.onSelectedRating
    }, null, 8, ["show-popup", "business-name", "business-logo", "headers", "ratingMessages", "isLoading", "onSave", "onClose", "onSelectedRating"])
  ], 64))
}