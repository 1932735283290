
import { bemBuilder } from "@chatfood/core-utils";
import { defineComponent, PropType, ref, computed, watch } from "vue";
import { AtomText, AtomTextTypeEnum, AtomTextColorEnum } from "../../atom/text";
import { AtomIcon, AtomIconNameEnum } from "../../atom/icon";
import {
  AtomButton,
  AtomButtonSizeEnum,
  AtomButtonTypeEnum,
} from "../../atom/button";
import { MolPaymentBox } from "../../mol/payment-box";
import { PaymentMethodsEnum } from "@/enum";

const css = bemBuilder("org-payment-method");

type PaymentMethod = {
  id: string;
  method: PaymentMethodsEnum;
  label: string;
  last4?: string;
  selected: boolean;
};

export default defineComponent({
  name: "OrgPaymentMethod",
  components: {
    AtomText,
    AtomIcon,
    AtomButton,
    MolPaymentBox,
  },
  props: {
    paymentMethodList: {
      type: Array as PropType<Array<PaymentMethod>>,
      required: true,
    },
    onChangeMethod: {
      type: Function as PropType<(value: PaymentMethod) => void>,
      required: true,
    },
    selectedPaymentId: {
      type: String,
      default: "",
    },
    addCard: {
      type: Function as PropType<() => void>,
      required: true,
    },
    onBack: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const selectedItemId = ref<string>("");

    watch(
      () => props.selectedPaymentId,
      () => {
        selectedItemId.value = props.selectedPaymentId || "";
      },
      { immediate: true }
    );

    const selectedItem = computed(() =>
      props.paymentMethodList.find(
        (payment) => payment.id === selectedItemId.value
      )
    );

    return {
      css,
      selectedItemId,
      selectedItem,
      PaymentMethodsEnum,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomIconNameEnum,
      AtomButtonSizeEnum,
      AtomButtonTypeEnum,
    };
  },
});
