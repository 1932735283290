import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomGraphic = _resolveComponent("AtomGraphic")!
  const _component_AtomText = _resolveComponent("AtomText")!
  const _component_AtomButton = _resolveComponent("AtomButton")!
  const _component_AtomIcon = _resolveComponent("AtomIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.css())
  }, [
    _createVNode(_component_AtomGraphic, {
      name: "error",
      class: _normalizeClass(_ctx.css('icon')),
      size: 56
    }, null, 8, ["class"]),
    _createVNode(_component_AtomText, {
      type: _ctx.AtomTextTypeEnum.H2,
      class: _normalizeClass(_ctx.css('title'))
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Payment unsuccessful ")
      ]),
      _: 1
    }, 8, ["type", "class"]),
    _createVNode(_component_AtomText, {
      color: _ctx.AtomTextColorEnum.BASE_50,
      class: _normalizeClass(_ctx.css('description'))
    }, {
      default: _withCtx(() => [
        _createTextVNode(" There was a problem with your payment. Please try again. ")
      ]),
      _: 1
    }, 8, ["color", "class"]),
    _createVNode(_component_AtomButton, { onClick: _ctx.reloadPage }, {
      default: _withCtx(() => [
        _createTextVNode(" Try payment again")
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createElementVNode("a", {
      href: "#",
      class: _normalizeClass(_ctx.css('link'))
    }, [
      _createVNode(_component_AtomIcon, {
        name: _ctx.AtomIconNameEnum.HELP,
        color: _ctx.AtomTextColorEnum.BASE_50
      }, null, 8, ["name", "color"]),
      _createVNode(_component_AtomText, {
        color: _ctx.AtomTextColorEnum.BASE_50
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Need help? ")
        ]),
        _: 1
      }, 8, ["color"])
    ], 2)
  ], 2))
}