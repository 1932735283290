
/* global ApplePaySession */
import {
  bemBuilder,
  toMoney,
  getPhoneNumberExample,
  isValidMobileNumber,
  parseInputNumber,
} from "@chatfood/core-utils";
import { defineComponent, ref, onMounted, computed, PropType } from "vue";
import { t } from "@/i18n";
import { canUseApplePay } from "@/util/apple-pay";
import { CurrencyCodeEnum } from "@/enum";
import { ICheckoutToken, IPaymentData, IRequest } from "./types";
import {
  exchangeToken,
  getApplePayValidateSession,
  IGetApplePayValidateSession,
} from "@/repo/payments";

const css = bemBuilder("apple-pay-button");

export default defineComponent({
  name: "ApplePayButton",
  props: {
    businessId: {
      type: String,
      required: true,
    },
    businessName: {
      type: String,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    countryCode: {
      type: String,
      default: "AE",
    },
    currencyCode: {
      type: String as PropType<CurrencyCodeEnum>,
      default: CurrencyCodeEnum.AED,
    },
    locale: {
      type: String,
      default: "en-US",
    },
    capturePhoneNumber: {
      type: Boolean,
      default: false,
    },
    paymentLabel: {
      type: String,
      default: "Restaurant (via Chatfood)",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    onValidation: {
      type: Function as PropType<() => boolean>,
      default: () => true,
    },
  },
  emits: ["on-success", "on-fail"],
  setup(props, { emit }) {
    const applePaySession = ref();
    const showButton = ref(false);
    const isCanceled = ref(false);
    const hasActiveSession = ref(false);
    const applePayButton = ref<HTMLDivElement>();

    const formattedPaymentLabel = computed(
      () => `ChatFood (for ${props.businessName})`
    );

    onMounted(() => {
      showButton.value = canUseApplePay();
    });

    const resetActiveSessionFlag = (): void => {
      hasActiveSession.value = false;
    };

    const report = (e: any, message: string): void => {
      console.error(e, message);
    };

    async function validateSession(
      applePaySessionUrl: string
    ): Promise<IGetApplePayValidateSession | undefined> {
      let session;
      try {
        session = await getApplePayValidateSession({
          applePaySessionUrl,
        });
      } catch (e) {
        resetActiveSessionFlag();
        report(e, "validateSession");
      }
      return session;
    }

    async function exchangeApplePayToken(
      paymentData: IPaymentData
    ): Promise<{ approved: boolean; checkoutToken?: ICheckoutToken }> {
      let token;

      try {
        const { checkoutToken } = await exchangeToken({
          businessId: props.businessId,
          tokenData: {
            type: "apple_pay",
            paymentData,
          },
        });
        token = {
          approved: Boolean(checkoutToken),
          checkoutToken,
        };
      } catch (e) {
        resetActiveSessionFlag();
        report(e, "exchangeApplePayToken");

        return {
          approved: false,
        };
      }
      return token;
    }

    const onValidateMerchant = async (event: {
      validationURL: string;
    }): Promise<void> => {
      const merchantSession = await validateSession(event.validationURL);

      if (isCanceled.value) {
        return;
      }

      try {
        applePaySession.value.completeMerchantValidation(merchantSession);
      } catch (e) {
        resetActiveSessionFlag();
        report(e, "onValidateMerchant");

        applePaySession.value.completePayment(
          applePaySession.value.STATUS_FAILURE
        );

        emit("on-fail");
      }
    };

    const onPaymentAuthorized = async (event: any): Promise<void> => {
      let validPhoneNumber = "";

      if (props.capturePhoneNumber) {
        const rawPhoneNumber: string =
          event.payment.shippingContact.phoneNumber;
        const parsedNumber = parseInputNumber(rawPhoneNumber);

        if (!isValidMobileNumber(parsedNumber)) {
          const result: Record<string, any> = {};
          const example = getPhoneNumberExample(
            props.countryCode,
            "international"
          );
          result.status = applePaySession.value.STATUS_FAILURE;
          result.errors = [
            new (window as any).ApplePayError(
              "shippingContactInvalid",
              "phoneNumber",
              t("view.bill.error.apple_pay_invalid_number", { example })
            ),
          ];

          applePaySession.value.completePayment(result);
          return;
        }

        validPhoneNumber = parsedNumber.getNumber();
      }

      const paymentData = event.payment?.token?.paymentData || {};
      const response = await exchangeApplePayToken(paymentData);

      if (response.approved) {
        applePaySession.value.completePayment(
          applePaySession.value.STATUS_SUCCESS
        );

        const { type, token, brand, last4, expiryMonth, expiryYear } =
          response.checkoutToken || {};

        const checkoutToken = {
          type,
          token,
          phoneNumber: validPhoneNumber,
          card: {
            last4,
            brand,
            expiryYear: `${expiryYear}`,
            // eslint-disable-next-line no-magic-numbers
            expiryMonth: `0${expiryMonth}`.slice(-2),
          },
        };

        emit("on-success", checkoutToken);
      }

      if (!response.approved) {
        applePaySession.value.completePayment(
          applePaySession.value.STATUS_FAILURE
        );
        emit("on-fail");
      }

      resetActiveSessionFlag();
    };

    const formattedTotal = computed(() => {
      const absAmount = Math.abs(props.total) ?? 0;
      return toMoney(absAmount, props.currencyCode, {
        locale: props.locale,
        inUnitOutput: true,
      });
    });

    function triggerApplePay(): void {
      if (!props.onValidation() || hasActiveSession.value) {
        return;
      }

      hasActiveSession.value = true;
      isCanceled.value = false;

      const request: IRequest = {
        countryCode: props.countryCode,
        currencyCode: props.currencyCode,
        supportedNetworks: ["visa", "mastercard", "amex", "discover"],
        merchantCapabilities: ["supports3DS"], // 'supports3DS' - this value must be  always supplied
        total: {
          label: formattedPaymentLabel.value,
          amount: formattedTotal.value,
        },
      };

      if (props.capturePhoneNumber) {
        request.requiredShippingContactFields = ["phone"];
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      // eslint-disable-next-line no-magic-numbers
      applePaySession.value = new ApplePaySession(6, request);

      const onCancel = (): void => {
        isCanceled.value = true;
        resetActiveSessionFlag();
      };

      applePaySession.value.onvalidatemerchant = onValidateMerchant;
      applePaySession.value.onpaymentauthorized = onPaymentAuthorized;
      applePaySession.value.oncancel = onCancel;
      applePaySession.value.begin();
    }

    return {
      css,
      triggerApplePay,
      applePayButton,
      showButton,
    };
  },
});
