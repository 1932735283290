import { IRepoErrors } from "../errors";
import { http } from "../http";

export type IAuthRequestOtpBody = {
  countryCode: string;
  phoneNumber: string;
  businessId: string;
};

export class AuthRequestOtpError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: AuthRequestOtp");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function authRequestOtp(
  request: IAuthRequestOtpBody
): Promise<void> {
  try {
    await http.post("/storefront/auth/request-otp", {
      country_code: request.countryCode,
      phone_number: request.phoneNumber,
      messaging_channel: "sms",
      business_id: request.businessId,
    });
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new AuthRequestOtpError(error);
  }
}
