
import { defineComponent, defineAsyncComponent } from "vue";

export default defineComponent({
  name: "AtomIcon",
  props: {
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "currentColor",
    },
    size: {
      type: Number,
      default: 16,
    },
  },
  computed: {
    iconSize(): { width: string; height: string } {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
      };
    },
    styles(): { width: string; height: string; color?: string } {
      const color = this.color === "currentColor" ? {} : { color: this.color };

      return {
        ...color,
        ...this.iconSize,
      };
    },
    icon() {
      // template literals doesn't work well with dynamic import on jest
      return defineAsyncComponent(() => import(`./assets/${this.name}.svg`));
    },
  },
});
