import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomGraphic = _resolveComponent("AtomGraphic")!
  const _component_AtomText = _resolveComponent("AtomText")!
  const _component_OrgPaymentDetails = _resolveComponent("OrgPaymentDetails")!
  const _component_PaymentSuccessLoyaltyProgram = _resolveComponent("PaymentSuccessLoyaltyProgram")!
  const _component_PaymentSuccessRatingFeedback = _resolveComponent("PaymentSuccessRatingFeedback")!
  const _component_MolPoweredByChatfood = _resolveComponent("MolPoweredByChatfood")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.css(), _ctx.css('status', 'inner'), { [_ctx.css('', 'blink')]: _ctx.billBlink }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.css('top'))
    }, [
      (_openBlock(), _createBlock(_component_AtomGraphic, {
        class: _normalizeClass(_ctx.css('icon')),
        key: _ctx.emojiName,
        name: _ctx.emojiName,
        size: 100
      }, null, 8, ["class", "name"])),
      _createVNode(_component_AtomText, {
        type: _ctx.AtomTextTypeEnum.H1,
        class: _normalizeClass(_ctx.css('title'))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.title), 1)
        ]),
        _: 1
      }, 8, ["type", "class"]),
      _createVNode(_component_AtomText, {
        color: _ctx.AtomTextColorEnum.BASE_50,
        class: _normalizeClass(_ctx.css('description'))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.subtitle), 1)
        ]),
        _: 1
      }, 8, ["color", "class"]),
      (_ctx.table.bill)
        ? (_openBlock(), _createBlock(_component_OrgPaymentDetails, {
            key: 0,
            countryCode: _ctx.business.country,
            currency: _ctx.currencyCode,
            totalPaid: _ctx.amountPaid,
            tipsAmount: _ctx.tipsAmount,
            tipsPercentage: _ctx.tipsPercentage,
            remainingAmount: _ctx.billRemaining,
            onClick: _ctx.goBackToBill
          }, null, 8, ["countryCode", "currency", "totalPaid", "tipsAmount", "tipsPercentage", "remainingAmount", "onClick"]))
        : _createCommentVNode("", true),
      (_ctx.showLoyaltyProgram)
        ? (_openBlock(), _createBlock(_component_PaymentSuccessLoyaltyProgram, {
            key: 1,
            class: _normalizeClass(_ctx.css('loyalty')),
            "total-order": _ctx.loyaltyProgram.amount,
            "loyalty-points": _ctx.loyaltyProgram.loyaltyPoints,
            "is-burned-points": _ctx.loyaltyProgram.isBurnedPoints,
            "maximum-redeemed-order-amount": 
          _ctx.loyaltyProgram.maximumRedeemedOrderAmount
        ,
            "maximum-redeemed-order-percentage": 
          _ctx.loyaltyProgram.maximumRedeemedOrderPercentage
        ,
            "order-amount-percentage-worth": 
          _ctx.loyaltyProgram.orderAmountPercentageWorth
        ,
            locale: _ctx.business.country,
            currency: _ctx.currencyCode,
            "business-color": _ctx.businessColor
          }, null, 8, ["class", "total-order", "loyalty-points", "is-burned-points", "maximum-redeemed-order-amount", "maximum-redeemed-order-percentage", "order-amount-percentage-worth", "locale", "currency", "business-color"]))
        : _createCommentVNode("", true),
      (_ctx.table.bill && _ctx.business)
        ? (_openBlock(), _createBlock(_component_PaymentSuccessRatingFeedback, {
            key: 2,
            "location-id": _ctx.locationId,
            "payment-id": _ctx.billId,
            "customer-name": _ctx.customerName,
            "customer-number": _ctx.customerPhoneNumber,
            "business-logo": _ctx.business.logo,
            "business-name": _ctx.business.name,
            "business-id": _ctx.business.id
          }, null, 8, ["location-id", "payment-id", "customer-name", "customer-number", "business-logo", "business-name", "business-id"]))
        : _createCommentVNode("", true)
    ], 2),
    _createVNode(_component_MolPoweredByChatfood, { business: _ctx.businessName }, null, 8, ["business"])
  ], 2))
}