import { AxiosResponse } from "axios";
import { http } from "@/repo/http";
import { IRepoErrors } from "@/repo/errors";

export class GetLocationSettingsError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: getLocationSettings");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function getLocationSettings(
  locationId: string
): Promise<boolean> {
  let response: AxiosResponse;
  try {
    response = await http.get(`pat/locations/${locationId}/settings`);
  } catch (e: any) {
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new GetLocationSettingsError(e);
  }

  return Boolean(response?.data?.refresh_bill);
}
