import { http } from "../http";
import { IRepoErrors } from "@/repo/errors";

type ISendFeedbackRatingRequest = {
  rating: number;
  comment: string;
  locationId: string;
  paymentId: string;
  businessId: string;
  customerName?: string;
  customerNumber?: string;
};

export class SendFeedbackRatingError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: SendFeedbackRating");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export const sendFeedbackRating = async ({
  locationId,
  customerName,
  customerNumber,
  paymentId,
  rating,
  comment,
  businessId,
}: ISendFeedbackRatingRequest): Promise<void> => {
  try {
    await http.post("/feedback/pat/rating", {
      rating,
      comment,
      business_id: businessId,
      premise_id: locationId,
      product_id: paymentId,
      customer: {
        name: customerName || null,
        phone_number: customerNumber || null,
      },
    });
  } catch (e: any) {
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new SendFeedbackRatingError(e);
  }
};
