
import { bemBuilder } from "@chatfood/core-utils";
import { defineComponent } from "vue";
import { AtomGraphic, AtomText, AtomTextColorEnum } from "@/design-system";

const css = bemBuilder("mol-loading-with-logo");

export default defineComponent({
  name: "MolLoadingWithLogo",
  components: { AtomGraphic, AtomText },
  props: {
    title: {
      type: String,
      default: "Fetching your bill...",
    },
  },
  setup() {
    return {
      css,
      AtomTextColorEnum,
    };
  },
});
