import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomGraphic = _resolveComponent("AtomGraphic")!
  const _component_AtomText = _resolveComponent("AtomText")!
  const _component_AtomButton = _resolveComponent("AtomButton")!
  const _component_MolPoweredByChatfood = _resolveComponent("MolPoweredByChatfood")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.css(), _ctx.css('status', 'inner')])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.css('top'))
    }, [
      _createVNode(_component_AtomGraphic, {
        class: _normalizeClass(_ctx.css('icon')),
        name: "shocked",
        size: 100
      }, null, 8, ["class"]),
      _createVNode(_component_AtomText, {
        type: _ctx.AtomTextTypeEnum.H1,
        class: _normalizeClass(_ctx.css('title'))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("view.no_bill.title")), 1)
        ]),
        _: 1
      }, 8, ["type", "class"]),
      _createVNode(_component_AtomText, {
        color: _ctx.AtomTextColorEnum.BASE_50,
        class: _normalizeClass(_ctx.css('description'))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("view.no_bill.subtitle")), 1)
        ]),
        _: 1
      }, 8, ["color", "class"]),
      _createVNode(_component_AtomButton, {
        onClick: _ctx.reloadPage,
        size: _ctx.AtomButtonSizeEnum.SMALL,
        type: _ctx.AtomButtonTypeEnum.SECONDARY,
        icon: _ctx.AtomIconNameEnum.REFRESH
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("view.no_bill.refresh")), 1)
        ]),
        _: 1
      }, 8, ["onClick", "size", "type", "icon"])
    ], 2),
    _createVNode(_component_MolPoweredByChatfood, { business: _ctx.businessName }, null, 8, ["business"])
  ], 2))
}