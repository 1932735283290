
import { AtomMoney } from "@chatfood/design-system";
import { bemBuilder } from "@chatfood/core-utils";
import { defineComponent, PropType, computed } from "vue";
import { AtomText, AtomTextTypeEnum, AtomTextColorEnum } from "../../atom/text";
import { CurrencyCodeEnum } from "@/enum";

const css = bemBuilder("org-payment-details");

export default defineComponent({
  name: "OrgPaymentDetails",
  components: {
    AtomText,
    AtomMoney,
  },
  props: {
    currency: {
      type: String as PropType<CurrencyCodeEnum>,
      default: CurrencyCodeEnum.AED,
    },
    countryCode: {
      type: String,
      default: "AE",
    },
    totalPaid: {
      type: Number,
      required: true,
    },
    tipsAmount: {
      type: Number,
      default: 0,
    },
    tipsPercentage: {
      type: Number,
      default: 0,
    },
    loyaltyAmount: {
      type: Number,
      default: 0,
    },
    remainingAmount: {
      type: Number,
      default: 0,
    },
    onClick: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const billTotal = computed(
      () => props.totalPaid + props.tipsAmount - props.loyaltyAmount
    );

    const isBillSettled = computed(() => props.remainingAmount === 0);

    return {
      css,
      billTotal,
      isBillSettled,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
