import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomIcon = _resolveComponent("AtomIcon")!
  const _component_AtomGraphic = _resolveComponent("AtomGraphic")!
  const _component_AtomText = _resolveComponent("AtomText")!

  return (_openBlock(), _createElementBlock("a", {
    class: _normalizeClass(_ctx.css()),
    href: "#",
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.css('method'))
    }, [
      (_ctx.brandIcon === 'card')
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.css('card'))
          }, [
            (_openBlock(), _createBlock(_component_AtomIcon, {
              name: _ctx.AtomIconNameEnum.CARD,
              key: _ctx.brandIcon,
              size: _ctx.paymentImageSize
            }, null, 8, ["name", "size"]))
          ], 2))
        : (_openBlock(), _createBlock(_component_AtomGraphic, {
            key: _ctx.brandIcon,
            name: _ctx.brandIcon,
            width: _ctx.paymentImageSize
          }, null, 8, ["name", "width"])),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.css('method', 'type'))
      }, [
        _createVNode(_component_AtomText, {
          type: _ctx.AtomTextTypeEnum.BODY_BOLD
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.paymentLabel), 1)
          ]),
          _: 1
        }, 8, ["type"]),
        (_ctx.last4)
          ? (_openBlock(), _createBlock(_component_AtomText, { key: 0 }, {
              default: _withCtx(() => [
                _createTextVNode(" ("),
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.css('method', 'mask'))
                }, " ···· ", 2),
                _createTextVNode(" " + _toDisplayString(_ctx.last4) + ") ", 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ], 2)
    ], 2),
    (_ctx.showChange)
      ? (_openBlock(), _createBlock(_component_AtomText, {
          key: 0,
          class: _normalizeClass(_ctx.css('change')),
          color: _ctx.AtomTextColorEnum.PRIMARY,
          type: _ctx.AtomTextTypeEnum.SMALL
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Change ")
          ]),
          _: 1
        }, 8, ["class", "color", "type"]))
      : _createCommentVNode("", true),
    (_ctx.showCheckbox)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.css('checkbox'))
        }, null, 2))
      : _createCommentVNode("", true)
  ], 2))
}