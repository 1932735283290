
import { defineComponent } from "vue";
import { bemBuilder } from "@chatfood/core-utils";

const css = bemBuilder("atom-modal");

export default defineComponent({
  name: "AtomModal",
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    hideBackdrop: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return { css };
  },
});
