
import { bemBuilder } from "@chatfood/core-utils";
import { defineComponent, PropType } from "vue";
import {
  AtomButton,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomIcon,
  AtomIconNameEnum,
  AtomGraphic,
} from "@/design-system";
import { IFetchBusinessBySlugResponse } from "@/repo/fetch-business-by-slug";
import { ViewsEnum } from "@/view/views.enum";
import { useRouter } from "vue-router";

const css = bemBuilder("bill-status-failed");

export default defineComponent({
  name: "ViewPaymentFail",
  components: {
    AtomButton,
    AtomText,
    AtomGraphic,
    AtomIcon,
  },
  props: {
    business: {
      type: Object as PropType<IFetchBusinessBySlugResponse>,
      required: true,
    },
    tableId: {
      type: String,
      required: true,
    },
    changeViewTo: {
      type: Function as PropType<(view: ViewsEnum) => void>,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();

    function reloadPage(): void {
      router.replace({
        name: "PAT",
        params: {
          businessId: props.business.id,
          tableId: props.tableId,
        },
      });

      props.changeViewTo(ViewsEnum.BILL);
    }

    return {
      css,
      reloadPage,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomIconNameEnum,
    };
  },
});
