
import { t } from "@/i18n";
import { bemBuilder, toMoney } from "@chatfood/core-utils";
import { OrgTipPicker } from "@chatfood/design-system";
import {
  computed,
  defineComponent,
  onUnmounted,
  PropType,
  ref,
  watch,
} from "vue";
import {
  AtomLogo,
  AtomButton,
  AtomButtonSizeEnum,
  AtomButtonTypeEnum,
  AtomDrawer,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomTextTagEnum,
  AtomModal,
  MolPaymentBox,
  MolSplitButton,
  MolLoadingWithLogo,
  OrgPaymentMethod,
  OrgBillSummary,
  OrgPaymentTab,
  OrgSplitBillForm,
  OrgNotification,
} from "@/design-system";
import { BillCheckoutForm, ICardDetails } from "./checkout-form";
import {
  fetchBillByTableId,
  IFetchBillByTableIdResponse,
} from "@/repo/fetch-bill-by-table-id";
import { PaymentMethodsEnum, TipsEnum } from "@/enum";
import { ViewsEnum } from "../views.enum";
import { fetchUserCreditCards } from "@/repo/fetch-user-credit-cards";
import { getLocationSettings } from "@/repo/get-location-settings";
import { posRefreshBill } from "@/repo/pos-refresh-bill";
import { IFetchBusinessBySlugResponse } from "@/repo/fetch-business-by-slug";
import { checkoutPayBill, IBillPaymentType } from "@/repo/checkout/pay-bill";
import { checkoutFetchBillPayment } from "@/repo/checkout/fetch-bill-payment";
import {
  fetchPaymentsList,
  IFetchPaymentsListResponse,
} from "@/repo/fetch-payments-list";
import { BillWaiting } from "./waiting";
import { canUseApplePay } from "@/util/apple-pay";
import { ApplePayButton } from "@/modules/checkout/apple-pay-button";
import { getPaymentGateway } from "@/repo/payments";
import { GatewayTypeEnum } from "@/repo/enums";
import mixpanel from "mixpanel-browser";
import { fetchLoyaltyPoints } from "@/repo/fetch-loyalty-points";
import { BillLoyaltyProgram } from "./loyalty-program";
import {
  getRedeemableLoyaltyPoints,
  saveLoyaltyPoints,
} from "@/util/loyalty-points";
import { report } from "@chatfood/bug-reporter";
import { getStorageItem, setStorageItem } from "@/util/local-storage";
import { IPrice } from "@chatfood/design-system/dist/types";

const css = bemBuilder("view-bill");

type PaymentMethod = {
  id?: string;
  method: string;
  label?: string;
  last4?: string;
  selected?: boolean;
};

type NewCard = {
  cardToken: string;
  brand: string;
  last4: string;
  expiryYear: string;
  expiryMonth: string;
};

export default defineComponent({
  name: "ViewBill",
  components: {
    ApplePayButton,
    AtomLogo,
    AtomButton,
    AtomDrawer,
    AtomText,
    AtomModal,
    MolPaymentBox,
    MolSplitButton,
    MolLoadingWithLogo,
    OrgPaymentMethod,
    OrgBillSummary,
    OrgPaymentTab,
    OrgSplitBillForm,
    OrgNotification,
    BillCheckoutForm,
    BillWaiting,
    BillLoyaltyProgram,
    OrgTipPicker,
  },
  props: {
    tableId: {
      type: String,
      required: true,
    },
    business: {
      type: Object as PropType<IFetchBusinessBySlugResponse>,
      required: true,
    },
    isLoginFlow: {
      type: Boolean,
      default: false,
    },
    checkoutRedirect: {
      type: Boolean,
      required: true,
    },
    changeViewTo: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    document.body.style.backgroundColor = "#e0e5f2";

    const table = ref<IFetchBillByTableIdResponse | Record<string, never>>({});
    const loadingTable = ref(true);
    const loadingPaymentMethods = ref(true);
    const loadingReloadBill = ref(false);
    const tipShaker = ref(false);
    const billBlink = ref(false);
    const tipChosen = ref(false);
    const tipsAmount = ref(0);
    const tipsPercentage = ref<TipsEnum>();
    const showPaymentMethod = ref(false);
    const paymentMethods = ref<Array<PaymentMethod>>([]);
    const disablePaymentButton = ref(false);
    const showCheckoutForm = ref(false);
    const displayBackButtonPayment = ref(false);
    const contactingBank = ref(false);
    const selectedCard = ref<PaymentMethod | null>(null);
    const newCard = ref<NewCard | null>(null);
    const accessToken = window.localStorage.getItem("auth._token.local") || "";
    const isLoggedIn = ref(Boolean(accessToken));
    const isValidGatewayCondition = ref(false);
    const shareAmount = ref(0);
    const shareName = ref("");
    const amountPaidValue = ref(0);
    const showSplitBillForm = ref(false);
    const billPaymentsList = ref<IFetchPaymentsListResponse>([]);
    const loyaltyPoints = ref(0);
    const hasApplePay = computed(
      () => isValidGatewayCondition.value && canUseApplePay()
    );
    const checkoutToken = ref("");
    const lastUpdated = ref(new Date());
    const hasSkipcash = ref(false);

    const hasStartedSync = ref(false);
    const isLoading = computed(
      (): boolean =>
        !(!loadingTable.value && !loadingPaymentMethods.value) ||
        loadingReloadBill.value
    );

    const applePayMethod = {
      id: "apple-pay",
      method: PaymentMethodsEnum.APPLE_PAY,
      label: "Apple Pay",
      last4: "",
      selected: true,
    };
    let paymentType: IBillPaymentType | null = null;

    const currencyCode = computed(
      () => table.value.bill?.remainingBalance?.currencyCode
    );

    const billRemaining = computed(() => {
      if (!table.value.bill?.remainingBalance?.currencyValue) {
        return 0;
      }
      return table.value.bill.remainingBalance.currencyValue;
    });

    const isOrderSettled = computed(() => billRemaining.value === 0);

    const billTotal = computed(() => {
      if (
        !table.value.bill?.remainingBalance?.currencyValue &&
        !table.value.bill?.amountPaid?.currencyValue
      ) {
        return 0;
      }

      return (
        table.value.bill?.remainingBalance?.currencyValue +
        table.value.bill?.amountPaid?.currencyValue
      );
    });

    const showSettlebill = computed(
      () => shareAmount.value === billRemaining.value || shareAmount.value === 0
    );

    const isSplitBill = computed(
      () => billPaymentsList.value.length > 0 || shareAmount.value > 0
    );

    const buttonLabel = computed(() => {
      if (isSplitBill.value) {
        return showSettlebill.value ? "Settle the bill" : "Pay your share";
      }
      return isLoggedIn.value && !selectedCard.value
        ? "Add a card"
        : "Pay the bill";
    });

    const buttonLabelApplePay = computed(() => {
      if (isSplitBill.value) {
        return showSettlebill.value ? "Settle the bill" : "Pay your share";
      }
      return "Pay with card";
    });

    const paymentInfoSubtitle = computed(() => {
      const subtitle = useLoyaltyProgram.value
        ? "Your share including loyalty and tips"
        : "Your share including tips";

      const subtitleSharedBill = useLoyaltyProgram.value
        ? "Remaining total plus loyalty and tips"
        : "Remaining total plus tips";

      return showSettlebill.value ? subtitleSharedBill : subtitle;
    });

    onUnmounted(() => {
      document.body.style.backgroundColor = "#fff";
    });

    watch([loadingTable, () => props.business], () => {
      const hasPaymentId = window.localStorage.getItem("bill_payment_id");

      if (
        !loadingTable.value &&
        props.business.id &&
        props.checkoutRedirect &&
        table.value.bill &&
        hasPaymentId
      ) {
        processingPayment(hasPaymentId, true);
      }
    });

    watch(selectedCard, () => {
      showPaymentMethod.value = false;
      paymentMethods.value = paymentMethods.value.map((paymentMethod) => {
        return {
          ...paymentMethod,
          selected: paymentMethod.id === selectedCard.value?.id,
        };
      });
    });

    watch(
      () => props.business,
      () => {
        if (props.business?.id) {
          Promise.all([
            checkGatewayCondition(),
            setupPayment(),
            getBillForTable(true),
            getLoyaltyPoints(),
          ]);
        }
      },
      { immediate: true }
    );

    const redirectToAuth = (): void => {
      props.changeViewTo(ViewsEnum.AUTH);
    };

    const useLoyaltyProgram = ref(false);
    const setLoyaltyProgramState = (state: boolean): void => {
      useLoyaltyProgram.value = state;
    };

    async function setupPayment(): Promise<void> {
      if (isLoggedIn.value && props.business?.id) {
        try {
          const userCreditCards = await fetchUserCreditCards({
            businessId: props.business.id,
            accessToken,
          });

          if (!userCreditCards.length && !hasApplePay.value) {
            loadingPaymentMethods.value = false;
            return;
          }

          paymentMethods.value = userCreditCards.map((card) => {
            return {
              id: card.id,
              method: card.cardBrand?.toLowerCase() as PaymentMethodsEnum,
              label: card.cardBrand,
              last4: card.last4,
              selected: false,
            };
          });

          if (!selectedCard.value && paymentMethods.value.length > 0) {
            selectedCard.value = paymentMethods.value?.[0] || null;
          }

          if (hasApplePay.value) {
            selectedCard.value = applePayMethod;
            if (props.isLoginFlow) {
              paymentMethods.value && paymentMethods.value.length > 0
                ? (selectedCard.value = paymentMethods.value[0])
                : openCheckoutForm();
            }
          }
        } catch (e) {
          report(e);
        }
      }

      if (hasApplePay.value) {
        paymentMethods.value = [...paymentMethods.value, applePayMethod];
      }

      loadingPaymentMethods.value = false;
    }

    function tracking(event: string, additionalData = {}): void {
      const tipsSaved = window.localStorage.getItem(
        `bill-${table.value.bill?.id}`
      );
      const tipPercentage = tipsSaved
        ? JSON.parse(tipsSaved).tipPercentage
        : TipsEnum.NONE;
      mixpanel.track(event, {
        business_id: props.business.id,
        business_name: props.business.name,
        table_id: props.tableId,
        table_number: table.value.tableName,
        event_category: "pay_at_table",
        bill_id: table.value.bill?.id,
        bill_amount: billTotal.value,
        tip_perc_amount: tipPercentage,
        bill_available: Boolean(table.value.bill),
        outlet_id: table.value.location?.id,
        outlet_name: table.value.location?.name,
        ...additionalData,
      });
    }

    function getBillSplitInfo(): void {
      const sharedAmountSaved = window.localStorage.getItem(
        `split-bill-${table.value.bill?.id}`
      );

      if (sharedAmountSaved) {
        const splitBill = JSON.parse(sharedAmountSaved);
        const shareAmountValue = parseInt(splitBill.sharedAmount);
        selectedSplitBill(shareAmountValue, splitBill.userName);
      }
    }

    async function getBillForTable(firstLoad = false): Promise<void> {
      const oldAmountPaidValue = JSON.parse(
        JSON.stringify(amountPaidValue.value)
      );

      try {
        table.value = await fetchBillByTableId(props.tableId);
      } catch (e: any) {
        report(e);
      } finally {
        if (firstLoad) {
          await refreshBill();
        }

        if (table.value?.bill) {
          if (table.value?.bill?.amountPaid?.currencyValue) {
            amountPaidValue.value = table.value.bill.amountPaid.currencyValue;
          }

          getBillSplitInfo();

          if (firstLoad) {
            tracking(isOrderSettled.value ? "Settled bill" : "Viewed bill");

            if (amountPaidValue.value > 0) {
              await getPaymentList(table.value.bill.id);
            }
          }

          if (
            !firstLoad &&
            amountPaidValue.value > 0 &&
            oldAmountPaidValue !== amountPaidValue.value
          ) {
            await getPaymentList(table.value.bill.id);
            blinkBill();
            trackBillUpdated();
          }
        } else {
          props.changeViewTo(ViewsEnum.NO_BILL);
        }

        loadingTable.value = false;

        if (
          !contactingBank.value &&
          !isOrderSettled.value &&
          !hasStartedSync.value
        ) {
          // eslint-disable-next-line no-magic-numbers
          setTimeout(() => getBillForTable(), 5000);
        }
      }
    }

    function trackBillUpdated(): void {
      const isBillSplit = !(
        isOrderSettled.value && billPaymentsList.value.length === 1
      );
      tracking("Payment Updated", {
        share_amount: shareAmount.value,
        customer_name: shareName.value,
        your_amount: totalPayableWithTips.value,
        split_bill: isBillSplit ? "Custom split" : "Total Payment",
        payment_updated: isBillSplit,
      });
    }

    function blinkBill(): void {
      billBlink.value = false;
      setTimeout(() => (billBlink.value = true), 100);
    }

    async function getPaymentList(billId: string): Promise<void> {
      try {
        const res = await fetchPaymentsList(billId, props.business.id);

        if (res.length) {
          billPaymentsList.value = res;
        }
      } catch (e) {
        report(e);
      }
    }

    async function checkGatewayCondition(): Promise<void> {
      if (!props.business?.id) return;

      try {
        const response = await getPaymentGateway({
          businessId: props.business.id,
        });

        const { isApplePayEnabled } = response || {};

        if (response.gatewayType === GatewayTypeEnum.CHECKOUT) {
          checkoutToken.value = response.publishableKey;
        }

        if (response.gatewayType === GatewayTypeEnum.SKIPCASH) {
          hasSkipcash.value = true;

          selectedCard.value = {
            id: GatewayTypeEnum.SKIPCASH,
            method: GatewayTypeEnum.SKIPCASH,
            label: "SkipCash",
            selected: true,
          };
        }

        isValidGatewayCondition.value = isApplePayEnabled;
      } catch (e) {
        report(e);
      }
    }

    const showLoyaltyProgramBlock = computed(
      () =>
        props.business?.loyaltyProgram?.orderAmountPercentageWorth &&
        !isOrderSettled.value
    );

    async function getLoyaltyPoints(): Promise<void> {
      if (!isLoggedIn.value || !props.business?.id) return;

      try {
        loyaltyPoints.value = await fetchLoyaltyPoints({
          businessId: props.business.id,
          accessToken,
        });
      } catch (e) {
        report(e);
      }
    }

    function shakeTips(): void {
      tipShaker.value = false;
      setTimeout(() => (tipShaker.value = true), 100);
    }

    const totalOrderAmount = computed(() =>
      shareAmount.value > 0 ? shareAmount.value : billRemaining.value
    );

    const totalPayableWithTips = computed(() => {
      const redeemableLoyaltyPoints = getRedeemableLoyaltyPoints(
        totalOrderAmount.value,
        loyaltyPoints.value,
        props.business.loyaltyProgram
      );

      const totalWithLoyaltyPoints = useLoyaltyProgram.value
        ? totalOrderAmount.value - redeemableLoyaltyPoints
        : totalOrderAmount.value;

      return tipsAmount.value + totalWithLoyaltyPoints;
    });

    const hasError = ref(false);
    const tipsRef = ref<HTMLDivElement>();

    function selectTip({
      percentage,
      tip,
    }: {
      percentage: number;
      tip: IPrice;
    }): void {
      if (!table.value?.bill) return;

      hasError.value = false;
      tipChosen.value = true;
      tipsAmount.value = tip.value;
      tipsPercentage.value = percentage;
    }

    function checkIsTipPicked(): boolean {
      if (tipChosen.value) return true;

      hasError.value = true;
      shakeTips();
      tipsRef.value?.scrollIntoView();
      return false;
    }

    function payWithCard(): void {
      if (!checkIsTipPicked()) return;

      if (!isLoggedIn.value) {
        redirectToAuth();
        return;
      }

      if (!selectedCard.value) {
        handleCheckoutForm(true);
        return;
      }

      paymentType = hasSkipcash.value
        ? IBillPaymentType.SKIPCASH
        : IBillPaymentType.CARD_ONLINE;

      checkout(paymentType);
    }

    function payWithApplePay(applePayToke: any): void {
      const { token, card, phoneNumber } = applePayToke;

      paymentType = IBillPaymentType.APPLE_PAY;

      newCard.value = {
        cardToken: token,
        ...card,
      };

      checkout(IBillPaymentType.APPLE_PAY, phoneNumber);
    }

    function openCheckoutForm(): void {
      showPaymentMethod.value = false;
      handleCheckoutForm(true);
    }

    function selectNewCard(card: ICardDetails): void {
      const newPayment = {
        id: card.cardToken,
        method: card.card.paymentMethod.toLowerCase(),
        label: card.card.paymentMethod,
        last4: card.card.last4,
        selected: true,
      };

      const formatNewCard = {
        cardToken: card.cardToken,
        brand: card.card.paymentMethod,
        last4: card.card.last4,
        expiryYear: card.card.expiryYear,
        expiryMonth: card.card.expiryMonth,
      };

      newCard.value = formatNewCard;

      onSelectedCard(newPayment);

      handleCheckoutForm(false);
    }

    function onSelectedCard(card: PaymentMethod): void {
      if (hasSkipcash.value) return;
      if (!card) return;
      selectedCard.value = card;

      tracking("Added Payment Method", {
        card_id: card.id,
        payment_method: card.method,
      });
    }

    function trackBillSplitOnChange(amount: number, name: string): void {
      if (tipsPercentage.value === undefined) return;
      const tips = parseInt(((tipsPercentage.value / 100) * amount).toFixed());
      const trackingTitle =
        shareAmount.value > 0 ? "Change share amount" : "Enter share amount";

      tracking(trackingTitle, {
        share_amount: amount,
        customer_name: name,
        your_amount: amount + tips,
        split_bill: "Custom split",
      });
    }

    function selectedSplitBill(
      amount: number,
      name: string,
      closeForm = false
    ): void {
      if (closeForm) {
        showSplitBillForm.value = false;
        trackBillSplitOnChange(amount, name);
      }

      shareAmount.value =
        amount > billRemaining.value ? billRemaining.value : amount;
      shareName.value = name;

      window.localStorage.setItem(
        `split-bill-${table.value.bill?.id}`,
        JSON.stringify({
          sharedAmount: shareAmount.value,
          userName: shareName.value,
        })
      );
    }

    function handleCheckoutForm(state: boolean): void {
      showCheckoutForm.value = state;
      disablePaymentButton.value = state;
      displayBackButtonPayment.value = state;
    }

    const isNotificationVisible = ref(false);
    const notificationIcon = ref("");
    const notificationTitle = ref("");
    const notificationDescription = ref("");
    const notificationPaymentSuccessfull = ref(false);

    function setNotification(
      icon: string,
      title: string,
      description: string,
      reason: string,
      isSuccess = false
    ): void {
      isNotificationVisible.value = true;
      notificationIcon.value = icon;
      notificationTitle.value = title;
      notificationDescription.value = description;

      tracking("Payment dialog box", {
        split_bill: "Custom split",
        share_amount: shareAmount.value,
        customer_name: shareName.value,
        your_amount: totalPayableWithTips.value,
        payment_dialog: reason,
      });

      if (isSuccess) {
        notificationPaymentSuccessfull.value = true;
      }
    }

    async function checkout(
      paymentType: IBillPaymentType,
      phoneNumber?: string
    ): Promise<void> {
      if (!table.value.bill) return;

      contactingBank.value = true;

      const cardInfo = newCard.value
        ? { ...newCard.value }
        : { cardId: selectedCard.value?.id };

      const paymentDetails = {
        type: paymentType,
        ...cardInfo,
      };

      const amount =
        shareAmount.value > 0 ? shareAmount.value : billRemaining.value;

      const payload = {
        businessId: props.business.id,
        billId: table.value.bill.id,
        useRewardPoints: useLoyaltyProgram.value,
        paymentDetails,
      };

      saveLoyaltyPoints(table.value.bill.id, {
        amount,
        loyaltyPoints: loyaltyPoints.value,
        isBurnedPoints: useLoyaltyProgram.value,
        ...props.business.loyaltyProgram,
      });

      updatePaymentSuccessData({ phoneNumber });

      try {
        const billPaymentId = await checkoutPayBill({
          ...payload,
          amount,
          phoneNumber,
          customerName: shareName.value,
          tipPercentage: tipsPercentage.value,
        });

        window.localStorage.setItem("bill_payment_id", billPaymentId);
        processingPayment(billPaymentId);
      } catch (e: any) {
        report(e);

        contactingBank.value = false;

        if (e.errors.errorCode === "bill_already_settled") {
          setNotification(
            "thumbs-up",
            "The bill is already settled.",
            "Nothing for you to pay for anymore.",
            "Bill settled"
          );
        }
      }
    }

    const updatePaymentSuccessData = (
      additionalData: Record<string, any>
    ): void => {
      if (!table.value?.bill) return;

      const key = `payment-success-${table.value.bill.id}`;
      const currentState = getStorageItem(key) || {};

      setStorageItem(`payment-success-${table.value.bill.id}`, {
        ...currentState,
        ...additionalData,
      });
    };

    async function processingPayment(
      billPaymentId: string,
      redirected = false
    ): Promise<void> {
      if (!table.value.bill) return;

      contactingBank.value = true;

      try {
        const response = await checkoutFetchBillPayment({
          businessId: props.business.id,
          billId: table.value.bill.id,
          paymentId: billPaymentId,
        });

        if (!redirected && response.status === "pending_authentication") {
          window.open(response.url, "_self");
          return;
        }

        if (response.status === "successful") {
          const sumValues = response.amountCaptured + response.tipAmount;

          tracking("Payment Success", {
            payment_method: paymentType,
            split_bill:
              response.amountCaptured === billTotal.value
                ? "Total Payment"
                : "Custom split",
            share_amount: shareAmount.value,
            customer_name: shareName.value,
            your_amount: sumValues,
            payment_success:
              billRemaining.value > 0 ? "Payment partial" : "Payment settled",
          });

          const calcTips = (response.tipAmount / response.amountCaptured) * 100;

          updatePaymentSuccessData({
            tipAmount: response.tipAmount,
            tipPercentage: calcTips.toFixed(),
            isSplitBill: isSplitBill.value,
            customerName: shareName.value,
            amountPaid: response.amountCaptured,
          });

          window.localStorage.removeItem("bill_payment_id");
          window.localStorage.removeItem(`bill-${table.value.bill.id}`);
          window.localStorage.removeItem(`split-bill-${table.value.bill.id}`);

          if (response.amountCaptured < response.amount) {
            const formatValue = toMoney(sumValues, response.currency, {
              locale: props.business.country,
            });

            const paidAmount = `${response.currency} ${formatValue}`;

            setNotification(
              "thumbs-up",
              `You paid only ${paidAmount}`,
              `Somebody was faster so you’ve only paid ${paidAmount} (including ${calcTips.toFixed()}% tips)`,
              "Overpayment",
              true
            );
            return;
          }

          props.changeViewTo(ViewsEnum.PAYMENT_SUCCESS);

          return;
        }

        if (response.status === "failed") {
          window.localStorage.removeItem("bill_payment_id");
          props.changeViewTo(ViewsEnum.PAYMENT_FAIL);

          tracking("Payment failed", {
            payment_method: paymentType,
            failure_reason: response.status,
          });

          return;
        }

        if (response.status === "voided") {
          window.localStorage.removeItem("bill_payment_id");

          if (response.reason === "bill_already_paid") {
            setNotification(
              "error",
              "The bill is already settled.",
              "Nothing for you to pay for anymore. Any payments you’ve done, will be refunded.",
              "Payment refund"
            );
            return;
          }

          setNotification(
            "error",
            "Payment timeout",
            "We were’t able to process your payment. Please try again.",
            "Payment timeout"
          );
          return;
        }

        // eslint-disable-next-line no-magic-numbers
        setTimeout(() => processingPayment(response.id, redirected), 500);
      } catch (e) {
        report(e);

        contactingBank.value = false;
      }
    }

    const isPosEnabled = ref(false);

    async function refreshBill(): Promise<void> {
      if (!table.value.location) {
        return;
      }

      lastUpdated.value = new Date();

      hasStartedSync.value = true;

      try {
        isPosEnabled.value = await getLocationSettings(table.value.location.id);
      } catch (e) {
        hasStartedSync.value = false;
        isPosEnabled.value = false;
        report(e);
      }

      if (!isPosEnabled.value) {
        hasStartedSync.value = false;
        return;
      }

      let refreshed = false;
      try {
        refreshed = await posRefreshBill(
          table.value.location.id,
          props.tableId
        );
      } catch (e) {
        hasStartedSync.value = false;
        report(e);

        return;
      }

      if (!refreshed) {
        return;
      }

      try {
        await getBillForTable();
      } finally {
        hasStartedSync.value = false;
      }
    }

    async function onReloadBill(): Promise<void> {
      tracking("Reload bill clicked");
      loadingReloadBill.value = true;

      await refreshBill();

      loadingReloadBill.value = false;
    }

    async function setSplitBill(): Promise<void> {
      if (contactingBank.value) return;
      showSplitBillForm.value = true;

      tracking("Split bill started", {
        split_bill: "Custom split",
      });

      await refreshBill();
    }

    function closeNotification(): void {
      isNotificationVisible.value = false;
      notificationIcon.value = "";
      notificationTitle.value = "";
      notificationDescription.value = "";
      notificationPaymentSuccessfull.value = false;
      contactingBank.value = false;

      if (notificationPaymentSuccessfull.value) {
        props.changeViewTo(ViewsEnum.PAYMENT_SUCCESS);
      }
    }

    return {
      t,
      css,
      table,
      shareAmount,
      showSplitBillForm,
      contactingBank,
      selectNewCard,
      loadingTable,
      billPaymentsList,
      loadingPaymentMethods,
      hasError,
      refreshBill,
      lastUpdated,
      tipShaker,
      buttonLabel,
      buttonLabelApplePay,
      tipsRef,
      shareName,
      billTotal,
      selectTip,
      currencyCode,
      selectedCard,
      onSelectedCard,
      handleCheckoutForm,
      displayBackButtonPayment,
      paymentMethods,
      totalPayableWithTips,
      payWithCard,
      selectedSplitBill,
      payWithApplePay,
      checkIsTipPicked,
      showPaymentMethod,
      hasApplePay,
      disablePaymentButton,
      isLoggedIn,
      setSplitBill,
      openCheckoutForm,
      showCheckoutForm,
      isOrderSettled,
      billRemaining,
      totalOrderAmount,
      billBlink,
      isNotificationVisible,
      isLoading,
      hasStartedSync,
      notificationIcon,
      notificationTitle,
      notificationDescription,
      loyaltyPoints,
      useLoyaltyProgram,
      tipsPercentage,
      showLoyaltyProgramBlock,
      setLoyaltyProgramState,
      redirectToAuth,
      closeNotification,
      showSettlebill,
      tipChosen,
      isSplitBill,
      AtomButtonTypeEnum,
      AtomButtonSizeEnum,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomTextTagEnum,
      TipsEnum,
      paymentInfoSubtitle,
      PaymentMethodsEnum,
      checkoutToken,
      onReloadBill,
      isPosEnabled,
      hasSkipcash,
    };
  },
});
